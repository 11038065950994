import styles from './SeasonPass.module.scss';
import { BackButton, Logo } from 'shared/ui';
import { ReactComponent as IconRight } from 'shared/assets/icons/right_arrow.svg';
import { ID } from 'shared/model';
import { classNames } from 'shared/utils';

function SeasonPass(props: {
  id: ID,
  title: string;
  subtitle?: string;
  backBtn?: { title: string; onClick: () => void };
  hideArrow?: boolean,
  logo: JSX.Element | string;
  decoration?: JSX.Element | string;
}) {
  return (
    <div className={styles.seasonPassContainer}>
      {props.backBtn && <BackButton id='SeasonPass' {...props.backBtn} />}
      <div className={styles.seasonPassInfo}>
        <div className={styles.seasonPassLogo}>
          {typeof props.logo === 'string' ? (
            <Logo src={props.logo} classes={classNames(styles.logoSize70)} />
          ) : (
            <div className={styles.logoContainer}>{props.logo}</div>
          )}
        </div>
        <div className={styles.seasonPassTitle}>{props.title}</div>
        <div className={styles.seasonPassSubtitle}>{props.subtitle}</div>
      </div>
        {!props.backBtn && !props.hideArrow &&
          <div className={styles.seasonPassArrow}>
            <IconRight />
          </div>
        }
    </div>
  );
};

export default SeasonPass;
