import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { LoyaltyService } from 'entities/Loyalty';
import { MatchesService } from 'entities/Matches';
import { OrderService } from 'entities/Order';
import { ProfileService } from 'entities/Profile';
import { SeasonsPassService } from 'entities/SeasonsPass/model';
import { VenueService } from 'entities/Venue';
import { WarningModalService } from 'features/ConfigurableWarningModal/model/WarningModalService';
import { AuthService } from 'processes/Auth';
import { PaymentService } from 'processes/Payment';
import { PreferencesService } from 'processes/Preferences';
import { ProlongSeasonTicketService } from '../../features/ProlongSeasonTicket';
import { LocalizedString } from 'shared/utils';


export type ID = string;
export type ISOstring = string;

export type RemoteFile = {
  publicLink: string;
};

export type Services = {
  preferencesService: PreferencesService;
  authService: AuthService;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  loyaltyService: LoyaltyService;
  matchesService: MatchesService;
  seasonsPassService: SeasonsPassService;
  profileService: ProfileService;
  prolongSeasonTicketService: ProlongSeasonTicketService;
  orderService: OrderService;
  paymentService: PaymentService;
  venueService: VenueService;
  warningModalService: WarningModalService;
};

export type Pagination = Partial<{
  limit: number;
  page: number;
}>;

export type PaginatedResult<T> = {
  total: number;
  limit: number;
  page: number;
  list: T[];
};

export enum EventStatus {
  CREATED,
  ACTIVE,
  CANCELED,
  COMPLETED,
}

export type Team = {
  id: ID;
  title: LocalizedString;
  logo?: RemoteFile;
  location?: LocalizedString;
};

export enum EventVisibilityPlace {
  CASHIER,
  WEB_SHOP,
  MOBILE_SHOP,
  WEB_WIDGET,
}

export type PeriodInput = {
  start: ISOstring;
  end: ISOstring;
};

export type Coordinates = {
  x: number;
  y: number;
};


export type MapSize = {
  height: number;
  width: number;
}

export type MapHeight = {
  height?: number
};
