import ErrorScreen from 'pages/ErrorScreen/ErrorScreen';
import {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from 'routes/Routes';
import {Services} from 'shared/model';
import {Loader} from 'shared/ui';
import {HelmetProvider} from 'react-helmet-async';

import './App.scss';
import {MainHead} from 'shared/ui/DocumentHead';
import {initGlobalStyles, initServices} from './init';
import {classNames} from 'shared/utils';
import {PreferenceKeys} from "../processes/Preferences";

function App() {
  const [services, setServices] = useState<Services>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);

  const layoutStyles = classNames(
    'App',
    services?.preferencesService.get(PreferenceKeys.CLUB) || 'fanzilla'
  );

  useEffect(() => {
    try {
      setLoading(true);
      (async () => {
        const services = await initServices();
        initGlobalStyles(services?.preferencesService);
        setServices(services);
      })();
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) return <Loader />;
  if (error) return <ErrorScreen error={error} />;

  return (
    <div className={layoutStyles}>
      <HelmetProvider>
        <MainHead preferencesService={services?.preferencesService}/>
        <Router>
          <Routes services={services}/>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
