import { useState } from 'react';
import { useIsMounted } from 'shared/utils';

export function useFetchFormData<T>(getter: () => Promise<T>, handleSuccess?: () => void, handleError?: (error: string) => void) {
  const [submited, setSubmited] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T>()
  const isMounted = useIsMounted()

  const fetch = async () => {
      try {
        setLoading(true)
        setSubmited(true)

        const res = await getter()
        if (isMounted()) setData(res)

        if (handleSuccess) handleSuccess()
      } catch (e) {
        if (handleError) handleError((e as Error).message)
        console.error((e as Error).message)
      } finally {
        if (isMounted()) {
          setSubmited(false)
          setLoading(false)
        }
      }
  }

  return {data, submited, loading, fetch}
} 
