import React from 'react';
import { ReactComponent as IllErrorSvg } from './ill-error.styled.svg';

function propsToCssVars(props?: IllErrorProps): React.CSSProperties {
    return {
        '--color-theme_clubNavigation1': props?.styles?.colorThemeClubNavigation1,
        '--color-theme_clubNavigation1-override': props?.styles?.colorThemeClubNavigation1Override,
    } as React.CSSProperties
}

export type IllErrorProps = {
    styles?: {
        colorThemeClubNavigation1?: string
        colorThemeClubNavigation1Override?: string
    }
}

/**
 * Generated component
 * Available CSS variables: color-theme_clubNavigation1, color-theme_clubNavigation1-override
 **/
export default function IllError(props?: IllErrorProps) {
    return <IllErrorSvg style={{ ...propsToCssVars(props) }}/>
}