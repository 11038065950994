import { useTranslation } from 'react-i18next';
import { OrderItemDescriptor, Ticket } from 'entities/Order/model';
import { CountdownTimerOnRxJS, SeparatedList } from 'shared/ui';
import { groupBy, minDate } from 'shared/utils';
import OrderTickets from '../OrderItems/OrderTickets';
import styles from './OrderItemsList.module.scss';

function OrderTicketsList(props: {
  orderItemDescriptors: OrderItemDescriptor[],
  removeItem: (orderItemId: string) => void
}) {
  const { t } = useTranslation();
  const minReserved = minDate<OrderItemDescriptor>(props.orderItemDescriptors, item => item.reservedUntil!)
  const groupTickets = groupBy<OrderItemDescriptor, string>(props.orderItemDescriptors, item => (item.item as Ticket).event.id)

  return (
    <article className={styles.order}>
      <div className={styles.header}>
        <h4>{t('order_tickets_list.h4_text')}</h4>
        <CountdownTimerOnRxJS label={t('common.countdown_timer_label') ?? 'бронь'} date={minReserved}/>
      </div>
      <SeparatedList>
        { Array.from(groupTickets, ([key, value]) => 
          <OrderTickets
            key={key}
            orderItemDescriptor={value}
            removeItem={props.removeItem}
          />
        )}
      </SeparatedList>
    </article>
  )
}

export default OrderTicketsList
