import { ItemType } from "entities/Order";
import { PlacesSubscriptionType } from "../hooks";
import { PlacePriceSubscription, PriceTemplateTag, SeasonTicketSubscription, SubscriptionHandlers } from "../model";

export const defineSubscriptionConfig = (eventType: ItemType | undefined, id: string, handlers: SubscriptionHandlers): {
  subscriptionType: PlacesSubscriptionType,
  subscriptionConfig: PlacePriceSubscription | SeasonTicketSubscription
} => {
  const isTicketSubscription = eventType === 'TICKET';
  const isSeasonTicketSubscription = eventType === 'SEASON_TICKET' || eventType === 'PROLONG_SEASON_TICKET';
  const { handlePlacePriceChange, handleError } = handlers;

  let type: PlacesSubscriptionType;
  let config: PlacePriceSubscription | SeasonTicketSubscription;
  if (isTicketSubscription) {
    type = 'TICKET';
    const filter = {
      eventId: id,
      tag: PriceTemplateTag.ONLINE
    };
    config = {
      filter,
      onNext: handlePlacePriceChange,
      onError: handleError,
      // onComplete:
    };
  } else if (isSeasonTicketSubscription) {
    type = 'SEASON_TICKET';
    const filter = {
      descriptorId: id,
    };
    config = {
      filter,
      onNext: handlePlacePriceChange,
      onError: handleError,
      // onComplete:
    }
  } else {
    throw new Error(`Incorrect subscription configuration: ID – ${id}, Event type – ${eventType}`);
  }

  return {
    subscriptionType: type,
    subscriptionConfig: config
  };
}
