import CloseButton from 'shared/ui/Buttons/CloseButton/CloseButton';
import ModalHeader, { ModalHeaderProps } from 'shared/ui/Modal/ModalHeader/ModalHeader';
import styles from './ModalHeader.module.scss';

export function ModalHeaderWithTitle(
  props: {
    title?: string;
    closeModal?: () => void;
    headerButton?: JSX.Element;
  } & Omit<ModalHeaderProps, 'children'>
) {
  return (
    <ModalHeader {...props} headerClassName={styles.headerWithTitle}>
      <span>{props.headerButton}</span>
      <h2>{props.title || ''}</h2>
      {props.closeModal ? (
        <span className={styles.closeModal}>
          <CloseButton id='ModalHeaderWithTitle' onClick={() => props.closeModal!()} />
        </span>
      ) : (
        ''
      )}
    </ModalHeader>
  );
}

export default ModalHeaderWithTitle;
