import { OrderItemDescriptor, Ticket } from 'entities/Order/model';
import { TicketDetails } from 'features/TicketDetails';
import { Logo } from 'shared/ui';
import { classNames, formatDateTimeForOrder } from 'shared/utils';
import styles from './OrderItems.module.scss';

function OrderTickets(props: {
  orderItemDescriptor: OrderItemDescriptor[],
  removeItem: (ticketsId: string) => void
}) {
  const event = (props.orderItemDescriptor[0].item as Ticket).event

  return (
    <section className={styles.event}>
      <div className={styles.header}>
          <h3 className={styles.title}> {event.team1.title} <span>vs</span> {event.team2.title}</h3>
        <div className={styles.subtitle}>
          <Logo src={event.stage.tournament.logo?.publicLink} classes={classNames(styles.logoSize16)} />
          <p>{formatDateTimeForOrder(event.startTime)}</p>
        </div>
      </div>
       {props.orderItemDescriptor && props.orderItemDescriptor.map((item: OrderItemDescriptor, index: number ) => (
        <TicketDetails
          id={item.id}
          key={index}
          sector={item.item.place.row.sector.title}
          row={item.item.place.row.number}
          place={item.item.place.number}
          price={item.price}
          priceWithDiscount={item.priceWithDiscount}
          removeItem={props.removeItem}
        />
       ))}
    </section>
  )
}

export default OrderTickets
