import { useDelayUnmount } from "../utils/useDelayUnmount";

function DelayUnmountChild(props: {
  isMountChild: boolean,
  delayTime: number,
  children: JSX.Element,
}) {
  const shouldRenderChild = useDelayUnmount(props.isMountChild, props.delayTime);

  return (
    <>
    {shouldRenderChild && props.children}
    </>
  )
}

export default DelayUnmountChild