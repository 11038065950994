import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { ID } from 'shared/model/types';
import { LocalizedString } from 'shared/utils';

// export type LocalizedString = {
//   ru: string,
//   en: string
// }

export type UserContact = {
  type: ContactType;
  value: string;
}

export type Person = {
  name: LocalizedString;
  surname: LocalizedString;
  contacts: UserContact[];
  patronymic?: LocalizedString;
  birthday?: DateTime;
  photo?: ShortFileDescriptor;
}

export type ShortFileDescriptor = {
  id: string;
  publicLink: string;
}

export type User = {
  id: ID;
  person?: Person;
}

export enum ContactType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export type ContactInput = {
  type: ContactType;
  value: string;
}

export type PersonUpdateInput = {
  name?: LocalizedString;
  surname?: LocalizedString;
  patronymic?: LocalizedString;
  birthday?: DateTime;
  contacts?: ContactInput[];
}

export type PersonUpdateInputProfileUpdateInput = {
  password?: string,
  person?: PersonUpdateInput;
}

export enum RegistrationState {
  DEFAULT = 'DEFAULT',
  IN_PROCESS = 'IN_PROCESS',
}

export interface ProfileService {
  registrationState: BehaviorSubject<RegistrationState>;
  startRegistration(): void;
  resetRegistration(): void;
  restorePassword(phone: string, captchaCode?: string): Promise<number>;
  registration(phone: string, captchaCode?: string): Promise<string>;
  updateProfile(person: PersonUpdateInput, password?: string): Promise<string>;
  getProfile(): Promise<User>;
}
