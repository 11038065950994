import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, useMovementWithinMultistep } from 'shared/ui';
import { IErrors, IFields } from '../../utils';

function UpdateProfileForm(props: {
  onSubmit?: () => void,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  data: IFields,
  errors: IErrors,
  touched?: boolean,
  loading?: boolean,
  btnChildren: JSX.Element | string,
}) {
  const { errors, data, onChange } = props
  const { forbiddenNextStep } = useMovementWithinMultistep(Object.keys(errors).length > 0)
  const { t } = useTranslation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (props.onSubmit) props.onSubmit()
  }

  return (
      <form onSubmit={handleSubmit}>
        <Input
          type='text'
          name='surname'
          title={t('update_profile_form.surname.0') ?? "Фамилия"}
          placeholder={t('update_profile_form.surname.1') ?? "Введите фамилию"}
          value={data.surname.value}
          handleChange={onChange}
          error={errors?.surname}
          touched={props.touched}
        />
        <Input
          type='text'
          name='name'
          title={t('update_profile_form.name.0') ?? "Имя"}
          placeholder={t('update_profile_form.name.1') ?? "Введите имя"}
          value={data.name.value}
          handleChange={onChange}
          error={errors?.name}
          touched={props.touched}
        />
        <Input
          type='text'
          name='patronymic'
          title={t('update_profile_form.patronymic.0') ?? "Отчество"}
          placeholder={t('update_profile_form.patronymic.1') ?? "Введите отчество"}
          value={data.patronymic?.value}
          handleChange={onChange}
          error={errors?.patronymic}
        />
        <Input
          type='text'
          name='birthday'
          title={t('update_profile_form.dob') ?? "Дата рождения"}
          placeholder='10.01.1970'
          mask='00.00.0000'
          value={data.birthday?.value}
          handleChange={onChange}
          error={errors?.birthday}
          touched={props.touched}
        />
        <Input
          type='text'
          name='email'
          title={t('update_profile_form.email.0') ?? "Почта"}
          placeholder='yourmail@mail.com'
          label={t('update_profile_form.email.1') ?? "Для копий билетов"}
          value={data.email?.value}
          handleChange={onChange}
          error={errors?.email}
          touched={props.touched}
        />
        <Button id='UpdateProfileForm' type="submit" loading={props.loading} disabled={forbiddenNextStep}>{props.btnChildren}</Button>
      </form>
  )
}

export default UpdateProfileForm
