import Skeleton from "shared/ui/Skeleton/Skeleton";
import SkeletonTicket from "features/TicketDetails/ui/Ticket/SkeletonTicket";

import styles from './TicketDetails.module.scss';

function SkeletonTicketDetails() {
  return (
    <div className={styles.ticketDetails} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <SkeletonTicket />

      <div className={styles.price}><Skeleton type="span" style={{width: '100px'}}/></div>
      <div className={styles.priceWithDiscount}><Skeleton type="span" style={{width: '100px'}}/></div>
      <Skeleton type="span" style={{ width: '20px', height: '20px' }} />
    </div>
  )
} 

export default SkeletonTicketDetails