import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Path} from 'routes/path';
import {unique} from 'shared/utils/helpers/listTools';
import {useBehaviorSubject} from 'shared/utils';
import styles from './ProlongSeasonTicket.module.scss';

import {CustomCheckbox, CustomRadioInput, Modal, Skeleton} from 'shared/ui';

import SeasonPass from 'entities/SeasonsPass/ui/SeasonPass/SeasonPass';
import Ticket from 'features/TicketDetails/ui/Ticket/Ticket';
import {GoToCartButton} from 'entities/Venue/ui/Places/SelectedPlaces';
import {First} from 'figma/components/Icons/first/First/First';
import {Second} from 'figma/components/Icons/second/Second/Second';
import IllError from 'figma/components/IllError/IllError';

import {ProlongSeasonTicketBaseErrors} from '../../utils/types';
import {errorsModalContent, getErrorModalBody} from '../../utils/errorsModalContent';
import {errorMapper} from '../../utils/errorMapper';
import {PreferenceKeys, PreferencesService} from 'processes/Preferences';
import {
  ProlongSeasonTicketDescriptor,
  ProlongSeasonTicketService,
  SeasonTicket,
  SeasonTicketDescriptor
} from '../../model';
import {AuthService} from 'processes/Auth';
import {OrderContext} from 'entities/Order/model/OrderContext';
import {ItemType} from 'entities/Order';


function ColumnTitle(props: {
  icon?: React.ReactNode,
  text: string
}) {
  return (
    <div className={ styles.columnTitle }>
      { props.icon }
      <span>
        <h2 className={ styles.text }>{ props.text }</h2>
      </span>
    </div>
  )
}


function ProlongSeasonTicket(props: {
  prolongSeasonTicketService: ProlongSeasonTicketService;
  preferencesService: PreferencesService;
  authService: AuthService;
}) {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const authenticated = useBehaviorSubject(props.authService.authenticated);
  const { t } = useTranslation();

  const [ loaded, setLoaded ] = useState(false)
  const [ tickets, setTickets ] = useState<SeasonTicket[]>([])
  const [ descriptor, setDescriptor ] = useState<ProlongSeasonTicketDescriptor>()
  const [ error, setError ] = useState<ProlongSeasonTicketBaseErrors>();
  const [ selectedTickets, setSelectedTickets ] = useState<SeasonTicket[]>([])
  const [ selectedDescriptor, setSelectedDescriptor ] = useState<SeasonTicketDescriptor>()

  const {updateEventType} = useContext(OrderContext);
  updateEventType && updateEventType(ItemType.PROLONG_SEASON_TICKET);

  useEffect(() => {
    if(!authenticated) {
      props.authService.requireAuthorization();
    } else {
      const init = async () => {
        const seasonTicketDescriptorJob = props.prolongSeasonTicketService.getDescriptorById(id);
        const seasonTicketsJob = props.prolongSeasonTicketService.getSeasonTicketsToProlong();
        const [seasonTicketDescriptor, seasonTickets] = await Promise.all([seasonTicketDescriptorJob, seasonTicketsJob]);
        const tickets: SeasonTicket[] = seasonTickets.filter(t => t.descriptor.id === seasonTicketDescriptor.id);
        setDescriptor(seasonTicketDescriptor);
        setTickets(tickets);
        setLoaded(true);
      };
      if (!loaded) {
        init();
      }
    }
  }, [authenticated])


  return (
    <>
      <h1 className={styles.pageTitle}>{t('prolong_season_ticket.h1_text')} {authenticated ? descriptor?.title : null}</h1>
        <div className={styles.wrapper}>
          {
            !authenticated ?
            <div className={styles.nonAuthWrapper}>
              <IllError />
              <h5>{t('prolong_season_ticket.h5_text')}.</h5>
            </div>
            :
            <>
              <div className={styles.columns}>
                <div className={styles.leftColumn}>
                  <ColumnTitle
                    icon={<First />}
                    text={t('prolong_season_ticket.column_title.0')}
                  />
                  {
                    loaded ? tickets.map((seasonTicket: SeasonTicket) => {
                        return (
                          <div key={seasonTicket.id + 'div'} className={styles.ticketRow}>
                            <CustomCheckbox
                              key={seasonTicket.id + 'cb'}
                              name={seasonTicket.id + 'cb'}
                              onChange={(ev) => {
                                if (ev.target.checked) {
                                  if (selectedTickets.length > 4) {
                                    setError({ errorType: 'TooMuchTickets' } as ProlongSeasonTicketBaseErrors);
                                  } else {
                                    setSelectedTickets([...selectedTickets, seasonTicket].filter(unique((it: SeasonTicket) => it.id)));
                                  }
                                } else {
                                  setSelectedTickets(selectedTickets.filter((it: SeasonTicket) => it.id !== seasonTicket.id));
                                }
                              }}
                              label={(
                                <Ticket
                                  key={seasonTicket.id + 'tc'}
                                  sector={seasonTicket.place.row.sector.title}
                                  row={seasonTicket.place.row.number}
                                  place={seasonTicket.place.number}
                                />
                              )}
                            />

                          </div>
                        );
                      })
                      :
                      <Skeleton type='span' />
                  }
                </div>

                <div className={styles.rightColumn}>
                  <ColumnTitle
                    icon={<Second />}
                    text={t('prolong_season_ticket.column_title.1')}
                  />
                  {
                    loaded ? descriptor?.prolongationDescriptors.map((d: SeasonTicketDescriptor) => {
                      return <div key={d.id + 'div'} className={styles.ticketRow}>
                        <CustomRadioInput
                          key={d.id + 'rb'}
                          name={d.id + 'rb'}
                          checked={selectedDescriptor?.id === d.id}
                          onChange={(ev) => {
                            ev.target.checked ? setSelectedDescriptor(d) : setSelectedDescriptor(undefined);
                          }}
                          label={(
                            <SeasonPass
                              key={d.id}
                              id={d.id}
                              title={d.title}
                              logo={props.preferencesService.get(PreferenceKeys.LOGO_SRC)}
                              hideArrow={true}
                            />
                          )}
                        />
                      </div>;
                    }) : <Skeleton type='span' />
                  }
                </div>
              </div>

              <div className={styles.footer}>
                {
                  loaded &&
                  <GoToCartButton
                    onClick={async () => {
                      if (selectedTickets.length < 1) {
                        setError({ errorType: 'NoTickets' } as ProlongSeasonTicketBaseErrors);
                        return;
                      }
                      if (selectedDescriptor === undefined) {
                        setError({ errorType: 'NoSeasonTicket' } as ProlongSeasonTicketBaseErrors);
                        return;
                      }

                      const jobs = selectedTickets.map((t: SeasonTicket) => {
                        return props.prolongSeasonTicketService.prolong(t.visibleId, selectedDescriptor!.id);
                      });
                      await Promise.all(jobs)
                        .then(() => history.push(Path.CART))
                        .catch(error => {
                          const errorUid = error?.graphQLErrors[0]?.extensions?.uid;
                          setError(errorMapper(errorUid));
                        });
                    }}
                    title={t('common.add_to_cart')}
                  />
                }
              </div>

              <Modal
                visible={error !== undefined}
                closeModal={() => setError(undefined)}
                outsideClickClose={true}
              >
                <>
                  {
                    error &&
                    getErrorModalBody(
                      errorsModalContent(error),
                      () => setError(undefined),
                    )}
                </>
              </Modal>
            </>
          }
        </div>
    </>
  )
}

export default ProlongSeasonTicket;
