import { useBehaviorSubject, useServiceData } from 'shared/utils';
import { SeasonsPassService } from 'entities/SeasonsPass/model';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';
import { AuthService } from 'processes/Auth';
import { EventCard, MatchBanner, MatchesService, NoEventsBanner } from 'entities/Matches';
import { ProlongSeasonTicketService } from 'features/ProlongSeasonTicket';
import { sortByStartTime } from './utils';
import { useTranslation } from 'react-i18next';

import { CardsGrid, PageHead } from 'shared/ui';
import { SeasonPass } from 'entities/SeasonsPass/ui';
import ErrorScreen from 'pages/ErrorScreen/ErrorScreen';
import SkeletonEvents from './SkeletonEvents';

import styles from './Events.module.scss';
import { Link } from 'react-router-dom';

const Events = (props: {
  matchesService: MatchesService;
  authService: AuthService;
  seasonsPassService: SeasonsPassService;
  preferencesService: PreferencesService;
  prolongSeasonTicketService: ProlongSeasonTicketService;
}) => {
  const authenticated = useBehaviorSubject(props.authService.authenticated);
  const { matchesService, seasonsPassService, prolongSeasonTicketService } = props;
  const { ...matchesData } = useServiceData(() => matchesService.getAvailable(), [
    matchesService,
    authenticated,
  ]);
  const { ...seasonsPassData } = useServiceData(() => seasonsPassService.getList(), [
    seasonsPassService,
    authenticated,
  ]);
  const { ...prolongSeasonTicketData } = useServiceData(
    () => prolongSeasonTicketService.getDescriptorsToProlong(),
    [prolongSeasonTicketService, authenticated]
  );
  const { t } = useTranslation();

  const brandColors = {
    colorThemeClubNavigation1: props.preferencesService?.get(PreferenceKeys.CLUB_NAVIGATION_1),
    colorThemeClubPrimaryLight: props.preferencesService?.get(PreferenceKeys.CLUB_PRIMARY_LIGHT),
  };

  if (matchesData.loading || seasonsPassData.loading || prolongSeasonTicketData.loading)
    return <SkeletonEvents />;

  if (matchesData.error || seasonsPassData.error || prolongSeasonTicketData.error)
    return (
      <ErrorScreen
        error={matchesData.error || seasonsPassData.error || prolongSeasonTicketData.error}
      />
    );

  if (!matchesData.data?.list)
    return <NoEventsBanner title={t('events.no_events_banner.title')} brandColors={brandColors} />;

  const sortedMatches = sortByStartTime(matchesData.data?.list);

  return (
    <main className={styles.main}>
      <PageHead title={t('react_app.meta_title')} description={t('events.page_head.description')} />
      {matchesData && matchesData.data?.list && matchesData.data?.list?.length > 0 ? (
        <>
          <Link to={`/tickets/${sortedMatches[0]?.id}`}>
            <MatchBanner
              preferencesService={props.preferencesService}
              match={sortedMatches[0]}
              loading={matchesData.loading}
            />
          </Link>
          {sortedMatches.length > 1 && (
            <CardsGrid title={t('events.cards_grid.title.0') ?? 'Ближайшие события'}>
              {sortedMatches.slice(1, 3).map(match => (
                <Link key={match.id + 'L'} to={`/tickets/${match.id}`}>
                  <EventCard key={match.id} match={match} />
                </Link>
              ))}
            </CardsGrid>
          )}
          {sortedMatches.length > 3 && (
            <CardsGrid title={t('events.cards_grid.title.1') ?? 'Предстоящие события'}>
              {sortedMatches.slice(3).map(match => (
                <Link key={match.id + 'L'} to={`/tickets/${match.id}`}>
                  <EventCard key={match.id} match={match} />
                </Link>
              ))}
            </CardsGrid>
          )}
        </>
      ) : (
        <>
          <h3 className={styles.title}>{t('events.title')}</h3>
          <NoEventsBanner title={t('events.no_events_banner.title')} brandColors={brandColors} />
        </>
      )}
      {prolongSeasonTicketData &&
        prolongSeasonTicketData.data &&
        prolongSeasonTicketData.data.length !== 0 && (
          <CardsGrid
            title={t('events.cards_grid.title.2') ?? 'Абонементы для продления'}
            columns={1}
          >
            {prolongSeasonTicketData.data.map(seasonTicket => (
              <Link key={seasonTicket.id + 'LP'} to={`/prolong-season-tickets/${seasonTicket.id}`}>
                <SeasonPass
                  id={seasonTicket.id}
                  key={seasonTicket.id}
                  title={seasonTicket.title}
                  logo={props.preferencesService.get(PreferenceKeys.LOGO_SRC)}
                />
              </Link>
            ))}
          </CardsGrid>
        )}
      {seasonsPassData?.data?.list && seasonsPassData.data?.list.length > 0 && (
        <CardsGrid title={t('events.cards_grid.title.3') ?? 'Абонементы'}>
          {seasonsPassData.data.list.map(seasonTicket => (
            <Link key={seasonTicket.id + 'L'} to={`/season-tickets/${seasonTicket.id}`}>
              <SeasonPass
                id={seasonTicket.id}
                key={seasonTicket.id}
                title={seasonTicket.title}
                logo={props.preferencesService.get(PreferenceKeys.LOGO_SRC)}
              />
            </Link>
          ))}
        </CardsGrid>
      )}
    </main>
  );
};

export default Events;
