import React from 'react';
import { ReactComponent as IllRegistrationSuccessSvg } from './ill-registration-success.styled.svg';

function propsToCssVars(props?: IllRegistrationSuccessProps): React.CSSProperties {
    return {
        '--color-theme_clubNavigation1': props?.styles?.colorThemeClubNavigation1,
        '--color-theme_clubNavigation1-override': props?.styles?.colorThemeClubNavigation1Override,
        '--color-theme_clubPrimaryLight': props?.styles?.colorThemeClubPrimaryLight,
        '--color-theme_clubPrimaryLight-override': props?.styles?.colorThemeClubPrimaryLightOverride,
    } as React.CSSProperties
}

export type IllRegistrationSuccessProps = {
    styles?: {
        colorThemeClubNavigation1?: string
        colorThemeClubNavigation1Override?: string
        colorThemeClubPrimaryLight?: string
        colorThemeClubPrimaryLightOverride?: string
    }
}

/**
 * Generated component
 * Available CSS variables: color-theme_clubNavigation1, color-theme_clubNavigation1-override, color-theme_clubPrimaryLight, color-theme_clubPrimaryLight-override
 **/
export default function IllRegistrationSuccess(props?: IllRegistrationSuccessProps) {
    return <IllRegistrationSuccessSvg style={{ ...propsToCssVars(props) }}/>
}