import { useTranslation } from 'react-i18next';
import styles from './Ticket.module.scss';
import formatShortSectorName from 'entities/Venue/utils/formatShortSectorName';

function Ticket(props: {
  sector: string,
  row: string,
  place: string,
}) {
  const { t, i18n } = useTranslation();
  const t_en = i18n.getFixedT('en', null, 'common');
  

  return (
    <div className={styles.ticket}>
      <div className={styles.place}>
        <div className={styles.placeName}>
          <p>{t('common.places.2')}</p>
          {
            i18n.language !== 'en' &&
            <p>{t_en('places.2')}</p>
          }
        </div>
        <h2>{formatShortSectorName(props.sector.padStart(2, '0'))}</h2>
      </div>
      <div className={styles.place}>
        <div className={styles.placeName}>
          <p>{t('common.places.1')}</p>
          {
            i18n.language !== 'en' &&
            <p>{t_en('places.1')}</p>
          }
        </div>
        <h2>{props.row.padStart(2, '0')}</h2>
      </div>
      <div className={styles.place}>
        <div className={styles.placeName}>
          <p>{t('common.places.0')}</p>
          {
            i18n.language !== 'en' &&
            <p>{t_en('places.0')}</p>
          }
        </div>
        <h2><span>{props.place.padStart(2, '0')}</span></h2>
      </div>
    </div>
  )
}

export default Ticket
