import { deserialize, ObjectDeserializer } from '@sebbia/object-deserializer';
import {
  BannerCardBlock,
  BannerContentBlock,
  ModalContent,
  SectionBlock,
  WarnBannerConfiguration,
} from 'features/ConfigurableWarningModal/model/types';
import { WarningModalService } from 'features/ConfigurableWarningModal/model/WarningModalService';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';

function blocksDeserializer(b: ObjectDeserializer): BannerContentBlock | undefined {
  const type = b.required('type').asString;
  switch (type) {
    case 'TITLE': {
      return {
        type: 'TITLE',
        content: b.required('content').asString,
      };
    }
    case 'TEXT': {
      return {
        type: 'TEXT',
        content: b.required('content').asString,
      };
    }
    case 'SMALL_CARD': {
      return {
        type: 'SMALL_CARD',
        description: b.required('description').asString,
        imgLink: b.optional('imgLink')?.asString,
      };
    }
    case 'CARD': {
      return {
        type: 'CARD',
        title: b.optional('title')?.asString,
        description: b.optional('description')?.asString,
        imgLink: b.optional('imgLink')?.asString,
      } as BannerCardBlock;
    }
    case 'SECTION': {
      return {
        type: 'SECTION',
        content: b.required('content').asArrayOfObjects(blocksDeserializer),
      } as SectionBlock;
    }
    default: {
      return undefined;
    }
  }
}

function configDeserializer(conf: ObjectDeserializer): WarnBannerConfiguration {
  const enabled = conf.required('enabled').asBool;
  if (!enabled) {
    return {
      enabled: false,
    };
  } else {
    return {
      enabled: true,
      mainMessage: conf.required('mainMessage').asString,
      detailsButtonText: conf.optional('detailsButtonText')?.asString,
      content: conf.optional('content')?.asObject<ModalContent>(c => {
        return {
          title: c.optional('title')?.asString,
          blocks: c
            .required('blocks')
            ?.asArrayOfOptionalObjects<BannerContentBlock>(blocksDeserializer)
            .filter(it => it !== undefined) as Array<BannerContentBlock>,
        };
      }),
    };
  }
}

export class WarningModalServiceImpl implements WarningModalService {
  private config!: Promise<WarnBannerConfiguration>;

  constructor(private preferencesService: PreferencesService) {
    this.init();
  }

  async init() {
      this.config = new Promise(async (resolve, reject) => {
        const configJsonUrl = this.preferencesService.getNullable(
            PreferenceKeys.WARN_BANNER_CONFIG_FILE_URL
          );
          if (!configJsonUrl) {
            resolve({
              enabled: false,
            });
          } else {
            try {
              const response = await fetch(configJsonUrl!);
              const rawConfig = await response.json();
              resolve(deserialize(rawConfig, configDeserializer));
            } catch (e) {
              console.error(
                `Could not inititalize warn banner because of error. Fallback to disabled banner`,
                e
              );
              resolve({
                enabled: false,
              });
            }
          }
      })    
  }

  async getConfig(): Promise<WarnBannerConfiguration> {
    return await this.config;
  }
}
