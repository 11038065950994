import { Skeleton } from 'shared/ui';
import styles from './EventCard.module.scss';

function SkeletonEventCard() {
  return (
    <div className={styles.eventContainer}>
        <Skeleton type="avatar" style={{ width: '70px', height: '70px' }} />
      <div className={styles.eventInfo}>
          <Skeleton type="span" />
          <Skeleton type="span" />
          <Skeleton type="span" />
          <Skeleton type="span" />
      <div className={styles.eventArrow}>
      </div>
      </div>
    </div>
  );
}

export default SkeletonEventCard