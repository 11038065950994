import { ObjectDeserializer } from '@sebbia/object-deserializer';
import { EventStatus, remoteFileDeserializer, teamDeserializer } from 'shared/model';
import { LocalizedString, getDateTimeParserWithTimezone } from 'shared/utils';
import { Match, RemoteVenue, Stage, Tournament } from './types';

function tournamentDeserializer(o: ObjectDeserializer): Tournament {
  return {
    title: o.required('title').as((d) => LocalizedString.fromObject(d)),
    description: o.optional('description')?.as((d) => LocalizedString.fromObject(d)),
    logo: o.optional('logo')?.asObject(remoteFileDeserializer),
  };
}

function stageDeserializer(o: ObjectDeserializer): Stage {
  return {
    id: o.required('id').asString,
    title: o.required('title').as((d) => LocalizedString.fromObject(d)),
    tournament: o.required('tournament').asObject(tournamentDeserializer),
  };
}

function venueDeserializer(o: ObjectDeserializer): RemoteVenue {
  return {
    id: o.required('id').asString,
    title: o.required('title').as((d) => LocalizedString.fromObject(d)),
    description: o.optional('description')?.as((d) => LocalizedString.fromObject(d)),
    location: o.optional('location')?.as((d) => LocalizedString.fromObject(d)),
    timezone: o.optional('timezone')?.asString
  };
}

export function matchDeserializer(o: ObjectDeserializer): Match {
  const venue = o.required('venue').asObject(venueDeserializer)
  const datetimeParser = getDateTimeParserWithTimezone(venue.timezone)

  return {
    id: o.required('id').asString,
    title: o.required('title').as((o) => LocalizedString.fromObject(o)),
    description: o.optional('description')?.as((o) => LocalizedString.fromObject(o)),
    startTime: o.required('startTime').as(datetimeParser),
    endTime: o.optional('endTime')?.as(datetimeParser),
    status: o.required('status').asEnum(EventStatus),
    cover: o.optional('cover')?.asObject(remoteFileDeserializer),
    team1: o.required('team1').asObject(teamDeserializer),
    team2: o.required('team2').asObject(teamDeserializer),
    stage: o.required('stage').asObject(stageDeserializer),
    venue: venue,
  };
}
