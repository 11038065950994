import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ProlongSeasonTicketService, ProlongSeasonTicketServiceImpl } from 'features/ProlongSeasonTicket';
import { AuthService } from 'processes/Auth';

function initProlongSeasonTicketService(
  client: ApolloClient<NormalizedCacheObject>,
  auth: AuthService
): ProlongSeasonTicketService {
  return new ProlongSeasonTicketServiceImpl(client, auth);
}

export default initProlongSeasonTicketService;
