import SkeletonMatchBanner from 'entities/Matches/ui/MatchBanner/SkeletonMatchBanner';
import SkeletonEventCard from 'entities/Matches/ui/EventCard/SkeletonEventCard';
import SkeletonSeasonPass from 'entities/SeasonsPass/ui/SeasonPass/SkeletonSeasonPass';
import Skeleton from 'shared/ui/Skeleton/Skeleton';
import CardsGrid from 'shared/ui/CardsGrid/CardsGrid';
import styles from './Events.module.scss';

function SkeletonEvents() {
  return (
    <main className={styles.main}>
      <SkeletonMatchBanner />
      <Skeleton type='span' style={{margin: '12px 0', height: '22px'}}/>
      <CardsGrid >
        <SkeletonEventCard />
        <SkeletonEventCard />
      </CardsGrid>
      <Skeleton type='span' style={{margin: '12px 0', height: '22px'}}/> 
      <CardsGrid >
        <SkeletonSeasonPass />
        <SkeletonSeasonPass />
      </CardsGrid>
    </main>
  )
}

export default SkeletonEvents