import i18next from 'i18next';

const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const validatePhone = (phone: string) => {
  const re = /^((\+?7|8)[ -]?)?((\(\d{3}\))|(\d{3}))?([ -])?(\d{3}[- ]?\d{2}[- ]?\d{2})$/
  return re.test(phone)
}

const validateBirthday = (birthday: string) => {
  const re = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  return re.test(birthday)
}
export interface IFields {
  [key: string]: {
    value: string,
    required?: boolean,
    checked?: boolean
    email?: boolean,
    phone?: boolean,
    birthday?: boolean
    minLength?: number,
    maxLength?: number,
    matchWith?: string,
    requiredMessage?: string,
    phoneMessage?: string,
    emailMessage?: string,
    birthdayMessage?: string,
    matchWithMessage?: string,
    minLengthMessage?: string,
    maxLengthMessage?: string,
    allowedTypesMessage?: string,
    touched?: boolean,
    file?: boolean,
    allowedTypes?: 'jpg'  | 'jpeg' | 'png' | 'gif',
  }
}

export type IErrors = Record<string, string>

function validate(fields: IFields, submit = false) {
  let errors: IErrors = {}

  for(let field in fields) {
    if(submit) {
      fields[field].touched = true
    }

    const currentField = fields[field]

    if(currentField.required && (currentField.value === '' || !currentField.value) && currentField.touched) {
      errors[field] = currentField.requiredMessage ? currentField.requiredMessage : `${i18next.t('validate.0')}\u00A0${i18next.t('validate.1')}`
    }

    if(currentField.file && currentField.required && Object.keys(currentField.value).length === 0 && currentField.touched) {
      errors[field] = currentField.requiredMessage ? currentField.requiredMessage : `${i18next.t('validate.0')}\u00A0${i18next.t('validate.1')}`
    }

    if(!errors[field] && currentField.phone && !validatePhone(currentField.value) && currentField.touched) {
      errors[field] = currentField.phoneMessage ? currentField.phoneMessage : i18next.t('validate.2')
    }

    if(!errors[field] && currentField.email && !validateEmail(currentField.value) && currentField.touched) {
      errors[field] = currentField.emailMessage ? currentField.emailMessage : i18next.t('validate.3')
    }

    if(!errors[field] && currentField.birthday && !validateBirthday(currentField.value) && currentField.touched) {
      errors[field] = currentField.birthdayMessage ? currentField.birthdayMessage : i18next.t('validate.4')
    }

    if(!errors[field] && currentField.matchWith && currentField.value !== fields[currentField.matchWith].value && currentField.touched) {
      errors[field] = currentField.matchWithMessage ? currentField.matchWithMessage : i18next.t('validate.5')
    }

    if(!errors[field] && currentField.minLength && currentField.value !== '' && currentField.value.length < currentField.minLength && currentField.touched) {
      errors[field] = currentField.minLengthMessage ? currentField.minLengthMessage : `${i18next.t('validate.6')} ${currentField.minLength} ${i18next.t('validate.8')}`
    }

    if(!errors[field] && currentField.maxLength && currentField.value !== '' && currentField.value.length > currentField.maxLength && currentField.touched) {
      errors[field] = currentField.maxLengthMessage ? currentField.maxLengthMessage : `${i18next.t('validate.7')} ${currentField.maxLength} ${i18next.t('validate.8')}`
    }

    // if(!errors[field] && currentField.file && currentField.touched && currentField.allowedTypes && !currentField.allowedTypes.includes(currentField.value[0].type.split('/')[1])) {
    //   errors[field] = currentField.allowedTypesMessage ? currentField.allowedTypesMessage : 'Неверный тип файла!';
    // }

  }

  return errors;
}

export default validate
