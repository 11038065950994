import { useTranslation } from 'react-i18next';
import styles from './ErrorScreen.module.scss';
import utilStyles from 'shared/styles/util.module.scss';
import { classNames } from 'shared/utils';

function ErrorScreen(props: { error?: Error }) {
  const { t } = useTranslation();
  const message = props.error?.message ?? t('error_screen_message') ?? 'Что-то пошло не так :(';
  return (
    <div className={classNames(styles.error, utilStyles.center)}>
      <h1>{message}</h1>
    </div>
  );
}
export default ErrorScreen;
