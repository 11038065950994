import { useTranslation } from 'react-i18next';
import styles from './AppCard.module.scss';
import app_store_badge from 'shared/assets/images/app_store_badge.svg';
import google_play_badge from 'shared/assets/images/google_play_badge.svg';

function AppCard() {
  const { t } = useTranslation();

  return (
    <div className={styles.card}>
      <h5>{t('app_card.h5_text')}</h5>
      <p>{t('app_card.p_text')}</p>
      <div className={styles.badgesContainer}>
        <img src={app_store_badge} alt={t('app_card.img.0') ?? "Загрузите в App Store"} />
        <img src={google_play_badge} alt={t('app_card.img.1') ?? "Доступно в Google Play"} />
      </div>
    </div>
  );
}

export default AppCard;
