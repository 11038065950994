import { PreferencesService, PreferenceKeys, Preferences } from './types';

class PreferencesServiceImpl implements PreferencesService {
  private preferences: Partial<Preferences> = {};

  constructor() {
    for (let key of Object.values(PreferenceKeys)) {
      this.preferences[key] = this.getPreferenceFromWindow('REACT_APP_' + key);
    }
  }

  private getPreferenceFromWindow(key: string): string | undefined {
    const varFromWindow = ((window as any)[key] || '').trim();
    const isVarFromWindowEmpty = varFromWindow.startsWith('${') || varFromWindow === '';
    return isVarFromWindowEmpty ? process.env[key] : varFromWindow;
  }

  private getRequiredPreference(key: string): string {
    const val = this.getPreferenceFromWindow(key);
    if (!val) {
      throw new Error(`${key} is not defined`);
    }
    return val;
  }

  get(key: PreferenceKeys) {
    const preference = this.preferences[key];
    if (!preference) throw new Error(`Key ${key} is not found`);
    return preference;
  }

  getNullable(key: PreferenceKeys) {
    return this.preferences[key]
  }
}

export default PreferencesServiceImpl;
