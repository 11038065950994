import { useTranslation } from 'react-i18next';
import styles from './LoyaltyAccrual.module.scss';

function LoyaltyAccrual(props: { percent: number }) {
  const { t } = useTranslation();

  return (
    <div className={styles.loyalty}>
      <span>{`${props.percent}%`}</span>
      <p>
        {t('loyalty_accrual.0')} <br /> {t('loyalty_accrual.1')}
      </p>
    </div>
  );
}

export default LoyaltyAccrual;
