import { DeserializationError, DeserializationPath } from '@sebbia/object-deserializer';
import { DateTime } from 'luxon';

export function dateTimeParser(value: any, path: DeserializationPath, timezone?: string): DateTime {
  if (typeof value !== 'string') throw new DeserializationError(path, 'Not a string');
  const result = DateTime.fromISO(value);
  if (timezone) {
    return result.setZone(timezone);
  }
  return result;
}

export function getDateTimeParserWithTimezone(timezone?: string): typeof dateTimeParser {
  return (value: any, path: DeserializationPath) => dateTimeParser(value, path, timezone);
}
