
import { SeparatedList, Skeleton } from 'shared/ui';
import SkeletonOrderTickets from '../OrderItems/SkeletonOrderTickets';
import styles from './OrderItemsList.module.scss';

function SkeletonOrderItemsList() {
  return (
    <article className={styles.order}>
      <div className={styles.header}>
        <h4><Skeleton type="span" style={{width: '170px'}}/></h4>
      </div>
      <SeparatedList>
        <SkeletonOrderTickets numberTickets={3}/>
        <SkeletonOrderTickets />
      </SeparatedList>
    </article>
  )
}

export default SkeletonOrderItemsList