import { useTranslation } from 'react-i18next';
import { LoyaltyDescriptor } from "entities/Loyalty/model";
import { InfoScreen, Modal } from "shared/ui";
import { pluralize } from "shared/utils";

import styles from './LoyaltyInfoScreen.module.scss';

function LoyaltyInfoScreen(props: {
  loyaltyDescriptor: LoyaltyDescriptor,
  totalLoyalty: number,
  visible: boolean,
  closeModal: () => void,
}) {
  const { totalLoyalty, loyaltyDescriptor } = props
  const { t } = useTranslation();

  const activePeriodText = loyaltyDescriptor?.bonusActivePeriodDays ? `${loyaltyDescriptor.bonusActivePeriodDays} ${t('loyalty_info_screen.active_period_text.0')}` : `${t('loyalty_info_screen.active_period_text.1')}`

  return (
    <Modal
      visible={props.visible}
      closeModal={props.closeModal}
      outsideClickClose={true}
      isLoyaltyModal={true}
    >
      <InfoScreen
        onClose={props.closeModal}
        backgroundColorHeader='systemOrange'
        title={t('loyalty_info_screen.info_screen.title')}
        subtitle={`${t('loyalty_info_screen.info_screen.subtitle.0')} ${loyaltyDescriptor.loyaltyCurrencyName.plural.toLowerCase()}, ${t('loyalty_info_screen.info_screen.subtitle.1')}.`}
        headerChildren={
          <h3>
            {t('loyalty_info_screen.info_screen.header_children')}
            <div>
              <span className={styles.loyalty}>{totalLoyalty}</span>
              &nbsp;
              {pluralize(totalLoyalty, [loyaltyDescriptor.loyaltyCurrencyName.mod1, loyaltyDescriptor.loyaltyCurrencyName.mod24, loyaltyDescriptor.loyaltyCurrencyName.mod50 ]).toUpperCase()}
            </div>
          </h3>
        }
      >
        <>
          <h2>{t('loyalty_info_screen.info_screen.text.0')}</h2>
          <p>{t('loyalty_info_screen.info_screen.text.1')} {loyaltyDescriptor.accrualPercentage}{t('loyalty_info_screen.info_screen.text.2')} {activePeriodText}.</p>
          <h2>{t('loyalty_info_screen.info_screen.text.3')}</h2>
          <p>
            {t('loyalty_info_screen.info_screen.text.4')} {loyaltyDescriptor.loyaltyCurrencyName.plural.toLowerCase()} {t('loyalty_info_screen.info_screen.text.5')} {loyaltyDescriptor.bonusPriceCoverPercentage}{t('loyalty_info_screen.info_screen.text.6')} {loyaltyDescriptor.applicableWithDiscount ? t('loyalty_info_screen.info_screen.applicable_with_discount.0') : t('loyalty_info_screen.info_screen.applicable_with_discount.1')} {t('loyalty_info_screen.info_screen.text.7')}
            <br/>
            {t('loyalty_info_screen.info_screen.text.8')} <a href={loyaltyDescriptor.legalityDocumentationLink} target='_blank' rel="noreferrer">{t('loyalty_info_screen.info_screen.text.9')}</a>.
          </p>
        </>
      </InfoScreen>
    </Modal>
  )
}

export default LoyaltyInfoScreen
