import styles from './ShoppingCart.module.scss';

function ShoppingCart(props: { children: JSX.Element[] | JSX.Element }) {
  return (
    <div className={styles.ShoppingCart}>
      <div className={styles.cart}>{props.children}</div>
    </div>
  );
}

export default ShoppingCart;
