import { Skeleton } from 'shared/ui';
import styles from './PaymentPointsCard.module.scss';

function SkeletonPaymentPointsCard() {
  return (
    <div className={styles.PaymentPointsCard} style={{margin: '8px 0 12px'}}>
      <div className={styles.cardWrap}>
        <div className={styles.cardTitle}>
          <span className={styles.title}><Skeleton type="span" style={{ width: '190px'}}/></span>
        </div>
        <span className={styles.subtitle}><Skeleton type="span" style={{ width: '100%'}}/></span>
        <div className={styles.cardMain} >
          <div className={styles.balance}>
            <span><Skeleton type="span" /></span>
          </div>
          <div className={styles.total}>
            <Skeleton type="span" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonPaymentPointsCard