import { ContactType, PersonUpdateInput } from "entities/Profile";

export const signUpInitialState = {
  name: {
    value: '',
    required: true,
  },
  surname: {
    value: '',
    required: true,
  },
  patronymic: {
    value: '',
    required: false,
  },
  birthday: {
    value: '',
    required: false,
    birthday: true,
  },
  email: {
    value: '',
    required: true,
    email: true,
  },
  phone: {
    value: '',
    required: true,
    phone: true,
    minLength: 16,
  },
  password: {
    value: '',
    required: true,
  },
};

export const getSignUpInitialState = (initialValues?: PersonUpdateInput, lang?: string) => {
  return {
    name: {
      value: initialValues?.name?.toString(lang) || '',
      required: true,
    },
    surname: {
      value: initialValues?.surname?.toString(lang) || '',
      required: true,
    },
    patronymic: {
      value: initialValues?.patronymic?.toString(lang) || '',
      required: false,
    },
    birthday: {
      value: initialValues?.birthday?.toFormat('dd.MM.yyyy') ?? '',
      required: false,
      birthday: true,
    },
    email: {
      value: initialValues?.contacts?.find(it => it.type === ContactType.EMAIL)?.value ?? '',
      required: true,
      email: true,
    },
    phone: {
      value: initialValues?.contacts?.find(it => it.type === ContactType.PHONE)?.value ?? '',
      required: true,
      phone: true,
      minLength: 16,
    },
  };
};
