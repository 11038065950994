import { gql } from '@apollo/client';

export const MATCH_FRAGMENT_FIELDS = gql`
  fragment MatchFields on MatchLocalised {
    id
    title
    description
    startTime
    endTime
    status
    cover {
      publicLink
    }
    stage {
      id
      title
      tournament {
        title
        description
        logo {
          publicLink
        }
      }
    }
    venue {
      id
      title
      description
      location
      timezone
    }
    team1 {
      id
      title
      logo {
        publicLink
        fileName
      }
      location
    }
    team2 {
      id
      title
      logo {
        publicLink
      }
      location
    }
  }
`;
