import { ID } from "shared/model";
import { Money } from "shared/utils";


export type LoyaltyDescriptor = {
  id: ID;
  bonusActivePeriodDays?: number;
  bonusPriceCoverPercentage: number;
  maxExpenditurePerTransactionPercentage: number;
  applicableWithDiscount: boolean;
  legalityDocumentationLink?: string;
  isActive: boolean;
  accrualPercentage: number;
  loyaltyCurrencyName: {
    plural: string;
    mod1: string;
    mod24: string;
    mod50: string;
  };
};

export type LTY_TransactionQuery = {
  getUserBonusCount: Money
}

export enum Tickets_LoyaltyApplicabilityError {
  LOYALTY_PROGRAM_IS_NOT_ACTIVE,
  NOT_APPLICABLE_WITH_DISCOUNT,
}

export type Tickets_LoyaltyOrderCheckResult = {
  isApplicable: boolean;
  applicabilityError?: Tickets_LoyaltyApplicabilityError;
  loyaltyAccrued: Money;
  maxPossibleExpenditure: Money;
  loyaltySpent: Money;
}
export interface LoyaltyService {
  getLoyaltyDescriptor(): Promise<LoyaltyDescriptor>;
  getCoins(): Promise<Money>;
  checkLoyaltyOrder(orderId: ID, userId?: ID): Promise<LTY_TransactionQuery & Tickets_LoyaltyOrderCheckResult>;
}
