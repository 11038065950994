import styles from './EventCard.module.scss';
import Logo from 'shared/ui/Logo/Logo';
import { ReactComponent as IconCalendar } from 'shared/assets/icons/calendar.svg';
import { ReactComponent as IconRight } from 'shared/assets/icons/right_arrow.svg';
import { Match, Tournament } from 'entities/Matches/model';
import { DateTime } from 'luxon';
import { classNames } from 'shared/utils';
import i18next from 'i18next';

function EventCard(props: { match: Match }) {
  return (
    <div className={styles.eventContainer}>
      <EventLogo link={props.match.team2.logo?.publicLink} />
      <div className={styles.eventInfo}>
        <EventLeague tournament={props.match.stage.tournament} />
        <EventDate date={props.match.startTime} />
        <div className={styles.eventHomeTeam}>{props.match.team1.title.toString(i18next.language)}</div>
        <div className={styles.eventGuestTeam}>vs {props.match.team2.title.toString(i18next.language)}</div>
        <div className={styles.eventArrow}>
          <IconRight />
        </div>
      </div>
    </div>
  );
}

function EventLeague(props: { tournament: Tournament }) {
  return (
    <div className={styles.eventLeague}>
      <Logo src={props.tournament.logo?.publicLink} classes={classNames(styles.logoSize16)} />
      <span>{props.tournament.title.toString(i18next.language)}</span>
    </div>
  );
}

function EventDate(props: { date: DateTime }) {  
  const weekdayShort = props.date.toFormat('ccc', { locale: i18next.language });
  const dateShort = props.date.toFormat('dd MMM yyyy, HH:mm', { locale: i18next.language }).replace(/[.]/, '');
  return (
    <div className={styles.eventDate}>
      <IconCalendar /> <span>{weekdayShort} </span>
      {dateShort}
    </div>
  );
}

function EventLogo(props: { link?: string }) {
  return (
    <div className={styles.eventLogo}>
      <Logo src={props.link} classes={classNames(styles.logoSize70)} />
    </div>
  );
}

export default EventCard;
