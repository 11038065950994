import { useState, useEffect } from 'react';
import { useIsMounted } from 'shared/utils';

export const useMovementWithinMultistep = (conditional?: boolean) => {
  const isMounted = useIsMounted()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [forbiddenNextStep, setForbiddenNextStep] = useState(true)

  useEffect(() => {
    if (!isMounted()) return
    if (conditional) {
      return setForbiddenNextStep(true)
    }

    return setForbiddenNextStep(false)
  }, [setForbiddenNextStep, conditional, isMounted])

  const goNext = () => {
    setSelectedIndex(selectedIndex + 1)
  }
  
  const goPrevious = () => {
    if (selectedIndex !== 0)
    setSelectedIndex(selectedIndex - 1)
  }
  
  return { goNext, goPrevious, selectedIndex, forbiddenNextStep}
}
