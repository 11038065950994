import { SkeletonTicketDetails } from 'features/TicketDetails';
import { Skeleton } from 'shared/ui';
import styles from './OrderItems.module.scss';


function SkeletonOrderTickets(props: {numberTickets?: number}) {


  return (
    <section className={styles.event}>
      <div className={styles.header}>
          <h3><Skeleton type="span" style={{width: '250px', margin: '8px 0'}}/></h3>
        <div className={styles.subtitle}>
          <Skeleton type="span" style={{width: '200px', margin: '8px 0'}} />
        </div>
      </div>
        <SkeletonTicketDetails/>
        <SkeletonTicketDetails/>
    </section>
  )
}

export default SkeletonOrderTickets
