import { PreferenceKeys, PreferencesService } from 'processes/Preferences';
import { Helmet } from 'react-helmet-async';
import i18next from 'i18next';

function MainHead(props: {
  preferencesService?: PreferencesService;
  themeColor?: string;
}) {
  if (!props.preferencesService) return <></>
  
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{i18next.t('react_app.title')}</title>
      <meta name="title" content={i18next.t('react_app.meta_title') ?? ''} />
      <meta name="description" content={i18next.t('react_app.meta_description') ?? ''} />
      <link rel="preconnect" href={props.preferencesService.get(PreferenceKeys.GRAPHQL_ENDPOINT)}/>

      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="theme-color" content={props.themeColor ? props.themeColor : props.preferencesService.get(PreferenceKeys.CLUB_PRIMARY)} />
      <link rel="shortcut icon" href={props.preferencesService.get(PreferenceKeys.FAVICON)} />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

      <meta property="og:type" content="website"/>
      <meta property="og:title" content={i18next.t('react_app.title') ?? ''} />
      <meta property="og:description" content={i18next.t('react_app.meta_description') ?? ''} />
      <meta property="og:image" content={props.preferencesService.get(PreferenceKeys.META_DESCRIPTION_IMAGE)} />

      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:title" content={i18next.t('react_app.title') ?? ''}/>
      <meta property="twitter:description" content={i18next.t('react_app.meta_description') ?? ''} />
      <meta property="twitter:image" content={props.preferencesService.get(PreferenceKeys.META_DESCRIPTION_IMAGE)}/>

    </Helmet>
  )
}

export default MainHead

