import { createContext, useRef, useState } from "react"
import { ItemType, OrderContextProviderProps, OrderContextType, ReserveResultIds, ReservePlaceFunction, RemovePlacesFromOrderFunction, GoToCartFunction } from "./types";
import { PlacePrice } from "entities/Venue";

const defaultOrder: OrderContextType = {
  reservedPlaces: {current: []},
}

export const OrderContext = createContext<OrderContextType>(defaultOrder);

const OrderContextProvider = ({ children }: OrderContextProviderProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const reservedPlaces = useRef<PlacePrice[]>([]);
  const placesCountByType = useRef<number>(0);
  const reservePlace = useRef<ReservePlaceFunction | undefined>();
  const eventType = useRef<ItemType>(ItemType.TICKET);
  const removePlacesFromOrder = useRef<RemovePlacesFromOrderFunction>();
  const orderItemsIds = useRef<{[key: string]: ReserveResultIds}>({});
  const goToCart = useRef<GoToCartFunction>();
  const isAuthenticated = useRef<boolean | undefined>(false);

  const updateLoading = (val: boolean) => {
    setLoading(val);
  }
  const updateReservedPlaces = (places: PlacePrice[]) => {
    reservedPlaces.current = places;
  }
  const updateEventType = (type: ItemType) => {
    eventType.current = type;
  }
  const updateReservePlace = (func: ReservePlaceFunction | undefined) => {
    reservePlace.current = func;
  }
  const updatePlacesCountByType = (count: number) => {
    placesCountByType.current = count;
  }
  const updateOrderItemsIds = (val: {[key: string]: ReserveResultIds}) => {
    orderItemsIds.current = val;
  }
  const updateRemovePlacesFromOrder = (func: RemovePlacesFromOrderFunction | undefined) => {
    removePlacesFromOrder.current = func;
  }
  const updateGoToCart = (func: GoToCartFunction) => {
    goToCart.current = func;
  }
  const updateIsAuthenticated = (val: boolean | undefined) => {
    isAuthenticated.current = val;
  }

  const context = {
    reservedPlaces, updateReservedPlaces,
    eventType, updateEventType,
    reservePlace, updateReservePlace,
    placesCountByType, updatePlacesCountByType,
    orderItemsIds, updateOrderItemsIds,
    loading, updateLoading,
    removePlacesFromOrder, updateRemovePlacesFromOrder,
    goToCart, updateGoToCart,
    isAuthenticated, updateIsAuthenticated,
  };

  return <OrderContext.Provider value={context}>
    { children }
  </OrderContext.Provider>
}

export default OrderContextProvider;
