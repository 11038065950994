import React from 'react';
import ReactDOM from 'react-dom';
import 'i18n.ts';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';


import 'shared/styles/index.css';
import 'shared/styles/fonts.css';
import 'figma/styles/colors.css';
import 'figma/styles/text.css';
import OrderContextProvider from 'entities/Order/model/OrderContext';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <OrderContextProvider>
        <App />
      </OrderContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
