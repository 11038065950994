import { useTranslation } from 'react-i18next';
import styles from './Ticket.module.scss';

function MobileTicket(props: {
  sector: string,
  row: string,
  place: string,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.isMobileTicket}>
      {t('common.places.2')} {props.sector.padStart(2, '0')}, 
      {t('common.places.1')} {props.row.padStart(2, '0')}, 
      {t('common.places.0')} <span>{props.place.padStart(2, '0')}</span>
    </div>
  )
}

export default MobileTicket
