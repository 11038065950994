import styles from './Multistep.module.scss';
import { Steps } from '../../model';

function Multistep(props: {
  steps: Steps[],
  selectedIndex: number,
}) {
  return (
    <div className={styles.Multistep}>
      {props.steps[props.selectedIndex]?.component}
    </div>
  )
}

export default Multistep