import { useEffect, useState } from 'react';
import { ProfileService, RegistrationState } from 'entities/Profile';
import { AuthService } from 'processes/Auth';
import { Modal, ModalBody } from 'shared/ui';
import { ensureSufficientUserData } from 'entities/Profile/utils/ensureSufficientUserData';
import { UpdateProfileComponent } from 'features/Authentication/shared/ui/UpdateProfileForm/UpdateProfileComponent';
import { useBehaviorSubject } from 'shared/utils';
import { useTranslation } from 'react-i18next';

export const FilledProfileGuard = (props: {
  authService: AuthService;
  profileService: ProfileService;
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const authenticated = useBehaviorSubject(props.authService.authenticated)
  const registrationState = useBehaviorSubject(props.profileService.registrationState);
  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      if (props.authService.isAuthorized && registrationState !== RegistrationState.IN_PROCESS) {
        const profile = await props.profileService.getProfile();
        if (!(profile.person && ensureSufficientUserData(profile.person))) {
          setModalVisible(true);
        }
      }
    };
    init();
  }, [authenticated]);

  return (
    <Modal
      visible={modalVisible}
      closeModal={() => setModalVisible(false)}
      outsideClickClose={false}
    >
      <ModalBody>
        <h2>{t('filled_profile_guard')}</h2>
        <UpdateProfileComponent
          profileService={props.profileService}
          onFinish={() => {
            setModalVisible(false);
          }}
        />
      </ModalBody>
    </Modal>
  );
};
