import {Redirect, Route, Switch} from 'react-router-dom';
import {Services} from 'shared/model/types';
import Events from 'pages/Events/Events';
import Cart from 'pages/Cart/Cart';
import Event from 'pages/Event/Event';
import {AppLayout, Footer, Header} from 'shared/ui';
import {Path} from './path';
import SeasonTicket from 'pages/SeasonTicket/SeasonTicket';
import {PreferenceKeys} from 'processes/Preferences';
import MobileAppMap from 'pages/MobileAppMap/MobileAppMap';
import Onboarding from 'pages/Onboarding/Onboarding';
import {ConfigurableWarningModal} from 'features/ConfigurableWarningModal/ui/ConfigurableWarningModal';
import {FilledProfileGuard} from 'features/Authentication/FilledProfileGuard/FilledProfileGuard';
import ProlongSeasonTicket from 'features/ProlongSeasonTicket/ui/ProlongSeasonTicket/ProlongSeasonTicket';

function AppRoutes(props: { services: Services }) {
  return (
    <>
      <Switch>
        <Route exact path={Path.ONBOARDING}>
          <Onboarding
            loyaltyService={props.services.loyaltyService}
            preferences={props.services.preferencesService}
          />
        </Route>
        <Route
          path={Path.ACCOUNT}
          component={() => {
            window.location.href = props.services.preferencesService.get(PreferenceKeys.ACCOUNT_URL);
            return null;
          }}
        />
        <Route
          path="/mobile-app/:id"
          component={() => <MobileAppMap
            venueService={props.services.venueService}
            preferencesService={props.services.preferencesService}
          />}
        />
        <Route path="/">
          <FilledProfileGuard
            authService={props.services.authService}
            profileService={props.services.profileService}
          />
          <AppLayout
            header={
              <Header
                authService={props.services.authService}
                profileService={props.services.profileService}
                orderService={props.services.orderService}
                preferencesService={props.services.preferencesService}
              />
            }
            footer={<Footer preferencesService={props.services.preferencesService} />}
          >
            <>
              <ConfigurableWarningModal warningModalService={props.services.warningModalService} />
              <Switch>
                <Route
                  path={Path.SHOP}
                  component={() => (
                    <Events
                      matchesService={props.services.matchesService}
                      authService={props.services.authService}
                      seasonsPassService={props.services.seasonsPassService}
                      preferencesService={props.services.preferencesService}
                      prolongSeasonTicketService={props.services.prolongSeasonTicketService}
                    />
                  )}
                  exact
                />
                <Route
                  path="/tickets/:id"
                  component={() => (
                    <Event
                      matchesService={props.services.matchesService}
                      venueService={props.services.venueService}
                      authService={props.services.authService}
                      orderService={props.services.orderService}
                      preferencesService={props.services.preferencesService}
                    />
                  )}
                />
                <Route
                  path="/season-tickets/:id"
                  component={() => (
                    <SeasonTicket
                      seasonsPassService={props.services.seasonsPassService}
                      venueService={props.services.venueService}
                      preferencesService={props.services.preferencesService}
                      authService={props.services.authService}
                      orderService={props.services.orderService}
                    />
                  )}
                />
                <Route
                  path="/prolong-season-tickets/:id"
                  component={() => (
                    <ProlongSeasonTicket
                      prolongSeasonTicketService={props.services.prolongSeasonTicketService}
                      preferencesService={props.services.preferencesService}
                      authService={props.services.authService}
                    />
                  )}
                />
                <Route
                  path={Path.CART}
                  component={() => (
                    <Cart
                      orderService={props.services.orderService}
                      loyaltyService={props.services.loyaltyService}
                      paymentService={props.services.paymentService}
                      profileService={props.services.profileService}
                      preferencesService={props.services.preferencesService}
                    />
                  )}
                />
                <Route render={() => <Redirect to={{ pathname: Path.SHOP }} />} />
              </Switch>
            </>
          </AppLayout>
        </Route>
      </Switch>
    </>
  );
}

export default AppRoutes;
