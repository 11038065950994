// import { useState, useEffect, useRef } from 'react';
// import { ReactComponent as EyePassword } from 'shared/assets/icons/eye.svg';
// import { classNames } from 'shared/utils';
// import { masked } from '../../utils';
// import styles from './Input.module.scss';

// function Input(props: {
//   title?: string,
//   type?: string,
//   name: string,
//   placeholder?: string,
//   label?: string,
//   value: string,
//   handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
//   onClick?: () => void,
//   textButton?: string,
//   disabledButton?: boolean,
//   pattern?: string,
//   mask?: string,
//   required?: boolean,
//   error?: string,
//   touched?: boolean,
//   maxLength?: number,
//   minLength?: number,
//   withoutLabel?: boolean,
// }) {
//   const ref = useRef<HTMLInputElement | null>(null)
//   const [val, setVal] = useState(props.value);

//   const [maskedOutput, setMaskedOutput] = useState('')
//   const [error, setError] = useState<string>('')
//   const [focus, setFocus] = useState('')
//   const [showPassword, setShowPassword] = useState(false)
//   const active = props.disabledButton ? 'disabled' : 'active'
//   const errorLabel = error ? 'errorLabel' : null
//   // const [startCur, setStartCur] = useState<number>(0)
//   // const [endCur, setEndCur] = useState<number>(0)

//   // useEffect(() => {
//   //   let pointer = ref.current?.selectionStart
//   //   if (pointer) pointer = startCur
//   //   ref.current?.setSelectionRange(startCur, startCur)
//   // }, [props.value])


//   useEffect(() => {
//     if (typeof value === 'string') {
//       props.mask ? setMaskedOutput(masked(props.mask, val)) : setMaskedOutput(val)
//     }
//   }, [val, props.mask])

//   useEffect(() => {
//     if (props.type === 'password') {
//       setShowPassword(true)
//     }
//   }, [props.type])

//   useEffect(() => {
//     if (props.value?.length === 0 && !props.error) {
//       setError('')
//     }
//   }, [props.error, props.value])

//   useEffect(() => {
//     if (props.touched && props.error) {
//       setError(props.error)
//     }
//   }, [props.error, props.value])

//   const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
//     e.preventDefault()
//     setError(props.error || '')
//     setFocus('')
//   }

//   const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
//     e.preventDefault()
//     setError('')
//     setFocus('focus')

//   }

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const input = ref
//     const { value, selectionEnd } = input.current!

//     console.log(value);
//     console.log(selectionEnd);

//     if (props.handleChange) props.handleChange(e)

//     if (selectionEnd) {
//       const rightCharsCount = value.length - selectionEnd;
//       const formattedValue = parseInt(value.replace(/\D/g, ''), 10).toLocaleString();
//       const newPosition = formattedValue.length - rightCharsCount;

//       setVal(formattedValue);

//       setTimeout(() => {
//         ref.current?.setSelectionRange(newPosition, newPosition);
//       }, 0);
//     }

//   };

//   // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//   //   e.preventDefault()
//   //   props.handleChange?.(e)
//   //   const pointer = ref.current?.selectionStart;
//   //   if (pointer) setStartCur(pointer)
//   //   const end = ref.current?.selectionEnd
//   //   if (end) setEndCur(end)
//   //   // ref.current?.setSelectionRange(endCur, endCur)
//   //   console.log('start', startCur)
//   //   console.log('endCur', end)
//   // }

//   return (
//     <div className={styles.Input}>
//       <h4 className={styles.inputTitle}>{props.title}</h4>
//       <div className={classNames(styles.inputWrap, styles[`${focus}`])}>
//         <input
//           ref={ref}
//           name={props.name}
//           type={showPassword ? 'password' : 'text'}
//           value={maskedOutput}
//           pattern={props.pattern}
//           placeholder={props.placeholder}
//           // onChange={props.handleChange}
//           onChange={handleInputChange}
//           onBlur={(e) => handleBlur(e)}
//           onFocus={(e) => handleFocus(e)}
//           maxLength={props.mask ? props.mask.length : props.maxLength || 36}
//           minLength={props.minLength}
//         />
//       {
//        props.type === 'password'
//         && <EyePassword onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)}/>
//       }
//       </div>
//         {!props.withoutLabel &&
//           <div className={styles.labelWrap}>
//             <label htmlFor={props.name} className={styles[`${errorLabel}`]}>
//               {error && error.length > 0 ? error : props.label}
//             </label>
//             <button
//               className={classNames(styles.textButton,styles[`${active}`])}
//               onClick={props.onClick}
//               disabled={props.disabledButton ? true : false}
//               type='button'
//               >
//               <span>{props.textButton}</span>
//             </button>
//           </div>
//         }
//     </div>
//   );
// }

// export default Input;

import { useState, useEffect, useRef } from 'react';
import { ReactComponent as EyePassword } from 'shared/assets/icons/eye.svg';
import { classNames } from 'shared/utils';
import { masked } from '../../utils';
import styles from './Input.module.scss';

export type Autocomplete =
  | 'on'
  | 'off'
  | 'name'
  | 'email'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'one-time-code';

function Input(props: {
  title?: string,
  type?: string,
  name: string,
  placeholder?: string,
  label?: string,
  value: string,
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onClick?: () => void,
  textButton?: string,
  disabledButton?: boolean,
  pattern?: string,
  mask?: string,
  required?: boolean,
  error?: string,
  touched?: boolean,
  maxLength?: number,
  minLength?: number,
  withoutLabel?: boolean,
  autoComplete?: Autocomplete
  autoFocus?: boolean
}) {
  const ref = useRef<HTMLInputElement | null>(null)
  const [val, setVal] = useState(props.value);
  const [maskedOutput, setMaskedOutput] = useState('')
  const [error, setError] = useState<string>('')
  const [focus, setFocus] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const active = props.disabledButton ? 'disabled' : 'active'
  const errorLabel = error ? 'errorLabel' : null

  useEffect(() => {
    if (typeof val === 'string') {
      props.mask ? setMaskedOutput(masked(props.mask, val)) : setMaskedOutput(val)
    }
  }, [val, props.mask, setMaskedOutput, props.value])

  useEffect(() => {
    if (props.type === 'password') {
      setShowPassword(true)
    }
  }, [props.type])

  useEffect(() => {
    if (props.value?.length === 0 && !props.error) {
      setError('')
    }
  }, [props.error, props.value])

  useEffect(() => {
    if (props.touched && props.error) {
      setError(props.error)
    }
  }, [props.error, props.value])

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setError(props.error || '')
    setFocus('')
  }

  const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setError('')
    setFocus('focus')
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = ref
    const { value, selectionEnd } = input.current!

    if (props.handleChange) props.handleChange(e)

    if (selectionEnd !== null) {
      const rightCharsCount = value.length - selectionEnd
      const newPosition = masked(props.mask, value).length - rightCharsCount
      const setCurrentSelectionRange = () => props.mask
        ? setTimeout(() => { ref.current?.setSelectionRange(newPosition, newPosition)}, 0)
        : setTimeout(() => { ref.current?.setSelectionRange(selectionEnd, selectionEnd)}, 0)

      setVal(value)
      setCurrentSelectionRange()
    } else {
      setVal('')
    }
  }

  return (
    <div className={styles.Input}>
      <h4 className={styles.inputTitle}>{props.title}</h4>
      <div className={classNames(styles.inputWrap, styles[`${focus}`])}>
        <input
          ref={ref}
          autoComplete={props.autoComplete}
          name={props.name}
          type={showPassword ? 'password' : 'text'}
          value={maskedOutput}
          pattern={props.pattern}
          placeholder={props.placeholder}
          onChange={handleInputChange}
          onBlur={(e) => handleBlur(e)}
          onFocus={(e) => handleFocus(e)}
          maxLength={props.mask ? props.mask.length : props.maxLength || 36}
          minLength={props.minLength}
          autoFocus={props.autoFocus}
        />
      {
       props.type === 'password'
        && <EyePassword onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)}/>
      }
      </div>
        {!props.withoutLabel &&
          <div className={styles.labelWrap}>
            <label htmlFor={props.name} className={styles[`${errorLabel}`]}>
              {error && error.length > 0 ? error : props.label}
            </label>
            {
              props.textButton &&
                <button
                  id='InputButton'
                  className={classNames(styles.textButton,styles[`${active}`])}
                  onClick={props.onClick}
                  disabled={props.disabledButton ? true : false}
                  type='button'
                  >
                  <span>{props.textButton}</span>
                </button>
            }
          </div>
        }
    </div>
  );
}

export default Input;
