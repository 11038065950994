import {Skeleton} from 'shared/ui';
import styles from './SeasonPass.module.scss';

function SkeletonSeasonPass() {
    return (
        <div className={styles.seasonPassContainer}>
            <div className={styles.seasonPassInfo}>
                <div className={styles.seasonPassLogo}>
                    <Skeleton type="avatar" style={{width: '70px', height: '70px'}}/>
                </div>
                <Skeleton type="span" style={{width: '100px'}}/>
                <Skeleton type="span" style={{width: '200px'}}/>
            </div>
        </div>
    )
}

export default SkeletonSeasonPass