import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ErrorScreen from 'pages/ErrorScreen/ErrorScreen';
import { PreferencesService } from 'processes/Preferences';
import { LoyaltyDescriptor, LoyaltyService } from 'entities/Loyalty';
import { Button, Loader, Logo, ProgressStepBar } from 'shared/ui';
import { useWindowSize } from 'shared/utils';
import { useOnboardingMedia, useSlides } from './ui';
import { useTranslation } from 'react-i18next';

import styles from './Onboarding.module.scss';

export default function Onboarding(props: {
  loyaltyService: LoyaltyService;
  preferences: PreferencesService;
}) {
  const [slide, setSlide] = useState(0);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { width } = useWindowSize();
  const [loyaltyDescriptor, setLoyaltyDescriptor] = useState<LoyaltyDescriptor>();
  const slides = useSlides(loyaltyDescriptor);
  const media = useOnboardingMedia(props.preferences, loyaltyDescriptor?.isActive);
  const isMobile = width < 880;
  const { t } = useTranslation();

  useEffect(() => {
    (async function fetch() {
      try {
        setLoading(true);
        const descriptor = await props.loyaltyService.getLoyaltyDescriptor();
        setLoyaltyDescriptor(descriptor);
      } catch (e: any) {
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [props.loyaltyService]);

  const handleNext = () => {
    if (slide < slides.length - 1) {
      setSlide(prev => prev + 1);
    } else {
      history.push('/');
    }
  };

  if (error) return <ErrorScreen />;
  if (loading) return <Loader />;

  return (
    <div className={styles.onboarding}>
      <div className={styles.main}>
        {
          isMobile ? null :
          <header><Logo /></header>
        }
        <main className={styles.content}>
          {slides[slide]}
          <nav className={styles.nav}>
            <Button id='OnboardingFurther' onClick={handleNext}>{t('onboarding.button') ?? "Далее"}</Button>
            <ProgressStepBar steps={slides.length} currentStep={slide} setCurrentStep={setSlide}/>
          </nav>
        </main>
        <footer className={styles.footer}>
          <Link to="/">{t('onboarding.link') ?? "Пропустить"}</Link>
        </footer>
      </div>
      <aside className={styles.aside}>{media[slide]}</aside>
    </div>
  );
}
