import { SkeletonPaymentPointsCard } from 'features/PaymentPointsCard';
import { SkeletonPromocodeInput } from 'features/PromocodeInput';
import SkeletonTotalOrderDetails from '../TotalOrderDetails/SkeletonTotalOrderDetails';
import { Button, Skeleton } from 'shared/ui';
import styles from './OrderDetails.module.scss';

function SkeletonOrderDetails() {
  return (
    <aside className={styles.orderDetails}>
      <h3><Skeleton type="span" style={{ width: '170px'}}/></h3>
        <SkeletonTotalOrderDetails />
        <SkeletonPromocodeInput />
        <SkeletonPaymentPointsCard />
      <Button id='SkeletonOrderDetails' style={{margin: '4px 0'}} onClick={() => console.log('перешёл к оплате')}><Skeleton type="span" /></Button>
    </aside>
  )
}

export default SkeletonOrderDetails
