import { gql } from '@apollo/client';

export const ORDER_FRAGMENT_FIELDS = gql`
  fragment OrderFields on Order {
    id
    appliedPromocode
    price
    priceWithDiscount
    additionalData {
      loyaltyAmount
    }
    items {
      id
      type
      title
      price
      priceWithDiscount
      loyaltyUsed
      reservedUntil
      item{
        ... on SeasonTicket {
          id
          descriptor {
            id
            title
            seasons {
              id
              endDate
            }
          }
          place {
            id
            number
            coordinates {
              x
              y
            }
            row {
              number
              sector {
                title
              }
            }
          }
          price
        }
        ... on Ticket {
          visibleId
          place {
            id
            number
            coordinates {
              x
              y
            }
            row {
              number
              sector {
                title
              }
            }
          }
          price
          event {
            id
            startTime
            team1 {
              title
            }
            team2 {
              title
            }
            stage {
              tournament {
                logo {
                  publicLink
                }
              }
            }
          }
        }
      }
    }
  }
`;
