import styles from './MultistepPrevButton.module.scss';
import { ReactComponent as IconLeft } from 'shared/assets/icons/left_arrow.svg';
import { useWindowSize } from 'shared/utils';
import { useTranslation } from 'react-i18next';

function MultistepPrevButton(props: { title?: string; onClick?: () => void }) {
  const { width } = useWindowSize()
  const { t } = useTranslation()

  if (props.title) {
    return (
      <button id='MultistepPrevButtonTitleButton' className={styles.headerBtn} onClick={props.onClick}>
        <span>{props.title}</span>
      </button>
    );
  }

  return (
    <button id='MultistepPrevButton' className={styles.backBtn} onClick={props.onClick}>
      <IconLeft width={20} className={styles.back__icon} />
      {width > 720 && <span>{t('common.back')}</span>}
    </button>
  );
}

export default MultistepPrevButton
