import styles from './SeparatedList.module.scss';

function SeparatedList(props: {
  children: JSX.Element[] | JSX.Element,
}) {
  return (
    <div className={styles.separatedList}>
      {props.children}
    </div>
  )
}

export default SeparatedList