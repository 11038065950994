import { useState, useCallback } from 'react';
import { IErrors, IFields } from './validate';

export function useForm<T extends Record<keyof IFields, any>>(initialState: Partial<T>, validate?: (data: IFields) => IErrors) {
  const [formData, setFormData] = useState<T>((initialState) as T)
  const [errors, setErrors] = useState<IErrors>({})
  
  const setDataAndErrors = useCallback((data) => {
    setFormData(data)

    if (validate) {
      const errors = validate(data);
      setErrors(errors)
    }
  }, [validate])

  
  const changeHandler = useCallback((e) => {
    let updatedData;

    if(e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
      updatedData = {
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          checked: e.target.checked,
          touched: true
        }
      }
    }else if(e.target.tagName === 'INPUT' && e.target.type === 'file') {
      updatedData = {
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          value: e.target.files,
          touched: true
        }
      }
    }else {
      updatedData = {
        ...formData,
        [e.target.name]: {
          ...formData[e.target.name],
          value: e.target.value,
          touched: true
        }
      }
    }
    setDataAndErrors(updatedData);

  }, [setDataAndErrors, formData])
  
  return {
    formData, errors, changeHandler, setErrors, setFormData
  }
}
