import { ReactComponent as IconLeft } from 'shared/assets/icons/left_arrow.svg';
import styles from './BackButton.module.scss';

function BackButton(props: {id: string; title: string; onClick: () => void }) {
  return (
    <button id={`${props.id}BackButton`} className={styles.backBtn} onClick={props.onClick}>
      <IconLeft className={styles.back__icon} />
      <span>{props.title}</span>
    </button>
  );
}

export default BackButton
