import styles from './CustomCheckbox.module.scss';
import { ReactChild } from 'react';

function CustomCheckbox(props: {
  name: string,
  value?: string,
  label?: ReactChild,
  checked?: boolean,
  onClick?: () => void,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void, }) {

  return (
    <div className={styles.customCheckbox}>
      <input
        className={styles.inputCheckbox}
        type='checkbox'
        id={props.name}
        name={props.name}
        value={props.value}
        checked={props.checked}
        onClick={props.onClick}
        onChange={props.onChange}
      >
      </input>
      <label htmlFor={props.name}>{props.label}</label>
  </div>
  )
}

export default CustomCheckbox