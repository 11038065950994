import {OrderService} from 'entities/Order';
import {ProfileService} from 'entities/Profile';
import {Navigation} from 'features/Navigation';
import {AuthService} from 'processes/Auth';
import {PreferenceKeys, PreferencesService} from 'processes/Preferences';
import {Link} from 'react-router-dom';
import {Path} from 'routes/path';
import Logo from 'shared/ui/Logo/Logo';
import {classNames} from 'shared/utils';
import {useTranslation} from 'react-i18next';
import {getSupportedLngs, switchLng} from 'i18n';

import styles from './Header.module.scss';
import {useState} from "react";

function Header(props: {
  authService: AuthService;
  profileService: ProfileService;
  orderService: OrderService;
  preferencesService: PreferencesService;
}) {
  const {t, i18n} = useTranslation();
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const currentClubName = props.preferencesService.get(PreferenceKeys.CLUB) || 'fanzilla';

  const supportedLangs = getSupportedLngs();
  const renderLangSwitcher = () => {
    if (supportedLangs.length > 1) {
      return supportedLangs.map((lang) => (
        <span
          className={classNames(styles.langCode, i18n.language === lang ? styles.activeLangCode : '')}
          onClick={() => switchLng(lang)}
          key={`header-lang-switcher-${lang}`}
        >
          {lang.toUpperCase()}
        </span>
      ))
    } else return null;
  }

  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <Link aria-label={t('header.main') ?? 'Главная'} to={Path.SHOP}>
          <Logo
            src={props.preferencesService.get(PreferenceKeys.LOGO_SRC)}
            classes={styles[`logoSize-${currentClubName}`]}
          />
        </Link>
        <Link aria-label={t('header.main') ?? 'Главная'} to={Path.SHOP} style={{textDecoration: 'none'}}>
          {props.preferencesService.getNullable(PreferenceKeys.ADDITIONAL_LOGO_SRC) ? <Logo
            src={props.preferencesService.get(PreferenceKeys.ADDITIONAL_LOGO_SRC)}
            classes={`${isMobileOpen ? styles.additionalLogoAlt : styles.additionalLogo} ${styles[`additionalLogo-${currentClubName}`]}`}
          /> : <span
            className={classNames(isMobileOpen ? styles.additionalLogoTextAlt : styles.additionalLogo, styles[`additionalLogo-${currentClubName}`], styles.additionalLogoText)}
            >
              {props.preferencesService.get(PreferenceKeys.ADDITIONAL_LOGO_TEXT)}
              </span>
        }
        </Link>
        <div className={styles.langsContainer}>
          {renderLangSwitcher()}
        </div>
      </div>
      <Navigation
        orderService={props.orderService}
        authService={props.authService}
        profileService={props.profileService}
        preferencesService={props.preferencesService}
        onMobileMenuOpen={(isMenuOpen) => setIsMobileOpen(isMenuOpen)}
      />
    </header>
  );
}

export default Header;
