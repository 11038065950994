
import { Skeleton } from 'shared/ui';
import { classNames } from 'shared/utils';
import styles from './TotalOrderDetails.module.scss';

function SkeletonTotalOrderDetails() {
  return (
    <div className={styles.paymentDetails}>
      <div className={styles.details}>
        <span className={styles.paymentsName}><Skeleton type="span" style={{ width: '120px'}}/></span>
        <span className={styles.value}><Skeleton type="span" style={{ width: '70px'}}/></span>
      </div>
      <div className={styles.details}>
        <span className={styles.paymentsName}><Skeleton type="span" style={{ width: '120px'}}/></span>
        <span className={styles.value}><span><Skeleton type="span" style={{ width: '70px'}}/></span></span>
      </div>
      <div className={classNames(styles.details, styles.sumTicketDetails)}>
        <h6 className={styles.paymentsName}><Skeleton type="span" style={{ width: '90px'}}/></h6>
        <h6 className={styles.value}><Skeleton type="span" style={{ width: '70px'}}/></h6>
      </div>
    </div>
  )
}

export default SkeletonTotalOrderDetails