import { Skeleton } from 'shared/ui';
import styles from './PromocodeInput.module.scss';

function SkeletonPromocodeInput() {
  return (
    <>
      <form onSubmit={e => e.preventDefault} className={styles.wrap} style={{margin: '6px 0'}}>
        <Skeleton type="span" style={{ width: '100%'}}/>
      </form>
    </>
  )
}

export default SkeletonPromocodeInput