
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './MobileAppMap.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import { KeyValueObject, Map, PlacePrice, PlacePriceSubscription, SeasonTicketSubscription, VenueService } from 'entities/Venue/model';
import { Loader } from 'shared/ui';
import MapComponent from 'features/Map/ui/MapComponent/MapComponent';
import {useRefFromState} from 'shared/utils';
import {PreferenceKeys, PreferencesService} from "processes/Preferences";
import { defineSubscriptionConfig } from 'entities/Venue/utils/defineSubscriptionConfig';
import { usePlacesStatusSubscription } from 'entities/Venue/hooks';
import { ItemType } from 'entities/Order';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MobileAppMap(props: { venueService: VenueService, preferencesService: PreferencesService }): JSX.Element {
  const {id} = useParams<{ id: string }>();

  const queryParams = useQuery();
  const eventType = (queryParams.get('type') === 'match') ? ItemType.TICKET : ItemType.SEASON_TICKET;
  const maxSelection = Number.parseInt(queryParams.get('maxSelection') || '5');

  const [map, setMap] = useState<Map>();
  const places = useRef<PlacePrice[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const placesIdByIndex = useRef<KeyValueObject>({});

  const [selectedPlaces, setSelectedPlaces] = useState<PlacePrice[]>([]);
  const selectedPlacesRef = useRefFromState(selectedPlaces);
  const [updatedPlace, setUpdatedPlace] = useState<PlacePrice>();
  const [placePriceState, setPlacePriceState] = useState<PlacePrice[]>([]);
  const initStateIds = useRef<string[]>([]);

  const mapLoader = (eventType === ItemType.TICKET)
    ? {
        getPlaces: async () => await props.venueService.getMatchPlaces(id),
        getMap: async () => await props.venueService.getMapMatch(id),
        subscribeToEventPlacesStatus: (subscriptionConfig: PlacePriceSubscription) => props.venueService.eventPlacesStatusSubscription(subscriptionConfig),
      }
    : {
        getPlaces: async () => await props.venueService.getSeasonTicketPlaces(id),
        getMap: async () => await props.venueService.getMapSeasonTicket(id),
        subscribeToSeasonTicketPlacesStatus: (subscriptionConfig: SeasonTicketSubscription) => props.venueService.seasonTicketPlacesStatusSubscription(subscriptionConfig),
      }

  const loadActualPlaceStatuses = useCallback(async () => {
    const newPlaces = await mapLoader.getPlaces();
    newPlaces.forEach((item, index) => {
      placesIdByIndex.current[item.place.id] = index;
    })
    places.current = newPlaces;

    await setSelectedPlacesFromApp();
  }, [mapLoader]);

  const setSelectedPlacesFromApp = async () => {
    const getJson = async () => await (window as any).MapEvent.initState();
    const jsonRes = await getJson();
    if ((window as any).MapEvent && !!jsonRes) {
      const initStateInfo = JSON.parse(jsonRes);
      initStateIds.current = [];
      initStateInfo.forEach((item: any) => {
        initStateIds.current.push(item.data.id)
      });

      const selectedPlacesIndexes = initStateIds.current.map((id) => {
        return placesIdByIndex.current[id]
      })

      const selectedPlacesFromInitState = selectedPlacesIndexes.map((index) => {
        return places.current[index];
      })
      if (selectedPlacesFromInitState) {
        setSelectedPlaces(selectedPlacesFromInitState || [])
      }
    } else {
      setLoaded(true);
    }
  };


  const syncBasketStateEvent = async () => {
    await setSelectedPlacesFromApp();
  }

  useEffect(() => {
    (async () => {
      try {
        const map = await mapLoader.getMap();
        setMap(map);
        await loadActualPlaceStatuses();
        (window as any).syncBasketStateEvent = new Event('mobilesync');
        window.addEventListener('mobilesync', syncBasketStateEvent, false);
      } catch (e) {
        console.log(e);
      }
    })();
    return () => {
      window.removeEventListener('mobilesync', syncBasketStateEvent, false);
    }
  }, []);

  useEffect(() => {
    setPlacePriceState(places.current);
    setLoaded(places.current.length > 0);
  }, [places.current]);


  const handlePlacePriceChange = (pp: PlacePrice) => {
    setUpdatedPlace(pp);
    console.log(`Статус места, ${pp.place.number}, в ряду ${pp.place.rowNumber}, сектора "${pp.place.sectorTitle}" изменился на ${pp.status}`); // TODO: delete when subscription completed
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const updatePlaces = (pp: PlacePrice) => {
    places.current = places.current.map(item => {
      if (item.place.id === pp.place.id) {
        item = pp;
      }
      return item;
    })
    setPlacePriceState(places.current);
  }

  const {subscriptionType: sType, subscriptionConfig: sConfig} = defineSubscriptionConfig(eventType, id, { handlePlacePriceChange, handleError });
  const placeStatusChanges = usePlacesStatusSubscription(sType, sConfig, mapLoader);

  useEffect(() => {
    const placesSubscription = placeStatusChanges();
    setLoaded(false);

    return () => {
      placesSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (updatedPlace !== undefined) {
      updatePlaces(updatedPlace)
    }
  }, [updatedPlace])

  return !loaded ? (
    <Loader />
  ) : (
    <div className={styles.mapContainer}>
      <MapComponent
        placesList={placePriceState}
        containerId={styles.mapmobile}
        mapSize={map?.size || {height: 0, width: 0}}
        mapUrl={map?.src || ''}
        placesIdByIndex={placesIdByIndex.current}
        maxZoom={Number(props.preferencesService.get(PreferenceKeys.MAP_MAX_ZOOM))}
        placesRadius={Number(props.preferencesService.get(PreferenceKeys.MAP_PLACE_RADIUS))}
        placesWeight={Number(props.preferencesService.get(PreferenceKeys.MAP_PLACE_WEIGHT))}
        onSelect={async obj => {
          if (selectedPlacesRef.current.length < maxSelection) {
            setSelectedPlaces(oldSelectedPlaces => [...oldSelectedPlaces, obj]);
            if ((window as any).MapEvent) {
              (window as any).MapEvent.postMessage(
                JSON.stringify({
                  type: 'PLACE_SELECT',
                  data: {
                    id: obj.place.id,
                    number: obj.place.number,
                    row: obj.place.rowNumber,
                    sector: obj.place.sectorTitle,
                    price: obj.price.toString(),
                  },
                })
              );
            }
          }
        }}
        onRemove={it => {
          setSelectedPlaces(oldSelectedPlaces => oldSelectedPlaces.filter(old => old.place.id !== it.place.id));
          if ((window as any).MapEvent) {
            (window as any).MapEvent.postMessage(
              JSON.stringify({
                type: 'PLACE_REMOVE',
                data: {
                  id: it.place.id,
                },
              })
            );
          }
        }}
        selectedPlaces={selectedPlaces}
      />
    </div>
  );
}

export default MobileAppMap;
