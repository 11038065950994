import { Services } from 'shared/model/types';
import initApolloClient from './ServicesConfiguration/ApolloClientConfiguration';
import initAuthService from './ServicesConfiguration/AuthServiceConfiguration';
import initCredentialStorage from './ServicesConfiguration/CredentialStorageConfiguration';
import initLoyaltyService from './ServicesConfiguration/LoyaltyServiceConfiguration';
import initMatchesService from './ServicesConfiguration/MatchesServiceConfiguration';
import initPreferencesService from './ServicesConfiguration/PreferencesServiceConfiguration';
import initProfileService from './ServicesConfiguration/ProfileServiceConfiguration';
import initProlongSeasonTicketService from './ServicesConfiguration/ProlongSeasonTicketServiceConfiguration';
import initSeasonsPassService from './ServicesConfiguration/SeasonsPassServiceConfiguration';
import initPaymentService from './ServicesConfiguration/PaymentServiceConfiguration';
import initOrderService from './ServicesConfiguration/OrderServiceConfiguration';
import initVenueService from './ServicesConfiguration/VenueServiceConfiguration';
import { WarningModalServiceImpl } from 'features/ConfigurableWarningModal/model/WarningModalServiceImpl';
import { initDateTime } from 'app/init/initServices/ServicesConfiguration/DateTimeConfiguration';

export async function initServices(): Promise<Services> {
  const preferencesService = initPreferencesService();
  const credentialStorage = initCredentialStorage(preferencesService);
  const authService = await initAuthService(preferencesService, credentialStorage);
  const apolloClient = initApolloClient(preferencesService, authService);
  const loyaltyService = initLoyaltyService(apolloClient);
  const matchesService = initMatchesService(apolloClient);
  const seasonsPassService = initSeasonsPassService(apolloClient);
  const profileService = initProfileService(apolloClient);
  const prolongSeasonTicketService = initProlongSeasonTicketService(apolloClient, authService);
  const paymentService = await initPaymentService(preferencesService, authService)
  const orderService = initOrderService(apolloClient, authService);
  const venueService = initVenueService(apolloClient, preferencesService);
  const warningModalService = new WarningModalServiceImpl(preferencesService);
  initDateTime(preferencesService)

  return {
    preferencesService,
    authService,
    loyaltyService,
    apolloClient,
    matchesService,
    seasonsPassService,
    profileService,
    prolongSeasonTicketService,
    orderService,
    paymentService,
    venueService,
    warningModalService,
  };
}
