import {
  RemoteFile,
  EventStatus,
  Team,
  ISOstring,
  ID,
  Pagination,
  EventVisibilityPlace,
  PeriodInput,
  PaginatedResult,
} from 'shared/model';
import { DateTime } from 'luxon'
import { LocalizedString } from 'shared/utils';

export type Tournament = {
  title: LocalizedString;
  description?: LocalizedString;
  logo?: RemoteFile;
};

export type Stage = {
  id: string;
  title: LocalizedString;
  tournament: Tournament;
};

export type RemoteVenue = {
  id: ID;
  title: LocalizedString;
  description?: LocalizedString;
  location?: LocalizedString;
  timezone?: string;
};

export type Match = {
  id: ID;
  title: LocalizedString;
  description?: LocalizedString;
  startTime: DateTime;
  endTime?: DateTime;
  status: EventStatus;
  cover?: RemoteFile;
  team1: Team;
  team2: Team;
  stage: Stage;
  venue: RemoteVenue;
};

enum MatchOrderBy {
  datetimeFromASC,
  datetimeFromDESC,
  seasonASC,
  seasonDESC,
}

export type MatchesFilterInput = Partial<{
  datetimeFrom: ISOstring;
  datetimeTo: ISOstring;
  title: String;
  statuses: EventStatus[];
  venueIds: ID[];
  teamIds: ID[];
  stageIds: ID[];
  seasonIds: ID[];
  matchIds: ID[];
  orderBy: MatchOrderBy;
  hidden: Boolean;
  pagination: Pagination;
  visibilityPlaces: EventVisibilityPlace[];
  updatePeriod: PeriodInput;
}>;

export interface MatchesService {
  getAvailable(): Promise<PaginatedResult<Match>>;
  getById(id: string): Promise<Match>;
}
