import { useState, useEffect } from 'react'

export function useServiceData<T>(getter: () => Promise<T>, dependencies: any[]) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getter();
        setData(res)
      } catch (e: any) {
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  return { data, loading, error };
}