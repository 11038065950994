import { ObjectDeserializer } from '@sebbia/object-deserializer';
import { Place, SeasonTicket } from './types';


export function placeDeserializer(o: ObjectDeserializer): Place {
  return {
    id: o.required('id').asString,
    number: o.required('number').asString,
    row: o.required('row').asObject(r => {
      return {
        number: r.required('number').asString,
        sector: r.required('sector').asObject(s => {
          return {
            title: s.required('title').asString
          }
        })
      }
    })
  };
}

export function seasonTicketDeserializer(st: ObjectDeserializer): SeasonTicket {
  return {
    id: st.required('id').asString,
    visibleId: st.required('visibleId').asString,
    place: st.required('place').asObject(placeDeserializer),
    descriptor: st.required('descriptor').asObject(d => {
      return {
        id: d.required('id').asString,
        title: d.required('title').asString
      }
    })
  }
}
