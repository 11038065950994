import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { VenueService, VenueServiceImpl } from "entities/Venue";
import { PreferencesService } from "processes/Preferences";


function initVenueService(client: ApolloClient<NormalizedCacheObject>, preferencesService: PreferencesService): VenueService {
  return new VenueServiceImpl(client, preferencesService);
}

export default initVenueService;
