
import Skeleton from 'shared/ui/Skeleton/Skeleton';
import styles from './WarnBanner.module.scss';

function SkeletonWarnBanner() {
  return (
    <div className={styles.warnBanner}>
      <div className={styles.warnText} style={{flexDirection: 'column'}}>
        <Skeleton type='line' style={{minWidth: '300px'}}/>
        <Skeleton type='line' style={{marginTop: '10px', minWidth: '300px'}}/>
      </div>
      <div className={styles.warnButton}>
        <div className={styles.warnButtonText}>
          <Skeleton type='span' />
        </div>
      </div>
    </div>
  );
};

export default SkeletonWarnBanner;
