import { AuthService } from 'processes/Auth';
import { PaymentService, PaymentServiceImpl } from 'processes/Payment';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';

async function initPaymentService(
  preferences: PreferencesService,
  auth: AuthService
  ): Promise<PaymentService> {

  if (preferences.get(PreferenceKeys.PAYMENT_LIB_LINK)) {
    const script = document.createElement('script')
    script.async = true
    script.src = preferences.get(PreferenceKeys.PAYMENT_LIB_LINK)
    document.body.appendChild(script)
  }

  const paymentService = new PaymentServiceImpl(preferences, auth)
  
  return paymentService
}

export default initPaymentService;
