import {
  ID,
  PaginatedResult,
  PeriodInput,
} from 'shared/model/types';


export type SeasonTicketDescriptor = {
  id: ID;
  title: string;
  status: SeasonTicketDescriptorStatus;
  description?: string;
}

export enum SeasonTicketDescriptorStatus {
  ACTIVE,
  PROLONG_ONLY,
  NOT_ACTIVE,
  }

export type SeasonTicketDescriptorFilter = Partial<{
  seasonIds: ID[];
  tournamentIds: ID[];
  stageIds: ID[];
  statuses: SeasonTicketDescriptorStatus[];
  updateDatetime?: PeriodInput;
  hidden?: Boolean;
}>;

export interface SeasonsPassService {
  getList(filter?: SeasonTicketDescriptorFilter): Promise<PaginatedResult<SeasonTicketDescriptor>>;
  getById(id: string): Promise<SeasonTicketDescriptor>;
}
