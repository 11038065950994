/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';
import { Multistep, MultistepPrevButton, Steps, useMovementWithinMultistep } from 'shared/ui';
import { recoveryInitialState } from './utils/initialState';
import { formatPhone, FormWithReCaptcha, LoginWithRePassword, ReCaptcha, useFetchFormData, useForm } from '../shared';
import { getErrorMessage, useIsMounted } from 'shared/utils';
import validate from '../shared/utils/validate';
import { ModalHeaderWithTitle } from 'shared/ui/Modal/ModalHeader/ModalHeaderWithTitle';

function RecoveryMultistepForm(props: {
  restorePassword: (phone: string, token?: string) => Promise<number>,
  login: (phone: string, password: string) => Promise<void>,
  preferencesService: PreferencesService,
  openAuthorization?: () => void,
  phone?: string,
  setPhone?: (phone: string) => void,
  onFinish?: () => void,
}) {
  const [token, setToken] = useState<string>()
  const { selectedIndex, goNext, goPrevious } = useMovementWithinMultistep()
  const { formData, errors, changeHandler, setErrors } = useForm(recoveryInitialState, validate)
  const isMounted = useIsMounted()
  const { t } = useTranslation();

  const phone = formatPhone(formData.phone.value)
  const password = formData.password.value

  const { data: nextRestoreAfterMsecs, submited: sendPassSubmit, loading: sendPassLoad, fetch: sendPassword } = useFetchFormData(
    () => props.restorePassword(phone, token), 
    () => goNext(),
    (error: string) => setErrors({phone: getErrorMessage(error)})
  )

  const { data: nextRestoreTime, submited: rePassSubmit, loading: rePassLoad, fetch: recoveryPassword } = useFetchFormData( 
    () => props.restorePassword(phone), 
    () => {},
    (error: string) => setErrors({phone: getErrorMessage(error)})
  )

  const { submited: loginSubmit, loading: loginLoad, fetch: login} = useFetchFormData(
    () => props.login(phone, password), 
    () => props.onFinish?.(),
    (error: string) => setErrors({password: getErrorMessage(error)})
  )
  
  useEffect(() => {
    if (!props.phone) return
    formData.phone.value = props.phone
  }, [props.phone])

  useEffect(() => {
    if (isMounted()) {
      props.setPhone?.(formData.phone?.value)
    } 
  }, [props.setPhone, formData.phone.value])

  useEffect(() => {
    const requiredErrors = validate(formData, true)
    setErrors(requiredErrors)
  }, [selectedIndex])

  const recaptchaKey = props.preferencesService.getNullable(PreferenceKeys.RECAPTCHA_KEY)
  
  const recoverySteps: Steps[] = [
    {
      title: t('multistep_form.recovery.title'),
      closeable: true,
      headerBtn: <MultistepPrevButton onClick={() => props.openAuthorization?.()} />,
      component: <FormWithReCaptcha
        onSubmit={() => sendPassword()}
        onChange={(e) => changeHandler(e)}
        data={formData}
        errors={errors}
        loading={sendPassLoad}
        touched={sendPassSubmit}
        btnChildren={t('multistep_form.send_sms_code') ?? "Отправить SMS-код"}
        reCAPTCHA={
          recaptchaKey ? <ReCaptcha 
            size='invisible'
            sitekey={recaptchaKey}
            onVerify={setToken}
          /> : undefined
        }
      />
    },
    {
      title: t('multistep_form.recovery.title'),
      closeable: true,
      headerBtn: <MultistepPrevButton onClick={goPrevious}/>,
      component: <LoginWithRePassword
        onChange={(e) => changeHandler(e)}
        onSubmit={() => login()}
        data={formData}
        timer={nextRestoreTime! || nextRestoreAfterMsecs!}
        recoveryPassword={() => recoveryPassword()}
        errors={errors}
        loading={(rePassLoad || loginLoad)}
        touched={(rePassSubmit || loginSubmit)}
        btnChildren={t('multistep_form.come_in') ?? "Войти"}
      />
    },
  ]

  return (
    <>
      <ModalHeaderWithTitle
        title={recoverySteps[selectedIndex]?.title}
        headerButton={recoverySteps[selectedIndex]?.headerBtn}
        closeModal={() => props.onFinish?.()}
      />
      <Multistep steps={recoverySteps} selectedIndex={selectedIndex}/>
    </>
  )
}

export default RecoveryMultistepForm
