import { ObjectDeserializer } from '@sebbia/object-deserializer';
import { moneyMapper } from 'shared/model';
import { LoyaltyDescriptor, LTY_TransactionQuery, Tickets_LoyaltyApplicabilityError, Tickets_LoyaltyOrderCheckResult } from './types';

export function loyaltyDescriptorDeserializer(o: ObjectDeserializer): LoyaltyDescriptor {
  return {
    id: o.required('id').asString,
    bonusActivePeriodDays: o.optional('bonusActivePeriodDays')?.asNumber,
    bonusPriceCoverPercentage: o.required('bonusPriceCoverPercentage').asNumber,
    maxExpenditurePerTransactionPercentage: o.required('maxExpenditurePerTransactionPercentage')
      .asNumber,
    applicableWithDiscount: o.required('applicableWithDiscount').asBool,
    legalityDocumentationLink: o.optional('legalityDocumentationLink')?.asString,
    isActive: o.required('isActive').asBool,
    accrualPercentage: o.required('accrualPercentage').asNumber,
    loyaltyCurrencyName: o.required('loyaltyCurrencyName').asObject(o => ({
      plural: o.required('plural').asString,
      mod1: o.required('mod1').asString,
      mod24: o.required('mod24').asString,
      mod50: o.required('mod50').asString,
    })),
  };
}

export function loyaltyOrderDeserializer(o: ObjectDeserializer): Tickets_LoyaltyOrderCheckResult {
  return {
    isApplicable: o.required('isApplicable').asBool,
    applicabilityError: o.optional('applicabilityError')?.asEnum(Tickets_LoyaltyApplicabilityError),
    loyaltyAccrued: o.required('loyaltyAccrued').as(moneyMapper),
    maxPossibleExpenditure: o.required('maxPossibleExpenditure').as(moneyMapper),
    loyaltySpent: o.required('loyaltySpent').as(moneyMapper),
  }
}

export function loyaltyTransactionDeserializer(o: ObjectDeserializer): LTY_TransactionQuery {
  return {
    getUserBonusCount: o.required('getUserBonusCount').as(moneyMapper)
  }
}