import { useTranslation } from 'react-i18next';
import styles from './WarnBanner.module.scss';
import { ReactComponent as IconRight } from 'shared/assets/icons/right_arrow.svg';

function WarnBanner(props: { text: string; buttonText?: string; onClick?: () => void }) {
  const { t } = useTranslation();
  
  return (
    <div
      className={styles.warnBanner}
      style={{
        cursor: props.onClick ? 'pointer' : 'default',
      }}
      onClick={props.onClick}
    >
      <div className={styles.warnText}>
        <h3>{props.text}</h3>
      </div>
      {props.onClick && (
        <div className={styles.warnButton}>
          <div className={styles.warnButtonText}>{props.buttonText ?? t('common.more')}</div>
          <div className={styles.warnButtonIcon}>
            <IconRight />
          </div>
        </div>
      )}
    </div>
  );
}

export default WarnBanner;
