import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, useMovementWithinMultistep } from 'shared/ui';
import { IErrors, IFields } from '../../utils';
import ReCaptchaPolicies from '../ReCaptchaPolicies/ReCaptchaPolicies';

function FormWithReCaptcha(props: {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onSubmit?: () => void,
  data: IFields,
  errors: IErrors,
  touched?: boolean,
  loading?: boolean,
  reCAPTCHA?: JSX.Element,
  policyLink?: string,
  btnChildren: JSX.Element | string,
}) {
  const { errors, data, onChange } = props
  const { forbiddenNextStep } = useMovementWithinMultistep(errors.phone?.length > 0)
  const { t } = useTranslation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (props.onSubmit) props.onSubmit()
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Input
          name='phone'
          title={t('multistep_form.your_phone_number') ?? "Номер вашего телефона"}
          value={data.phone?.value}
          placeholder='+7 000 000-00-00'
          mask='+7 000 000-00-00'
          handleChange={onChange}
          error={errors?.phone}
          touched={props.touched}
        />
        {props.reCAPTCHA}
        {props.policyLink && <p>{t('multistep_form.agreement_text.0')}<a href={props.policyLink} target='_blank' rel="noreferrer"> {t('multistep_form.agreement_text.1')}</a></p>}
        <Button id='handleSubmit' type="submit" loading={props.loading} disabled={forbiddenNextStep}>{props.btnChildren}</Button>
        {props.reCAPTCHA && <ReCaptchaPolicies />}
      </form>
    </>
  )
}

export default FormWithReCaptcha
