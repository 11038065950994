import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, useMovementWithinMultistep } from 'shared/ui';
import { IErrors, IFields, useCountdownSeconds } from '../../utils';

import styles from './LoginWithRePassword.module.scss';

function LoginWithRePassword(props: {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onSubmit?: () => void,
  data: IFields,
  errors: IErrors,
  loading?: boolean,
  touched?: boolean,
  timer: number,
  btnChildren: JSX.Element | string,
  recoveryPassword: () => void,
}) {
  const timer = props.timer / 1000;
  const [state, setState] = useState(false)
  const { data, errors, onChange } = props
  const { forbiddenNextStep } = useMovementWithinMultistep(errors.password?.length > 0)
  const { count } = useCountdownSeconds(props.timer ? timer : 30, [props.timer], state);
  const { t } = useTranslation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (props.onSubmit) props.onSubmit()
  }

  return (
    <>
      <div className={styles.phoneNumber}>
        <p>{t('login_with_re_password.text')}</p>
        <h5>{data.phone?.value}</h5>
      </div>
      <form onSubmit={handleSubmit}>
        <Input
          autoFocus
          name='password'
          type='password'
          autoComplete='one-time-code'
          title={t('multistep_form.sms_code') ?? "SMS-код"}
          textButton={count > 0 ? `${t('login_with_re_password.text_button.0')} ${count} ${t('login_with_re_password.text_button.1')}` : `${t('login_with_re_password.text_button.2')}`}
          disabledButton={count > 0}
          onClick={() => {
            setState(true)
            props.recoveryPassword()
          }}
          value={data.password?.value}
          handleChange={onChange}
          error={errors?.password}
          touched={props.touched}
        />
        <Button id='LoginWithRePassword' type="submit" loading={props.loading} disabled={forbiddenNextStep}>{props.btnChildren}</Button>
      </form>
    </>
  )
}

export default LoginWithRePassword
