
import { Skeleton } from 'shared/ui';
import styles from './Ticket.module.scss';

function SkeletonTicket() {
  return (
    <div className={styles.ticket}>
      <div className={styles.place}>
        <div className={styles.placeName}>
          <Skeleton type="span" style={{width: '37px'}}/>
          <Skeleton type="span" style={{width: '37px'}}/>
        </div>
        <h2><Skeleton type="span" style={{width: '37px'}}/></h2>
      </div>
      <div className={styles.place}>
        <div className={styles.placeName}>
          <Skeleton type="span" style={{width: '37px'}}/>
          <Skeleton type="span" style={{width: '37px'}}/>
        </div>
        <h2><Skeleton type="span" style={{width: '37px'}}/></h2>
      </div>
      <div className={styles.place}>
        <div className={styles.placeName}>
          <Skeleton type="span" style={{width: '37px'}}/>
          <Skeleton type="span" style={{width: '37px'}}/>
        </div>
        <h2><span><Skeleton type="span" style={{width: '37px'}}/></span></h2>
      </div>
    </div>
  )
}

export default SkeletonTicket