import { ApolloLink } from '@apollo/client';
import Credentials from '@sebbia/auth-client/lib/Credentials';
import { BehaviorSubject } from 'rxjs';

export enum AuthorizationState {
  AUTHORIZED = 'AUTHORIZED',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  REQUIRE_AUTHORIZATION = 'REQUIRE_AUTHORIZATION'
}

export interface AuthService {
  authenticated: BehaviorSubject<boolean>;
  state: BehaviorSubject<AuthorizationState>;
  credentials: Credentials | null;
  tokenRefreshLink: ApolloLink;
  isAuthorized: boolean;
  init(): Promise<void>;
  login(login: string, password: string): Promise<void>;
  logout(): Promise<void>;
  requireAuthorization(): Promise<void>;
}