import { classNames } from 'shared/utils';
import styles from './CloseButton.module.scss';

function CloseButton(props: {
  onClick?: () => void;
  backgroundColor?: 'systemBlack' | 'systemWhite';
  size?: 'big' | 'medium' | 'small';
  type?: 'submit' | 'reset' | 'button';
  id: string
}) {
  return (
    <button
      id={`${props.id}CloseButton`}
      className={classNames(
        styles.btn,
        styles[`${props.size}`],
        styles[`${props.backgroundColor}`]
      )}
      onClick={props.onClick}
      type={props.type}
    >
      <div className={styles.wrapper}>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.07244 13.0944C1.44106 13.0944 0.919495 12.5728 0.919495 11.9277C0.919495 11.612 1.04302 11.3375 1.26263 11.1316L5.36655 7.01399L1.26263 2.89634C1.04302 2.70418 0.919495 2.41595 0.919495 2.10026C0.919495 1.46889 1.44106 0.961044 2.07244 0.961044C2.40185 0.961044 2.66263 1.08457 2.86851 1.30418L6.98616 5.4081L11.1313 1.29046C11.3646 1.0434 11.6254 0.933594 11.9273 0.933594C12.5724 0.933594 13.0803 1.45516 13.0803 2.08653C13.0803 2.40222 12.9842 2.66301 12.7371 2.88261L8.6195 7.01399L12.7234 11.1042C12.943 11.3238 13.0666 11.5983 13.0666 11.9277C13.0666 12.5728 12.5587 13.0944 11.9136 13.0944C11.5842 13.0944 11.296 12.9571 11.0901 12.7512L6.98616 8.63359L2.89597 12.7512C2.69008 12.9708 2.40185 13.0944 2.07244 13.0944Z"
            fill="#323232"
          />
        </svg>
      </div>
    </button>
  );
}
export default CloseButton;
