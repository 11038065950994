import React from 'react';
import { ReactComponent as SecondSvg } from './second.styled.svg';

function propsToCssVars(props?: SecondProps): React.CSSProperties {
    return {
        '--fan-theme_clubPrimary': props?.styles?.fanThemeClubPrimary,
    } as React.CSSProperties
}

export type SecondProps = {
    styles?: {
        fanThemeClubPrimary?: string
    }
}

/**
 * Generated component
 * Available CSS variables: 
 * fan-theme_clubPrimary,
 **/
export function Second(props?: SecondProps) {
    return <SecondSvg style={{ ...propsToCssVars(props) }}/>
}