import { useState } from 'react';
import { ProfileService } from 'entities/Profile/model';
import { AuthService } from 'processes/Auth';
import { PreferencesService } from 'processes/Preferences';
import { RecoveryMultistepForm, SignInMultistepForm, SignUpMultistepForm } from 'features/Authentication';


enum Auth {
  Signin = 'Signin',
  Signup = 'Signup',
  Recovery = 'Recovery'
}

function AuthWithSwitch(props: {
  authService: AuthService,
  profileService: ProfileService,
  preferencesService: PreferencesService,
  onFinish?: () => void,
}) {
  const [phone, setPhone] = useState('')
  const [form, setForm] = useState<Auth>(Auth.Signin)

  const onFinish = () => {
    props.onFinish?.()
  }

  const openAuthorization = () => {
    setForm(Auth.Signin);
    props.profileService.resetRegistration();
  }

  const openRegistration = () => {
    setForm(Auth.Signup);
    props.profileService.startRegistration();
  }

  const openRecovery = () => {
    setForm(Auth.Recovery);
    props.profileService.resetRegistration();
  }

  return (
      <>
        {form === Auth.Recovery && (
            <RecoveryMultistepForm
              restorePassword={(phone: string, token?: string) => props.profileService.restorePassword(phone, token)}
              login={(phone: string, password: string) => props.authService.login(phone, password)}
              preferencesService={props.preferencesService}
              onFinish={onFinish}
              openAuthorization={openAuthorization}
              phone={phone}
              setPhone={(phone: string) => setPhone(phone)}
            />
          )
        }
        {form === Auth.Signup && (
            <SignUpMultistepForm
              login={(phone: string, password: string) => props.authService.login(phone, password)}
              profileService={props.profileService}
              preferencesService={props.preferencesService}
              onFinish={onFinish}
              openAuthorization={openAuthorization}
              phone={phone}
              setPhone={(phone: string) => setPhone(phone)}
            />
          )
        }
        {form === Auth.Signin && (
            <SignInMultistepForm
              onFinish={onFinish}
              login={(phone: string, password: string) => props.authService.login(phone, password)}
              openRegistration={() => openRegistration()}
              openRecovery={() => openRecovery()}
              phone={phone}
              setPhone={(phone: string) => setPhone(phone)}
            />
          )
        }
    </>
  )
}

export default AuthWithSwitch
