import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Path } from 'routes/path';
import { groupBy } from 'shared/utils';
import { ItemType, OrderItemDescriptor } from 'entities/Order/model';

import ErrorScreen from 'pages/ErrorScreen/ErrorScreen';
import { OrderSeasonTicketsList, OrderTicketsList } from '../OrderItemsLists';
import { ReactComponent as PlusOval } from 'shared/assets/icons/icon_plusOval.svg';

import styles from './Order.module.scss';

function OrderComponent(props: {
  orderItemDescriptors: OrderItemDescriptor[]
  removeItem: (orderItemId: string) => void
}) {
  const { t } = useTranslation();

  return (
    <main className={styles.ordersWrap}>
      <div className={styles.orderFactoryWrapper}>
        { Array.from( groupBy<OrderItemDescriptor, ItemType>(props.orderItemDescriptors, item => item.type), ([key, value]) =>
            orderFactory( key, value, props.removeItem)
          )
        }
      </div>
      <div className={styles.bottomWrap}>
        <Link className={styles.addTickets} to={Path.SHOP}><PlusOval /> {t('order_component.link_text')}</Link>
      </div>
    </main>
  )

  function orderFactory(type: ItemType, OrderItemDescriptors: OrderItemDescriptor[], remove: (orderItemId: string) => void): JSX.Element {
    switch (type) {
      case ItemType.TICKET:
        return <OrderTicketsList key={type} removeItem={remove} orderItemDescriptors={OrderItemDescriptors}/>
      case ItemType.SEASON_TICKET:
        return <OrderSeasonTicketsList key={type} title={t('order_component.order_factory.0')} removeItem={remove} orderItemDescriptors={OrderItemDescriptors}/>
      case ItemType.PROLONG_SEASON_TICKET:
        return <OrderSeasonTicketsList key={type} title={t('order_component.order_factory.1')} removeItem={remove} orderItemDescriptors={OrderItemDescriptors}/>
      case ItemType.OUTFIT:
        return <ErrorScreen/>
    }
  }
}

export default OrderComponent
