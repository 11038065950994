import { ObjectDeserializer } from '@sebbia/object-deserializer';
import { SeasonTicketDescriptor, SeasonTicketDescriptorStatus } from './types';


export function seasonPassDescriptorDeserializer(o: ObjectDeserializer): SeasonTicketDescriptor {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    description: o.optional('description')?.asString,
    status: o.required('status').asEnum(SeasonTicketDescriptorStatus),
  };
}
