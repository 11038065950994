import { useState, useEffect } from 'react'
import { useInterval } from 'shared/utils'

export function useCountdownSeconds(seconds: number, dependencies: any[],flag = false, msDelay?: number) {
  const [count, setCount] = useState<number>(seconds)
  const [delay] = useState<number>(msDelay || 1000)

  useEffect(() => {
    setCount(seconds)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies])

  useEffect(() => {
    if (flag) {
      setCount(seconds)
    }
  }, [flag])
  useInterval(() => {
    setCount(count - 1)
  }, count > 0 ? delay : null)

  return { count, setCount }
}
