import { CSSProperties, ReactNode } from 'react';
import Loader from 'shared/ui/Loader/Loader';
import { classNames } from 'shared/utils';
import styles from './Button.module.scss';

function Button(props: {
  id: string;
  disabled?: boolean;
  children: JSX.Element | string;
  icon?: ReactNode;
  loading?: boolean;
  style?: CSSProperties;
  className?: string;
  type?: 'submit' | 'button';
  onClick?: () => void;
}) {
  const { icon, loading, disabled, className, style, onClick } = props;

  const active = disabled ? 'disable' : 'active';

  return (
    <button
      id={`${props.id}funcButton`}
      className={classNames(className, styles.btn, styles[`btn-${active}`])}
      style={style}
      onClick={onClick}
      disabled={props.disabled}
    >
      <span className={styles.icon}>{loading ? <Loader className={styles.buttonLoader} /> : icon}</span>
      <span className={styles.text}>{props.children}</span>
    </button>
  );
}

export default Button;
