import { Person } from "entities/Profile";
import { localizedStringIsNotEmpty } from "shared/utils";

export function ensureSufficientUserData(person: Person): boolean {
    return [
        localizedStringIsNotEmpty(person.name),
        localizedStringIsNotEmpty(person.surname),
        person.contacts.length > 1
    ].every(Boolean)  
}
