import styles from './NoEventsBanner.module.scss';
import { Button, Skeleton } from 'shared/ui';
import IllNoEvents from 'figma/components/IllNoEvents/IllNoEvents';

function NoEventsBanner(props: {
  title: string,
  subtitle?: string,
  button?: {
    text: string,
    onClick: () => void
  }
  brandColors?: {colorThemeClubNavigation1?: string, colorThemeClubPrimaryLight?: string}
}) {

  return (
    <div className={styles.NoEventsBanner} >
        {props.title ? (
              <IllNoEvents styles={props.brandColors}/>
            ) : (
              <Skeleton type="avatar" style={{ width: '240px', height: '240px' }} />
            )
          }
        <div className={styles.bannerInfo} >
          {props.title ? (
            <h2>{props.title}</h2>
            ) : (
              <Skeleton type="span" />
            )
          }

          {props.button?.text
            ? <Button id='NoEventsBanner' onClick={props.button?.onClick}>{props.button?.text}</Button>
            : props.subtitle
            ? <p>{props.subtitle}</p>
            : <Skeleton type="span" />
          }
        </div>
    </div>
  )
}

export default NoEventsBanner;
