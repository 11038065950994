import { ReactElement, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { classNames, useLockedBody } from 'shared/utils';
import styles from './Modal.module.scss';

function Modal(props: {
  closeModal: () => void;
  children: ReactElement | ReactElement[];
  visible: boolean;
  outsideClickClose?: boolean;
  isLoyaltyModal?: boolean;
}) {
  useLockedBody(props.visible);
  if (!props.visible) return null;

  return ReactDOM.createPortal(
    <>
      <div
        role="dialog"
        className={classNames(styles.overlay, props.visible ? styles.overlay_visible : '')}
        onClick={() => props.outsideClickClose && props.closeModal()}
      >
        <div
          className={classNames(styles.modal, props.isLoyaltyModal ? styles.loyaltyModal : '', props.visible ? styles.modal_visible : '')}
          onClick={(e) => e.stopPropagation()}
        >
          {props.children}
        </div>
      </div>
    </>,
    document.body
  );
}

export function ModalBody(props: { children: ReactElement | ReactNode | ReactNode[] }) {
  return <div className={styles.modalBody}>{props.children}</div>;
}

export default Modal;
