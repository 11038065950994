import { PreferenceKeys, PreferencesService } from 'processes/Preferences';

export function initGlobalStyles(preferencesService: PreferencesService) {
  const colorOverridesStyle = document.createElement('style')
  colorOverridesStyle.innerHTML = `
    :root {
      --fan-theme_clubAccent-override: ${preferencesService.get(PreferenceKeys.CLUB_ACCENT)};
      --fan-theme_clubAccent: ${preferencesService.get(PreferenceKeys.CLUB_ACCENT)};
      --fan-theme_clubBg1-override: ${preferencesService.get(PreferenceKeys.CLUB_BG_1)};
      --fan-theme_clubBg2-override: ${preferencesService.get(PreferenceKeys.CLUB_BG_2)};
      --fan-theme_clubBg3-override: ${preferencesService.get(PreferenceKeys.CLUB_BG_3)};
      --fan-theme_clubNavigation1-override: ${preferencesService.get(PreferenceKeys.CLUB_NAVIGATION_1)};
      --fan-theme_clubNavigation2-override: ${preferencesService.get(PreferenceKeys.CLUB_NAVIGATION_2)};
      --fan-theme_clubPrimary-override: ${preferencesService.get(PreferenceKeys.CLUB_PRIMARY)};
      --fan-theme_clubPrimaryLight-override: ${preferencesService.get(PreferenceKeys.CLUB_PRIMARY_LIGHT)};
      --clubLogoOverride: url(${preferencesService.get(PreferenceKeys.LOGO_SRC)});
    }
  `
  document.getElementsByTagName('head')[0].appendChild(colorOverridesStyle);
}