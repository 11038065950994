import { PlacePrice } from "entities/Venue/model/types";

export type ReservationError = {
  errorType: 'ReservationError';
  placePrice: PlacePrice;
};

export type PackedReservationError = {
  errorType: 'PackedReservationError';
  places: PlacePrice[];
};

export type CancelError = {
  errorType: 'CancelError';
};

export type PlacesReservationError = {
  errorType: string;
};

export type UnknownError = {
  errorType: 'UnknownError';
};

export type AuthorizationRequired = {
  errorType: 'AuthorizationRequired';
};

export type CartAlreadyFull = {
  errorType: 'CartAlreadyFull';
};

export type PlacesReservationBaseErrors =
  | PlacesReservationError
  | CancelError
  | ReservationError
  | UnknownError
  | AuthorizationRequired;

export function isError(object: any): object is PlacesReservationError {
  return object !== undefined && 'errorType' in object;
}
