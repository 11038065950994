import { PreferenceKeys, PreferencesService } from "processes/Preferences";
import { useTranslation } from 'react-i18next';

export default function useOnboardingMedia(preferences: PreferencesService, loyalty?: boolean) {
  const { t } = useTranslation();
  const image = preferences.get(PreferenceKeys.ONBOARDING_MEDIA_1);
  const mediaList = [<img src={image} alt={t('common.welcome') ?? "Добро пожаловать"} />];

  const videos = [
    preferences.get(PreferenceKeys.ONBOARDING_MEDIA_2),
    preferences.get(PreferenceKeys.ONBOARDING_MEDIA_3),
  ];

  if (loyalty) videos.push(preferences.get(PreferenceKeys.ONBOARDING_MEDIA_4));

  return mediaList.concat(videos.map(src => <video src={src} autoPlay></video>));
}
