import React from 'react';
import { ReactComponent as CartAddSvg } from './cart-add.styled.svg';

function propsToCssVars(props?: CartAddProps): React.CSSProperties {
    return {
        '--fan-theme_clubNavigation1': props?.styles?.fanThemeClubNavigation1,
    } as React.CSSProperties
}

export type CartAddProps = {
    styles?: {
        fanThemeClubNavigation1?: string
    }
}

/**
 * Generated component
 * Available CSS variables: 
 * fan-theme_clubNavigation1,
 **/
export function CartAdd(props?: CartAddProps) {
    return <CartAddSvg style={{ ...propsToCssVars(props) }}/>
}