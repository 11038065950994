import { NavLink } from 'react-router-dom';
import { NavItem } from '../Navigation/Navigation';

import styles from './../Navigation/Navigation.module.scss';

function NavList(props: {items: NavItem[], onClick?: () => void}) {
  return (
    <ul>
      { props.items.map((e, i) => (
          <li className={styles.items} onClick={props.onClick}  key={i}>
              <NavLink activeClassName={e.path ? styles.active : ''} onClick={e.onClick} className={styles.linkItems} to={location => e.path || location.pathname }>
                {e.icon}
                <span>{e.title}</span>
              </NavLink>
            </li>
          )
        )}
    </ul>
  )
}

export default NavList