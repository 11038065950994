import { rangeLinearGradient } from './utils/rangeLinearGradient';
import styles from './RangeSlider.module.scss';

function RangeSlider(props: {
  minRange: number,
  maxRange: number,
  value: number,
  step: number,
  onChange: (value: number) => void,
}) {
  return (
    <div className={styles.RangeSlider} >
      <input
        className={styles.rangeInput}
        style={{background: rangeLinearGradient(props.value, props.minRange, props.maxRange)}}
        type='range'
        onChange={(e) => props.onChange(Number(e.target.value))}
        min={props.minRange}
        max={props.maxRange}
        value={props.value}
        step={props.step}
      />
    </div>
  )
}

export default RangeSlider