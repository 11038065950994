import {useServiceData} from 'shared/utils';
import {useHistory, useParams} from 'react-router-dom';
import {MatchBanner, MatchesService, SkeletonMatchBanner} from 'entities/Matches';
import {VenueService} from 'entities/Venue';
import {useTranslation} from 'react-i18next';

import ErrorScreen from 'pages/ErrorScreen/ErrorScreen';
import {ItemsHead, Loader} from 'shared/ui';

import styles from './Event.module.scss';
import {AuthorizationState, AuthService} from 'processes/Auth';
import {ItemType, OrderService} from 'entities/Order';
import { PreferencesService} from 'processes/Preferences';
import {Path} from 'routes/path';
import {useAuthChanged} from 'features/Authentication/shared/utils/useAuthChanged';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useBannerOnTop} from './useBannerOnTop';
import MatchPlaces from 'entities/Venue/ui/Places/MatchPlaces';
import {OrderContext} from "../../entities/Order/model/OrderContext";

const Event = (props: {
  matchesService: MatchesService;
  venueService: VenueService;
  authService: AuthService;
  orderService: OrderService;
  preferencesService: PreferencesService;
}) => {
  const {matchesService, venueService, authService, orderService, preferencesService} = props;
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data: match, loading: matchLoading, error: matchError } = useServiceData(
    () => matchesService.getById(id),
    [matchesService]
  );
  const [bannerRef, isBannerOnTop] = useBannerOnTop();
  const refBannerWrapper = useRef<HTMLDivElement>(null);

  const [reducedBannerHeight, setReducedBannerHeight] = useState<number | undefined>(undefined);

  const {updateEventType} = useContext(OrderContext);
  updateEventType && updateEventType(ItemType.TICKET);

  const setHeight = useCallback(() => {
    const bannerWrapper = refBannerWrapper.current?.getBoundingClientRect();
    bannerWrapper && setReducedBannerHeight(isBannerOnTop ? bannerWrapper?.height : undefined);
  }, [refBannerWrapper, isBannerOnTop])

  useEffect(() => {
    setTimeout(setHeight, 400);
  }, [isBannerOnTop, setHeight])

  useAuthChanged(authService, async (newState, prevState) => {
    if(newState === AuthorizationState.NOT_AUTHORIZED){
      history.push(Path.SHOP)
    }
  })

  if (matchLoading)
    return (
      <>
        <SkeletonMatchBanner />
      </>
    );

  if (matchError) return <ErrorScreen error={matchError} />;

  if (!match) return <Loader />;

  return (
    <main className={styles.main}>
      <ItemsHead
        title={match.title}
        description={match.description ? match.description : ""}
        descriptionImage={match.cover?.publicLink!}
        metaContent="product"
      />
      {match && (
        <div ref={refBannerWrapper}>
          <div ref={bannerRef}>
            <MatchBanner
              preferencesService={props.preferencesService}
              match={match}
              loading={matchLoading}
              backBtn={{
                title: `${t('common.back')}`,
                onClick: () => history.push(Path.SHOP),
              }}
              isBannerOnTop={isBannerOnTop}
            />
          </div>
        </div>

      )}
      {venueService &&
        <MatchPlaces
          venueService={venueService}
          authService={authService}
          orderService={orderService}
          preferencesService={preferencesService}
          isBannerOnTop={isBannerOnTop}
          bannerHeight={reducedBannerHeight ? reducedBannerHeight : undefined}
        />
      }
    </main>
  );
};

export default Event;
