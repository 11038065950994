export const recoveryInitialState = {
  phone: {
    value: '',
    required: true,
    phone: true,
  },
  password: {
    value: '',
    required: true,
  }
}