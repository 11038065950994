import { PersonUpdateInput, ProfileService } from 'entities/Profile';
import { IErrors } from 'features/Authentication';
import UpdateProfileFormV2 from 'features/Authentication/shared/ui/UpdateProfileForm/UpdateProfileFormV2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui';

export const UpdateProfileComponent = (props: {
  profileService: ProfileService;
  onFinish: () => void;
}) => {
  const [profileData, setProfileData] = useState<PersonUpdateInput>({});
  const [errors, setErrors] = useState<IErrors>();
  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      const profile = await props.profileService.getProfile();
      setProfileData({
        name: profile.person?.name,
        surname: profile.person?.surname,
        patronymic: profile.person?.patronymic,
        contacts: profile.person?.contacts,
        birthday: profile.person?.birthday,
      });
    };
    init();
  }, []);

  const updateProfileComponent = (
    <UpdateProfileFormV2
      onChange={(data: PersonUpdateInput, errors?: IErrors) => {
        if (!errors) {
          setProfileData(data);
        }
        setErrors(errors);
      }}
      initialValues={profileData}
    />
  );

  return (
    <>
      {updateProfileComponent}
      <Button
        id='UpdateProfileComponentContinue'
        disabled={errors !== undefined}
        onClick={async () => {
          try {
            await props.profileService.updateProfile(profileData);
            props.onFinish();
          } catch {
            // TODO
          }
        }}
      >
        {t('update_profile_component.text') ?? "Продолжить"}
      </Button>
    </>
  );
};
