import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export function useBehaviorSubject<T>(behaviorSubject?: BehaviorSubject<T> ) {
  const [stateValue, setStateValue] = useState<T | undefined>(behaviorSubject?.value);
  useEffect(() => {
    if (!behaviorSubject) return
    const listener = behaviorSubject.subscribe(setStateValue);
    return () => listener.unsubscribe();
  }, [behaviorSubject]);
  return stateValue;
}
