import { ContactType } from 'entities/Profile';
import { DateTime } from 'luxon';
import { transliterate, LocalizedString } from 'shared/utils';

import { IFields } from "./validate"

export function formatPhone(phone: string) {
  return phone.replace(/\+7/, '7').replace(/[( )_-]/g, '')
}

export function formatBirthday(birthday: string) {
  return birthday.split('.').reverse().join('-')
}

export function formatPrice(num: number | string) {
  if (typeof num === "number") num = num.toString()
  return parseFloat(num)
          .toLocaleString("ru-RU",{style:'currency', currency:'RUB'})
          .replace(',', '.')
          .replace('.00', '')
}

export function formatPersonInput(data: IFields) {
  return {
    name: LocalizedString.fromObject({
      ru: data.name.value,
      en: transliterate(data.name.value)
    }),
    surname: LocalizedString.fromObject({
      ru: data.surname.value,
      en: transliterate(data.surname.value)
    }),
    patronymic: LocalizedString.fromObject({
      ru: data.patronymic.value,
      en: transliterate(data.patronymic.value)
    }),
    birthday: DateTime.fromFormat(data.birthday.value,  'dd.MM.yyyy'),
    contacts: [
      {
        type: ContactType.PHONE,
        value: formatPhone(data.phone.value),
      },
      {
        type: ContactType.EMAIL,
        value: data.email.value
      },
    ]
}
}