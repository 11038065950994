/**
 * Склонение форм существительных во множественном числе
 * @param  {Integer} amount количество
 * @param  {Array} words Массив слов. Пример: ['товар', 'товара', 'товаров']
 * @return {String} сопряженное слово
 */

export function pluralize(amount: number, words: [string, string, string]) {
  amount = Math.abs(amount);
  if (Number.isInteger(amount)) {
    const cases = [2, 0, 1, 1, 1, 2];  
    return words[ (amount%100>4 && amount%100<20)? 2 : cases[(amount%10<5)?amount%10:5] ];
  }
  return  words[1];
}