import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { deserialize } from '@sebbia/object-deserializer';
import { paginatedResultDeserializer } from 'shared/model';
import { matchDeserializer } from './deserializers';
import { MATCH_FRAGMENT_FIELDS } from './fragments';
import { Match, MatchesService } from './types';

/**
 * @deprecated since {@link EventsServiceImpl} exists 
 */
class MatchesServiceImpl implements MatchesService {
  constructor(private client: ApolloClient<NormalizedCacheObject>) {}

  async getById(id: string): Promise<Match> {
    const query = gql`
      ${MATCH_FRAGMENT_FIELDS}
      query getMatch($id: ID!) {
        matchLocalised {
          getById(id: $id) {
            ...MatchFields
          }
        }
      }
    `;

    const res = await this.client.query({ query, variables: { id }, fetchPolicy: 'cache-first' });
    return deserialize(res.data, o => 
      o
        .required('matchLocalised.getById')
        .asObject(matchDeserializer));
  }

  async getAvailable(): Promise<any> {
    const query = gql`
      ${MATCH_FRAGMENT_FIELDS}
      query getMatches {
        matchLocalised {
          getAvailableList(
            filter: {
              visibilityPlaces: [WEB_SHOP]
            }
          ) {
            total
            limit
            page
            list {
              ...MatchFields
            }
          }
        }
      }
    `;

    const res = await this.client.query({ query });
    return deserialize(res.data, o =>
      o
        .required('matchLocalised.getAvailableList')
        .asObject(o => paginatedResultDeserializer(o, matchDeserializer))
    );
  }
}

export default MatchesServiceImpl;
