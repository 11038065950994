import i18next from "i18next";

type KeyValueObject = Record<string, string>;

const fallbackLangCodes = i18next.options.fallbackLng as string[];
const fallbackLangCode = fallbackLangCodes?.[0] ?? 'en';

/**
 * Строка с несколькими локализациями
 */
export class LocalizedString {
  private constructor(private readonly data: KeyValueObject) {}

  /**
   * Создать из объекта {string: string}.
   * @param data Ключ - язык, значение - значение строки для указанного языка.
   */
  static fromObject(data: any): LocalizedString {
    const d: KeyValueObject = {};
    if (typeof data === 'string') {
      let parsedData;
      try {
        parsedData = JSON.parse(data);
        data = parsedData;
        this.getStringByKey(data, d);
      } catch (error) {
        d[fallbackLangCode[0]] = data;
      }
    } else {
      this.getStringByKey(data, d);
    }
    return new LocalizedString(d);
  }

  private static getStringByKey(data: any, d: KeyValueObject) {
    for (const key in data) {
      if (typeof key !== 'string') {
        throw new Error('<dcab428e> Key is not a string');
      }
      const value = data[key];
      if (typeof key !== 'string') {
        throw new Error('<eecdb6c6> Data is not a string');
      }
      d[key] = value;
    }
  }

  toObject(): KeyValueObject {
    return this.data;
  }

  static fromString(s: string, langCode?: string): LocalizedString {
    return new LocalizedString({
      [langCode || fallbackLangCode]: s,
    });
  }

  toString(langCode?: string): string {
    let result;
    if (langCode) {
      result = this.data[langCode];
      if (result || (result === '')) {
        return result;
      }
    }
    result = this.data[fallbackLangCode];
    if (result || (result === '')) {
      return result;
    }
    for (const key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        result = this.data[key];
        if (result || (result === '')) {
          return result;
        }
      }
    }
    throw new Error(`<dce73154> Cannot get localized string for lang code: ${langCode}`);
  }
}

export function localizedStringIsNotEmpty(localizedString: LocalizedString) {
  return localizedString.toString('ru') !== '' && localizedString.toString('en') !== '';
}
