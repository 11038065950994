import { putZeroInStartString, useRxJSCountdownTime } from '../../utils';
import { ReactComponent as StopWatch } from 'shared/assets/icons/stopwatch.svg';
import { useTranslation } from 'react-i18next';

import styles from './CountdownTimerOnRxJS.module.scss';

function CountdownTimerOnRxJS(props: { label?: string; date: Date}) {
  const { days, hours, minutes, seconds, leftTotal } = useRxJSCountdownTime(props.date)
  const { t } = useTranslation();

  if (leftTotal <= 0 || isNaN(props.date.getTime())) return null

  return (
    <div id="CountdownTimer" className={styles.CountdownTimer}>
      <div className={styles.timerIcon}>
        <StopWatch />
      </div>
      <div className={styles.timerLabel}>
        <span>{props.label}</span>
      </div>
      <div className={styles.timerWrap}>
        {days > 0 && `${days} ${t('common.days')} `}
        {hours > 0 && `${putZeroInStartString(hours)}:`}
        {`${putZeroInStartString(minutes)}:`}
        {`${putZeroInStartString(seconds)}`}
      </div>
    </div>
  )
}

export default CountdownTimerOnRxJS
