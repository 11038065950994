import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import CredentialStorage from '@sebbia/auth-client/lib/CredentialStorage';
import GraphQlClient from '@sebbia/auth-client/lib/GraphQLClientImpl';
import { AuthService, AuthServiceImpl } from 'processes/Auth';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';


async function initAuthService(
  preferences: PreferencesService,
  credentialStorage: CredentialStorage,
): Promise<AuthService> {
  const authGraphQlClient = new GraphQlClient(
    preferences.get(PreferenceKeys.GRAPHQL_ENDPOINT),
    20000
  );
  const authClient = new AuthClient(authGraphQlClient, credentialStorage);
  const authService = new AuthServiceImpl(authClient);
  await authService.init();
  return authService;
}

export default initAuthService;
