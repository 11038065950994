import { useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

function ReCaptcha(props: {
  onVerify: (token: string) => void,
  size?: 'compact' | 'normal' | 'invisible',
  sitekey?: string,
}) {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const handleVerifyRecaptcha = async () => {
    try {
      if (recaptchaRef) {
        const token = await recaptchaRef.current?.executeAsync()
        if (token) props.onVerify(token)
      }
    } catch(e) {
      console.error(e)
    } 
  }

  useEffect(() => {
    handleVerifyRecaptcha()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      size={props.size}
      sitekey={props.sitekey!}
    />
  )
}

export default ReCaptcha