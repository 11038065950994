import { useTranslation } from 'react-i18next';
import styles from './OrderDetails.module.scss';

function OrderDetails(props: { children: JSX.Element[] | JSX.Element, }) {
  const { t } = useTranslation();

  return (
    <aside className={styles.orderDetails}>
      <h3>{t('order_details.h3')}</h3>
      {props.children}
    </aside>
  )
}

export default OrderDetails
