import { OrderItemDescriptor, SeasonTicket } from 'entities/Order/model';
import { TicketDetails } from 'features/TicketDetails';
import styles from './OrderItems.module.scss';

function OrderSeasonTickets(props: {
  orderItemDescriptor: OrderItemDescriptor[];
  removeItem: (ticketsId: string) => void;
}) {
  const descriptor = (props.orderItemDescriptor[0].item as SeasonTicket).descriptor;

  return (
    <section className={styles.event}>
      <div className={styles.header}>
        <h3 className={styles.title}>{descriptor.title}</h3>
        <div className={styles.subtitle}></div>
      </div>

      {props.orderItemDescriptor &&
        props.orderItemDescriptor.map((item: OrderItemDescriptor, index: number) => (
          <TicketDetails
            id={item.id}
            key={index}
            sector={item.item.place.row.sector.title}
            row={item.item.place.row.number}
            place={item.item.place.number}
            price={item.price}
            priceWithDiscount={item.priceWithDiscount}
            removeItem={props.removeItem}
          />
        ))}
    </section>
  );
}

export default OrderSeasonTickets;
