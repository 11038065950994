import ReactDOM from 'react-dom';
import { classNames } from 'shared/utils';
import styles from './MobileNavigation.module.scss';

function MobileNavigation(props: {
  children: JSX.Element[] | JSX.Element;
  visible: boolean;
  className?: string;
  bodyClassName?: string;
}) {
  return ReactDOM.createPortal(
    <>
      <div
        className={classNames(
          styles.mobileMenyBody,
          props.className,
          props.visible ? styles.mobileMenyBody_visible : ''
        )}
      >
        <main className={props.bodyClassName}>{props.children}</main>
       </div>
      <div className={classNames(styles.menuOverlay, props.visible ? styles.menuOverlay_visible : '')} />
    </>,
    document.body
  )
}

export default MobileNavigation
