
import { useState, useEffect } from 'react'
import { BehaviorSubject } from 'rxjs';
import { useRxJSCountdownTime } from 'shared/ui/CountdownTimer/utils/useRxJSCountdownTime';

export function useUpdateBehaviorSubject<T>(behaviorSubject: BehaviorSubject<T>, getter: () => T, dependencies: any[], dateOfUpdate?: Date) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [isUpdated, setUpdated] = useState(false)

  const date = dateOfUpdate ? dateOfUpdate : new Date()
  const { leftTotal } = useRxJSCountdownTime(date)

  useEffect(() => {
    if (dateOfUpdate && leftTotal <= 0) setUpdated(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftTotal])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        await behaviorSubject.next(getter())
      } catch (e: any) {
        setError(e)
      } finally {
        setUpdated(false)
        setLoading(false)
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, ...dependencies])

  return { isUpdated, loading, error };
}