import styles from './IconWithCounter.module.scss';

function IconWithCounter(props: {
  icon: JSX.Element,
  count?: number
}) {
  return (
    <div className={styles.IconWithCounter}>
      {props.icon}
      {!!props.count && <div className={styles.counter}><span>{props.count}</span></div>}
    </div>
  )
}

export default IconWithCounter