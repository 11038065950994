import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  LoyaltyDescriptor,
  LoyaltyService,
  LTY_TransactionQuery,
  Tickets_LoyaltyApplicabilityError,
  Tickets_LoyaltyOrderCheckResult,
} from 'entities/Loyalty';
import {
  Order,
  OrderComponent,
  OrderDetails,
  OrderItemDescriptor,
  OrderService,
  TotalOrderDetails,
} from 'entities/Order';
import {ProfileService} from 'entities/Profile';
import {PaymentService} from 'processes/Payment';
import {PreferencesService} from 'processes/Preferences';
import {sortByReservedUntil} from './utils';
import {minDate, Money, useBehaviorSubject, useServiceData, useUpdateBehaviorSubject,} from 'shared/utils';
import ErrorScreen from 'pages/ErrorScreen/ErrorScreen';

import {Loader, PageHead} from 'shared/ui';
import {EmptyCart, ShoppingCart} from './ui';
import {PromocodeInput} from 'features/PromocodeInput';
import {PaymentPointsCard} from 'features/PaymentPointsCard';
import {switchToPrivate} from 'features/Navigation';
import {OrderPayment} from 'features/OrderPayment';

import styles from './Cart.module.scss';

function Cart(props: {
  orderService: OrderService;
  loyaltyService: LoyaltyService;
  paymentService: PaymentService;
  profileService: ProfileService;
  preferencesService: PreferencesService;
}) {
  const { t } = useTranslation();
  const stateOrder = useBehaviorSubject(props.orderService.behaviorOrder);
  const { data: loyaltyDescriptor } = useServiceData(
    () => props.loyaltyService.getLoyaltyDescriptor(),
    [props.loyaltyService.getLoyaltyDescriptor]
  );
  const [currentOrder, setCurrentOrder] = useState<Order>();
  const [loyaltyOrder, setLoyaltyOrder] = useState<
    Tickets_LoyaltyOrderCheckResult & LTY_TransactionQuery
  >();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [minReserved, setMinReserved] = useState<Date>();

  const cartHead = <PageHead title={t('common.cart')} description={t('cart.page_head_description')} />;

  useUpdateBehaviorSubject(
    props.orderService.behaviorOrder!,
    () => props.orderService.getCurrentOnly(),
    [],
    minReserved
  );

  useEffect(() => {
    (async () => {
      try {
        if (loading) setLoading(true);
        const orderRes = await stateOrder;
        if (orderRes) {
          const loyaltyRes = await props.loyaltyService.checkLoyaltyOrder(orderRes.id);
          setMinReserved(minDate<OrderItemDescriptor>(orderRes.items, item => item.reservedUntil!));
          setCurrentOrder(orderRes);
          setLoyaltyOrder(loyaltyRes);
        }
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    })();
  }, [stateOrder]);

  if (loading) return <Loader />;

  if (error) return <ErrorScreen error={error} />;

  if (!(currentOrder && loyaltyOrder && loyaltyDescriptor && currentOrder.items.length > 0))
    return (
      <>
        {cartHead}
        <EmptyCart preferencesService={props.preferencesService}/>
      </>
    );

  const disablePointsCard = !loyaltyOrder.maxPossibleExpenditure.value || !!loyaltyOrder.applicabilityError;

  const sortedOrder = sortByReservedUntil(currentOrder.items);

  return (
    <div className={styles.wrapper}>
      {cartHead}
      <h1 className={styles.title}>{t('common.cart')}</h1>
      <ShoppingCart>
        <OrderComponent
          orderItemDescriptors={sortedOrder}
          removeItem={(orderItemId: string) =>
            props.orderService.removeItemFromOrder(currentOrder.id, [orderItemId])
          }
        />
        <OrderDetails>
          <TotalOrderDetails
            amount={currentOrder.items.length}
            loyaltySpent={loyaltyOrder.loyaltySpent}
            totalPrice={currentOrder.price}
            totalPriceWithDiscount={currentOrder.priceWithDiscount}
          />
          <PromocodeInput
            appliedPromocode={currentOrder.appliedPromocode}
            cancelPromocode={() => props.orderService.cancelPromocode(currentOrder.id)}
            applyPromocode={(value: string) =>
              props.orderService.applyPromocode(currentOrder.id, value)
            }
          />
          <PaymentPointsCard
            applyLoyaltyBonuses={(value: Money) =>
              props.orderService.applyLoyaltyBonuses(currentOrder.id, value)
            }
            loyaltyDescriptor={loyaltyDescriptor}
            title={t('common.pay_with_points')}
            subtitle={getPointsCardSubtitle(loyaltyDescriptor, loyaltyOrder.applicabilityError)}
            maxPossibleExpenditure={loyaltyOrder.maxPossibleExpenditure}
            loyaltySpent={loyaltyOrder.loyaltySpent}
            disabled={disablePointsCard}
            totalLoyalty={loyaltyOrder.getUserBonusCount}
            isShown={loyaltyOrder.isApplicable}
          />
          <OrderPayment
            orderId={currentOrder.id}
            orderService={props.orderService}
            paymentService={props.paymentService}
            preferencesService={props.preferencesService}
            getProfile={() => props.profileService.getProfile()}
            onFinish={() => switchToPrivate(props.preferencesService)}
          />
        </OrderDetails>
      </ShoppingCart>
    </div>
  );

  function getPointsCardSubtitle(
    loyaltyDescriptor: LoyaltyDescriptor,
    applicabilityError?: Tickets_LoyaltyApplicabilityError
  ) {
    switch (applicabilityError) {
      case Tickets_LoyaltyApplicabilityError.LOYALTY_PROGRAM_IS_NOT_ACTIVE:
        return t('cart.get_points_card_subtitle.0');
      case Tickets_LoyaltyApplicabilityError.NOT_APPLICABLE_WITH_DISCOUNT:
        return t('cart.get_points_card_subtitle.1');
      default:
        return `${t('cart.get_points_card_subtitle.2')} ${loyaltyDescriptor.bonusPriceCoverPercentage}${t('cart.get_points_card_subtitle.3')}`;
    }
  }
}

export default Cart;


