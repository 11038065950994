import i18next from 'i18next';

export const DICTIONARY_ERRORS: Record<string, string> = {
  'already registered': i18next.t('dictionary_errors.already_registered'),
  'User unregistered': i18next.t('dictionary_errors.user_unregistered'),
  'ebfd6fa2': i18next.t('dictionary_errors.ebfd6fa2'),
  '76a97d9a': i18next.t('dictionary_errors.76a97d9a'),
  '02369b5c': i18next.t('dictionary_errors.02369b5c'),
  '74a2af6d': i18next.t('dictionary_errors.74a2af6d'),
  'cb0eaf1a': i18next.t('dictionary_errors.cb0eaf1a'),
  '9efb0ecf': i18next.t('dictionary_errors.9efb0ecf'),
  '1ab90f8f': i18next.t('dictionary_errors.1ab90f8f'),
  '4ea49f04': i18next.t('dictionary_errors.4ea49f04'),
  'a4a9b2ec': i18next.t('dictionary_errors.a4a9b2ec'),
  '43b119cf': i18next.t('dictionary_errors.43b119cf'),
  'Invalid captcha': i18next.t('dictionary_errors.invalid_captcha'),
  '0a18f9fe': '',
  '11c733fc': '',

}
