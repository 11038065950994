
import { SkeletonOrderDetails, SkeletonOrderItemsList } from 'entities/Order';
import styles from './ShoppingCart.module.scss';

function SkeletonCartComponent() {

  return (
    <div className={styles.cart}>
      <main className={styles.ordersWrap}>
      <SkeletonOrderItemsList />
      </main>
        <SkeletonOrderDetails />
    </div>
  )
}

export default SkeletonCartComponent