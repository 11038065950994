import { AuthorizationState, AuthService } from 'processes/Auth';
import { Subscription } from 'rxjs';

export default function ensureAuthorized(
  authService: AuthService,
  block: () => void,
  onDeny?: () => void
) {
  if (authService.isAuthorized) {
    block();
  } else {
    authService.requireAuthorization();
    let subscription: Subscription | undefined = undefined
    subscription = authService.state.subscribe(nextState => {
      try {
        switch (nextState) {
          case AuthorizationState.AUTHORIZED: {
            block();
            break;
          }
          case AuthorizationState.NOT_AUTHORIZED: {
            onDeny?.();
            break;
          }
        }
      } finally {
        subscription?.unsubscribe();
      }
    });
  }
}
