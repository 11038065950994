import { useTranslation } from 'react-i18next';
import Logo from 'shared/ui/Logo/Logo';
import Button from 'shared/ui/Buttons/Button/Button';
import styles from './MatchBanner.module.scss';
import { ReactComponent as LocationArrow } from 'shared/assets/icons/location_arrow.svg';
import { Team } from 'shared/model';
import { Match } from 'entities/Matches/model';
import { classNames, formatDateTimeLong, useWindowSize } from 'shared/utils';
import { BackButton } from 'shared/ui';
import i18next from 'i18next';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';

function MatchBanner(props: {
  preferencesService: PreferencesService;
  match: Match;
  loading: boolean;
  backBtn?: { title: string; onClick: () => void };
  onClick?: () => void;
  isBannerOnTop?: boolean;
  classes?: string;
}) {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const currentClubName = props.preferencesService.getNullable(PreferenceKeys.CLUB) || 'No name club';

  const bannerStyles = classNames(
    styles.matchBanner,
    props.isBannerOnTop ? styles.onTopMatchBanner : '',
    styles[currentClubName],
    props.classes
  );

  const customLeagueBanner = () => {
    const bannerUrl = props.preferencesService.getNullable(
      PreferenceKeys.CLUB_CUSTOM_LEAGUE_BANNER_URL
    );
    if (!bannerUrl) return null;
    const bannerAlt = props.preferencesService.getNullable(
      PreferenceKeys.CLUB_CUSTOM_LEAGUE_BANNER_TEXT
    );
    return <img alt={bannerAlt} src={bannerUrl} className={styles.leagueBanner} />;
  };

  return (
    <div className={classNames(props.backBtn ? styles.event : '')}>
      {customLeagueBanner()}
      <div className={bannerStyles} onClick={props.onClick}>
        {props.backBtn && <BackButton id="MatchBanner" {...props.backBtn} />}
        <TeamInfo team={props.match.team1} isBannerOnTop={props.isBannerOnTop} />
        <MainInfo
          match={props.match}
          button={
            width > 760 && !props.backBtn ? (
              <Button
                id="MatchBanner"
                children={t('event.match_banner.main_info.button') ?? 'Купить билеты'}
                className={styles.bannerBtn}
                onClick={props.onClick}
              />
            ) : undefined
          }
          showLeague={!customLeagueBanner()}
        />
        <TeamInfo team={props.match.team2} isBannerOnTop={props.isBannerOnTop} />
      </div>
    </div>
  );
}

function TeamInfo(props: { team: Team; isBannerOnTop?: boolean }) {
  return (
    <div className={styles.teamContainer}>
      <div className={classNames(styles.teamLogo)}>
        <div className={classNames(styles.teamLogoBackground)}></div>
        {props.team && (
          <div
            className={classNames(
              styles.teamLogoImg,
              props.isBannerOnTop ? styles.teamLogoHide : ''
            )}
            style={{ backgroundImage: `url(${props.team?.logo?.publicLink})` }}
          ></div>
        )}
      </div>
      <div className={styles.teamInfo}>
        <h3 className={classNames(styles.teamTitle, styles.teamTitleSmall)}>
          {props.team?.title.toString(i18next.language)}
        </h3>
        <p className={classNames(styles.teamLocation, styles.textHide)}>
          {props.team?.location?.toString(i18next.language)}
        </p>
      </div>
    </div>
  );
}

function MainInfo(props: { match: Match; button?: JSX.Element; showLeague?: boolean }) {
  return (
    <div className={classNames(styles.info, styles.infoFlexRight)}>
      {props.showLeague && (
        <LeagueTitle
          src={props.match?.stage?.tournament?.logo?.publicLink}
          title={props.match.stage.title.toString(i18next.language)}
        />
      )}
      <h3 className={classNames(styles.startTime, styles.marginTop22)}>
        {formatDateTimeLong(props.match.startTime)}
      </h3>
      <StadiumTitle title={props.match.venue.title.toString(i18next.language)} />
      {props.button}
    </div>
  );
}

function LeagueTitle(props: { title: string; src?: string }) {
  return (
    <div className={styles.league}>
      <div className={styles.leagueIcon}>
        <Logo src={props.src} classes={classNames(styles.logoSize18, styles.logoHide)} />
      </div>
      <div className={classNames(styles.leagueTitle, styles.textHide)}>{props.title}</div>
    </div>
  );
}

function StadiumTitle(props: { title: string }) {
  return (
    <div className={styles.stadiumContainer}>
      <LocationArrow className={classNames(styles.stadiumIcon, styles.stadiumIconHide)} />
      <div className={classNames(styles.stadiumTitle, styles.textHide)}>{props.title}</div>
    </div>
  );
}

export default MatchBanner;
