/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';
import { Button, Multistep, MultistepPrevButton, Steps, Success, useMovementWithinMultistep } from 'shared/ui';
import { signUpInitialState } from './utils/initialState';
import { formatPersonInput, formatPhone, FormWithReCaptcha, LoginWithRePassword, ReCaptcha, UpdateProfileForm, useFetchFormData, useForm } from '../shared';
import { getErrorMessage } from 'shared/utils';
import validate from '../shared/utils/validate';
import { PersonUpdateInput, ProfileService } from 'entities/Profile';
import { ModalHeaderWithTitle } from 'shared/ui/Modal/ModalHeader/ModalHeaderWithTitle';

function SignUpMultistepForm(props: {
  login: (phone: string, password: string) => Promise<void>,
  profileService: ProfileService,
  preferencesService: PreferencesService,
  openAuthorization?: () => void,
  phone?: string
  setPhone?: (phone: string) => void,
  onFinish?: () => void,
}) {
  const { selectedIndex, goNext, goPrevious } = useMovementWithinMultistep()
  const { formData, errors, changeHandler, setErrors } = useForm(signUpInitialState, validate)
  const [captchaCode, setCaptchaCode] = useState<string>()
  const { t } = useTranslation();
  const phone = formatPhone(formData.phone.value)
  const password = formData.password.value
  const person: PersonUpdateInput = formatPersonInput(formData)
  const brandColors = {
    colorThemeClubNavigation1: props.preferencesService?.get(PreferenceKeys.CLUB_NAVIGATION_1),
    colorThemeClubPrimaryLight: props.preferencesService?.get(PreferenceKeys.CLUB_PRIMARY_LIGHT)
  }

  const { submited: regSubmit, loading: regLoad, fetch: registration } = useFetchFormData(
    () => props.profileService.registration(phone, captchaCode),
    () => goNext(),
    (error: string) => setErrors({phone: getErrorMessage(error)})
  )

  const { data: nextRestoreAfterMsecs, submited: rePassSubmit, loading: rePassLoad, fetch: recoveryPassword } = useFetchFormData(
    () => props.profileService.restorePassword(phone),
    () => {},
    (error: string) => setErrors({password: getErrorMessage(error)})
  )

  const { submited: loginSubmit, loading: loginLoad, fetch: login} = useFetchFormData(
    () => props.login(phone, password),
    () => goNext(),
    (error: string) => setErrors({password: getErrorMessage(error)})
  )

  const { submited: updateProfileSubmit, loading: updateProfileLoad, fetch: updateProfile } = useFetchFormData(
    () => props.profileService.updateProfile(person),
    () => goNext(),
    (error: string) => setErrors({password: getErrorMessage(error)})
  )

  useEffect(() => {
    if (!props.phone) return
    formData.phone.value = props.phone
  }, [props.phone])

  useEffect(() => {
    if (!props.setPhone) return
    props.setPhone(formData.phone?.value)
  }, [props.setPhone, formData.phone.value])

  useEffect(() => {
    const requiredErrors = validate(formData, true)
    setErrors(requiredErrors)
  }, [selectedIndex, setErrors])

  const recaptchaKey = props.preferencesService.getNullable(PreferenceKeys.RECAPTCHA_KEY)

  const signUpSteps: Steps[] = [
    {
      title: t('multistep_form.registration'),
      closeable: true,
      headerBtn: <MultistepPrevButton
        title={t('multistep_form.entry') ?? "Вход"}
        onClick={() => props.openAuthorization?.()} />,
      component: <FormWithReCaptcha
        onChange={(e) => changeHandler(e)}
        onSubmit={() => registration()}
        data={formData}
        loading={regLoad}
        errors={errors}
        touched={regSubmit}
        btnChildren={t('multistep_form.send_sms_code') ?? "Отправить SMS-код"}
        policyLink={props.preferencesService.getNullable(PreferenceKeys.POLICY_LINK)}
        reCAPTCHA={
          recaptchaKey ? <ReCaptcha
            size='invisible'
            sitekey={recaptchaKey}
            onVerify={setCaptchaCode}
          /> : undefined
        }
      />
    },
    {
      title: t('multistep_form.registration'),
      closeable: true,
      headerBtn: <MultistepPrevButton onClick={goPrevious}/>,
      component: <LoginWithRePassword
        onChange={(e) => changeHandler(e)}
        onSubmit={() => login()}
        data={formData}
        errors={errors}
        loading={(loginLoad || rePassLoad)}
        touched={(loginSubmit || rePassSubmit)}
        timer={nextRestoreAfterMsecs!}
        recoveryPassword={() => recoveryPassword()}
        btnChildren={t('multistep_form.sign_up.next') ?? "Далее"}
      />
    },
    {
      title: t('multistep_form.sign_up.title'),
      closeable: false,
      // headerBtn: <MultistepPrevButton onClick={goPrevious}/>,
      component: <UpdateProfileForm
        onSubmit={() => updateProfile()}
        onChange={(e) => changeHandler(e)}
        data={formData}
        errors={errors}
        loading={updateProfileLoad}
        touched={updateProfileSubmit}
        btnChildren={t('multistep_form.sign_up.next') ?? "Далее"}
      />
    },
    {
      title: t('multistep_form.registration'),
      closeable: true,
      component:
        <Success
          illustration='registrationSuccess'
          title={t('multistep_form.sign_up.success_title')}
          button={<Button id='SignUpMultistepFormStart' onClick={() => props.onFinish?.()}>{t('multistep_form.sign_up.success_button') ?? "Начать"}</Button>}
          brandColors={brandColors}
        />
    },
  ]

  return (
    <>
    <ModalHeaderWithTitle
      title={signUpSteps[selectedIndex]?.title}
      headerButton={signUpSteps[selectedIndex]?.headerBtn}
      closeModal={
        signUpSteps[selectedIndex]?.closeable
          ? (() => props.onFinish?.())
          : undefined
      }
    />
    <Multistep steps={signUpSteps} selectedIndex={selectedIndex}/>
    </>
  )
}

export default SignUpMultistepForm
