import { useTranslation } from 'react-i18next';
import { classNames, Money, pluralize } from 'shared/utils';
import styles from './TotalOrderDetails.module.scss';

function TotalOrderDetails(props: {
  amount: number;
  loyaltySpent: Money;
  totalPrice: Money;
  totalPriceWithDiscount: Money;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.paymentDetails}>
      <div className={styles.details}>
        <p className={styles.paymentsName}>{props.amount + ' ' + pluralize(props.amount, [`${t('total_order_details.product.0')}`,`${t('total_order_details.product.1')}`,`${t('total_order_details.product.2')}`])} {t('total_order_details.text.0')}</p>
        <p className={styles.value}>{props.totalPrice.toLocaleString()}</p>
      </div>
      {
        Number(promotionsDiscount(props.totalPrice, props.totalPriceWithDiscount, props.loyaltySpent)) > 0 && (
          <div className={styles.details}>
            <p className={styles.paymentsName}>{t('total_order_details.text.1')}</p>
            <p className={styles.value}>{promotionsDiscount(props.totalPrice, props.totalPriceWithDiscount, props.loyaltySpent).toLocaleString()}</p>
          </div>
        )
      }
      {
        props.loyaltySpent && props.loyaltySpent.getMain() > 0 
          && (
            <div className={styles.details}>
              <p className={styles.paymentsName}>{t('common.pay_with_points')}</p>
              <p className={styles.value}><span>{props.loyaltySpent.toLocaleString()}</span></p>
            </div>
          )
      }
      <div className={classNames(styles.details, styles.sumTicketDetails)}>
        <h6 className={styles.paymentsName}>{t('total_order_details.text.2')}</h6>
        <h6 className={styles.value}>{props.totalPriceWithDiscount.toLocaleString()}</h6>
      </div>
    </div>
  )
}

function promotionsDiscount(price: Money, priceWithDiscount: Money, loyaltyAmount?: Money) {
  if (loyaltyAmount) return price.minus(priceWithDiscount).minus(loyaltyAmount)
  return price.minus(priceWithDiscount)
}

export default TotalOrderDetails
