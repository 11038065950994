import { useTranslation } from 'react-i18next';
import { PlacePrice } from 'entities/Venue/model';
import { CartAdd } from 'figma/components/Icons/cart-add/CartAdd';
import { Cross } from 'figma/components/Icons/cross/Cross/Cross';
import { ReactNode } from 'react';
import { Button } from 'shared/ui';
import { pluralize } from 'shared/utils/helpers/pluralize';
import { Money } from 'shared/utils';
import styles from './Places.module.scss';
import formatShortSectorName from 'entities/Venue/utils/formatShortSectorName';

export function PlaceCard(props: { place: PlacePrice; zIndex: number; onRemove?: (obj: PlacePrice) => void; }) {
  const { t } = useTranslation();
  return (
    <div className={ styles.placeCardWrapper } style={{ zIndex: props.zIndex }}>
      <div className={ styles.placeCard }>
        <div>
          <span className={ styles.placeCardTitle }>{t('common.places.0')} </span>
          <span className={ styles.placeCardPlaceNumber }>{ props.place.place.number }</span>
        </div>
        <div className={ styles.placeCardNumbers }>
          <div className={ styles.placeCardNumberBlock }>
            <span className={ styles.placeCardTitle }>{t('common.places.1')} </span>
            <span className={ styles.placeCardNumber }>{ props.place.place.rowNumber }</span>
          </div>
          <div>
            <span className={ styles.placeCardTitle }>{t('common.places.2')} </span>
            <span className={ styles.placeCardNumber }>{ formatShortSectorName(props.place.place.sectorTitle) }</span>
          </div>
        </div>
        <p className={ styles.placeCardPrice }>{ props.place.price.toLocaleString() }</p>
        <Button
          id='PlaceCard'
          onClick={ () => props.onRemove?.(props.place) }
          className={ styles.placeCardRemoveButton }
        >
          <Cross styles={{ fanThemeClubNavigation1: 'var(--systemWhite)' }} />
        </Button>
      </div>
    </div>
  );
}

export function PlaceCardMobile(props: {
  placesNumber: number,
  placesPrice: Money,
  onRemove?: () => void,
}) {
  const { t } = useTranslation();
  const words: [string, string, string] = [ `${t('selected_places.place_card_mobile.words.0')}`, `${t('selected_places.place_card_mobile.words.1')}`, `${t('selected_places.place_card_mobile.words.2')}` ];

  return (
    <>
      <div className={ styles.placesWrapperMobile } >
        <div className={ styles.placesBlockMobile } >
          <span className={ styles.placeCardTitle }>{t('selected_places.place_card_mobile.span_text')} { props.placesNumber } { pluralize(props.placesNumber, words) }</span>
          <Button
            id='PlaceCardMobile'
            onClick={ () => props.onRemove?.() }
            className={ styles.placeCardRemoveButton }
          >
            <Cross styles={{ fanThemeClubNavigation1: 'var(--systemBlack)' }} />
          </Button>
        </div>
        <p className={ styles.placeCardPrice }>{ props.placesPrice.toLocaleString() }</p>
      </div>
    </>
  );
}

export function GoToCartButton(props: { onClick: () => void; title: string }) {
  return (
    <div className={styles.addToCardButtonContainer}>
      <Button
        id='GoToCartButton'
        onClick={props.onClick}
        icon={<CartAdd styles={{ fanThemeClubNavigation1: 'var(--systemWhite)' }} />}
      >
        <span>{props.title}</span>
      </Button>
    </div>
  );
}

export default function MapFooter(props: { children: ReactNode | ReactNode[] }) {
  return <div className={styles.selectedPlacesContainer}>{props.children}</div>;
}
