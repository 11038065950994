import IllCartSuccess from 'figma/components/IllCartSuccess/IllCartSuccess';
import IllRegistrationSuccess from 'figma/components/IllRegistrationSuccess/IllRegistrationSuccess';
import { ReactChild } from 'react';
import styles from './Success.module.scss';

function Success(props: {
  title: string,
  illustration: 'cartSuccess' | 'registrationSuccess' | ReactChild
  subtitle?: string,
  button?: JSX.Element,
  brandColors: {colorThemeClubNavigation1?: string, colorThemeClubPrimaryLight?: string}
}) {
  return (
    <div className={styles.successWrap}>
      {props.illustration === 'cartSuccess' && <IllCartSuccess styles={props.brandColors} />}
      {props.illustration === 'registrationSuccess' && <IllRegistrationSuccess styles={props.brandColors} />}
      {typeof props.illustration !== 'string' && props.illustration}
      <h3>{props.title}</h3>
      <p>{props.subtitle}</p>
      {props.button}
    </div>
  )
}

export default Success
