import { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Scissors } from 'shared/assets/icons/icon_scissors.svg';

import { getErrorMessage } from 'shared/utils';
import { CloseButton, Loader } from 'shared/ui';

import styles from './PromocodeInput.module.scss';

function PromocodeInput(props: {
  appliedPromocode?: string;
  applyPromocode: (value: string) => void;
  cancelPromocode: () => void;
}) {
  const [value, setValue] = useState(props.appliedPromocode || '');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showBtn, setShowBtn] = useState(props.appliedPromocode ? true : false);
  const [aplliedPromo, setAplliedPromo] = useState(!!props.appliedPromocode);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError('');
    setValue(e.currentTarget.value.toUpperCase());
    setAplliedPromo(false);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (value.length > 0) return setShowBtn(true);
    return setShowBtn(false);
  };

  const handleClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!aplliedPromo) {
        setAplliedPromo(true);
        await props.applyPromocode(value);
      } else {
        setValue('');
        if (!error) await props.cancelPromocode();
        setError('');
      }
    } catch (err: any) {
      setError(getErrorMessage(err.message));
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setShowBtn(true);
  };

  return (
    <div className={styles.PromocodeInput}>
      <form onSubmit={handleClick} className={styles.wrap}>
        <input
          id="promoInput"
          className={error && styles.error}
          onChange={e => handleChange(e)}
          onFocus={e => handleFocus(e)}
          onBlur={e => handleBlur(e)}
          value={value}
          placeholder={t('promocode_input.input_placeholder') ?? 'Применить промокод'}
        />
        {!showBtn && (
          <label className={styles.editor}>
            <Scissors />
          </label>
        )}
        {showBtn && aplliedPromo && !loading && (
          <CloseButton id="PromocodeInputAppliedPromo" type="submit" size="small" />
        )}
        {showBtn && !aplliedPromo && !loading && (
          <button
            id="PromocodeInputNotAppliedPromoButton"
            type="submit"
            disabled={value.length === 0}
            className={styles.textButton}
          >
            {' '}
            {t('promocode_input.button_label')}{' '}
          </button>
        )}
        {loading && (
          <button id="PromocodeInputLoadingButton" className={styles.textButton} disabled={true}>
            <Loader className={'load'} />
          </button>
        )}
      </form>
      <label htmlFor="promoInput" className={styles.error}>
        {error}
      </label>
    </div>
  );
}

export default PromocodeInput;
