import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, useMovementWithinMultistep } from 'shared/ui';
import { IErrors, IFields } from '../../utils';

function LoginForm(props: {
  openRecovery?: () => void,
  onSubmit?: () => void,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  data: IFields,
  errors: IErrors,
  loading?: boolean,
  touched?: boolean,
  btnChildren: JSX.Element | string,
}) {
  const { errors, data, onChange } = props
  const { forbiddenNextStep } = useMovementWithinMultistep(Object.keys(errors).length > 0 || data.phone?.value.length === 0)
  const { t } = useTranslation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (props.onSubmit) props.onSubmit()
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        name='phone'
        title={t('multistep_form.your_phone_number') ?? "Номер вашего телефона"}
        value={data.phone?.value}
        placeholder='+7 000 000-00-00'
        mask='+7 000 000-00-00'
        handleChange={onChange}
        error={props.errors?.phone}
        touched={props.touched}
      />
      <Input
        name='password'
        type='password'
        title={t('multistep_form.sms_code') ?? "SMS-код"}
        autoComplete='current-password'
        textButton={t('login_form.text_button') ?? "SMS-код"}
        onClick={props.openRecovery}
        disabledButton={false}
        value={data.password?.value}
        handleChange={onChange}
        error={props.errors?.password}
        touched={props.touched}
      />
      <Button id='LoginForm' type="submit" loading={props.loading} disabled={forbiddenNextStep}>{props.btnChildren}</Button>
    </form>
  )
}

export default LoginForm
