import { OptionsForSend } from 'features/SendOptions/model';
import { CustomCheckbox, Input } from 'shared/ui';
import { useTranslation } from 'react-i18next';

import styles from './SendOptions.module.scss';

function SendOptions(props: {
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
  sendOptions: OptionsForSend,
  button?: JSX.Element,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.OptionsForSend}>
      <p>{t('send_options.0')}</p>
      <div className={styles.shippingOption}>
        <CustomCheckbox name='sendToMail' onChange={props.changeHandler} checked={props.sendOptions.sendToMail.checked}/>
        <span>{t('send_options.1')}</span>
        <Input
          handleChange={props.changeHandler}
          name='email'
          value={props.sendOptions.email.value}
          withoutLabel={true}
        />
      </div>
      <div className={styles.shippingOption}>
        <CustomCheckbox name='sendToSMS' onChange={props.changeHandler} checked={props.sendOptions.sendToSMS.checked}/>
        <span>{t('send_options.2')}</span>
        <Input
          handleChange={props.changeHandler}
          placeholder='+7 000 000-00-00' mask='+7 000 000-00-00'
          name='phone' value={props.sendOptions.phone.value}
          withoutLabel={true}
        />
       </div>
       {props.button}
    </div>
  )
}

export default SendOptions
