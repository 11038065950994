import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { deserialize } from '@sebbia/object-deserializer';

import { AuthService } from 'processes/Auth';
import {
  SeasonTicket,
  ProlongSeasonTicketService,
  SeasonTicketDescriptor,
} from './types';
import { seasonTicketDeserializer } from './deserializers';
import { GET_PROLONG_SEASON_TICKETS, PROLONG_SEASON_TICKET, GET_SEASON_TICKET_DESCRIPTOR_BY_ID } from './graphql';
import { groupBy } from 'shared/utils';

class ProlongSeasonTicketServiceImpl implements ProlongSeasonTicketService {
  constructor(private client: ApolloClient<NormalizedCacheObject>, private auth: AuthService) {}

  getSeasonTicketsToProlong = async (): Promise<SeasonTicket[]> => {
    const response = await this.client.query({
      fetchPolicy: 'cache-first',
      query: GET_PROLONG_SEASON_TICKETS
    })

    return deserialize(response, o => o.required('data.seasonTicket.getAvailableToProlong')
      .asArrayOfObjects(seasonTicketDeserializer)
    )
  }

  getDescriptorsToProlong = async (): Promise<SeasonTicketDescriptor[] | null> => {
    if(this.auth.isAuthorized) {
      const seasonTicketsToProlong = await this.getSeasonTicketsToProlong();
      const descriptors = Array.from(groupBy(seasonTicketsToProlong, it => it.descriptor.id).values())
        .map(it => it[0].descriptor)

      return descriptors;
    }
    return null;
  }



  prolong = async (visibleId: string, newDescriptorId: string): Promise<SeasonTicket> => {
    const response = await this.client.query({
      fetchPolicy: 'no-cache',
      query: PROLONG_SEASON_TICKET,
      variables: {
        data: {
          visibleId,
          seasonTicketDescriptorId: newDescriptorId
        }
      }
    })

    return deserialize(response, o => o.required('data.seasonTicket.prolong')
      .asObject(seasonTicketDeserializer)
    )
  }


  getDescriptorById = async (id: string) => {
    const response = await this.client.query({
      fetchPolicy: 'cache-first',
      query: GET_SEASON_TICKET_DESCRIPTOR_BY_ID,
      variables: {
        id
      }
    })

    return deserialize(response, o => o.required('data.seasonTicketDescriptor.getById')
      .asObject(d => {
        return {
          id: d.required('id').asString,
          title: d.required('title').asString,
          prolongationDescriptors: d.required('prolongationDescriptors').asArrayOfObjects(p => {
            return {
              id: p.required('id').asString,
              title: p.required('title').asString,
            }
          })
        }
      })
    )
  }
}

export default ProlongSeasonTicketServiceImpl;