import { AuthService } from 'processes/Auth';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';

import { ID } from 'shared/model/types';
import { PaymentError, PaymentService } from './types';

class PaymentServiceImpl implements PaymentService {
  constructor(private preferences: PreferencesService, private auth: AuthService) {}

  payOrder(id: ID, onSuccess: () => void, onError: (e: PaymentError) => void) {
    const credentials = this.auth.credentials;
    const token = credentials ? credentials.idToken : '';
    const paymentMethod =
      this.preferences.getNullable(PreferenceKeys.PAYMENT_ADAPTER) ?? 'CLOUDPAYMENTS';

    /*global PaymentsClient */
    /*eslint no-undef: "error"*/
    // @ts-ignore: Imported in main app
    var payments = new PaymentsClient(
      null,
      token,
      this.preferences.get(PreferenceKeys.GRAPHQL_ENDPOINT),
      'null',
      false,
      false
    );
    payments.pay(
      id,
      paymentMethod,
      () => {
        onSuccess();
      },
      (e: { data: PaymentError }) => {
        onError(e.data);
      },
      null,
      null
    );
  }
}

export default PaymentServiceImpl;
