import {PlacePhotoConfig} from "features/Map/ui/MapComponent/MapComponent";
import {PreferenceKeys, PreferencesService} from "processes/Preferences";
import {useMemo} from "react";
import {useWindowSize} from "shared/utils/hooks/useWindowSize";

export function usePlacePhotoConfig(preferencesService: PreferencesService) {
    const { width } = useWindowSize();
    return useMemo<PlacePhotoConfig | undefined>(() => {
        const enabledInPreferences =
          preferencesService.getNullable(PreferenceKeys.PLACE_PHOTO_ACTIVE)?.toLowerCase() === 'true';
        if (enabledInPreferences && width > 915) {
          return {
            enabled: enabledInPreferences,
            checkBeforeLoad:
              preferencesService.getNullable(PreferenceKeys.PLACE_PHOTO_CHECK_BEFORE)?.toLowerCase() ===
              'true',
            fileExtension: preferencesService.get(PreferenceKeys.PLACE_PHOTO_FILE_EXTENSION),
            baseUrl: preferencesService.get(PreferenceKeys.PLACE_PHOTO_URL_PREFIX),
          };
        } else {
          return undefined;
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
}

export default usePlacePhotoConfig;
