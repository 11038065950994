import { useRef } from 'react';
import { useElementSize, useWindowSize } from 'shared/utils';


import styles from './CardsGrid.module.scss'

function CardsGrid(props: {
  title?: string,
  children: JSX.Element[] | JSX.Element,
  minSizeItem?: number,
  columns?: number,
  gap?: number,
}) {
  const ref = useRef<HTMLElement | null>(null)
  const { width: elementWidth } = useElementSize(ref)
  const { width: windowWidth } = useWindowSize()
  const gap = props.gap || 16
  const items = props.columns || 2
  const calcMinSizeItem = windowWidth < 720 ? 320 : (elementWidth / items) - gap * (items - 1)
  const gridTemplateColumns = props.minSizeItem
    ? `repeat( auto-fill, minmax( min(100%, ${props.minSizeItem}px), 1fr ))`
    : `repeat( auto-fit, minmax( min(100%, ${calcMinSizeItem}px), 1fr ))`

  return (
    <article className={styles.wrapper} ref={ref}>
      <h3 className={styles.title}>{props.title}</h3>
      <div className={styles.grid} style={{
        gridTemplateColumns: gridTemplateColumns,
        gap: `${gap}px`
      }}>
        {props.children}
      </div>
    </article>
  )
}

export default CardsGrid