import {
  BannerCardBlock,
  BannerContentBlock,
  BannerTitleBlock,
  SmallCardBlock,
  TextBlock,
} from 'features/ConfigurableWarningModal/model/types';
import { WarningModalService } from 'features/ConfigurableWarningModal/model/WarningModalService';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CloseButton,
  Modal,
  ModalBody,
  ModalHeader,
  WarnBanner,
} from 'shared/ui';
import { useServiceData } from 'shared/utils';
import { RecordCallback } from 'shared/utils/helpers/types';
import styles from './ConfigurableWarningModal.module.scss';

function Section(props: { children: ReactElement | ReactElement[] }) {
  return <div className={styles.ModalCardsSection}>{props.children}</div>;
}

function Card(props: { block: BannerCardBlock }) {
  const { t } = useTranslation();

  return (
    <div className={styles.ModalCard}>
      <div className={styles.ModalCardHeader}>
        {props.block.imgLink && (
          <img src={props.block.imgLink} alt={t('common.icon') ?? 'Иконка'} className={styles.ModalCardImg} />
        )}
        {props.block.title && <p className={styles.ModalCardTitle}>{props.block.title}</p>}
      </div>
      {props.block.description && (
        <p
          className={styles.ModalCardDescription}
          dangerouslySetInnerHTML={{ __html: props.block.description }}
        />
      )}
    </div>
  );
}

function SmallCard(props: { block: SmallCardBlock }) {
  const { t } = useTranslation();

  return (
    <div className={styles.ModalSmallCard}>
      {props.block.imgLink && (
        <img src={props.block.imgLink} alt={t('common.icon') ?? 'Иконка'} className={styles.ModalSmallCardImg} />
      )}
      <p
        className={styles.ModalSmallCardDescription}
        dangerouslySetInnerHTML={{ __html: props.block.description }}
      />
    </div>
  );
}

function Text(props: { block: TextBlock }) {
  return (
    <div className={styles.ModalText} dangerouslySetInnerHTML={{ __html: props.block.content }} />
  );
}

function Title(props: { block: BannerTitleBlock }) {
  return <h3 className={styles.ModalBodyTitle}>{props.block.content}</h3>;
}

function modalBlockFactory(block: BannerContentBlock, index: number): ReactElement {
  const renderMethod = ({
    CARD: it => <Card key={index} block={it} />,
    SECTION: it => <Section key={index} >{it.content.map((c, index) => modalBlockFactory(c, index))}</Section>,
    SMALL_CARD: it => <SmallCard key={index} block={it} />,
    TEXT: it => <Text key={index} block={it} />,
    TITLE: it => <Title key={index} block={it} />,
  } as RecordCallback<BannerContentBlock, ReactElement>)[block.type] as (
    block: BannerContentBlock
  ) => ReactElement;

  return renderMethod(block);
}

export function ConfigurableWarningModal(props: {
  warningModalService: WarningModalService;
  openByDefault?: boolean;
}) {
  const { data: warningModalConfig } = useServiceData(async () => {
    return props.warningModalService.getConfig();
  }, []);

  const { t } = useTranslation();

  useEffect(() => {}, []);

  const [modalVisible, setModalVisible] = useState(!!props.openByDefault);

  const hasContent = !!(warningModalConfig?.enabled && warningModalConfig.content);

  return warningModalConfig?.enabled ? (
    <>
      <WarnBanner
        text={warningModalConfig.mainMessage}
        buttonText={warningModalConfig.detailsButtonText}
        onClick={hasContent ? () => setModalVisible(true) : undefined}
      />
      <Modal visible={modalVisible} closeModal={() => setModalVisible(false)} outsideClickClose>
        <div className={styles.ModalBody}>
          <ModalHeader headerClassName={styles.ModalHeader}>
            <div className={styles.ModalHeaderBody}>
              <h1 className={styles.ModalHeaderTitle}>
                {warningModalConfig?.content?.title || `${t('common.attention')}!`}
              </h1>
              <CloseButton id='ConfigurableWarningModal' backgroundColor="systemWhite" onClick={() => setModalVisible(false)} />
            </div>
          </ModalHeader>
          <ModalBody>{warningModalConfig.content?.blocks.map((block, index) => modalBlockFactory(block, index))}</ModalBody>
        </div>
      </Modal>
    </>
  ) : null;
}
