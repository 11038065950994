import { useState, useEffect } from 'react';
import { Duration } from 'shared/ui/CountdownTimer/utils/duration';
import { BehaviorSubject, timer } from 'rxjs';
import { scan, skip, map } from 'rxjs/operators';

export function useRxJSCountdownTime(date: Date) {
  let endDate = date.getTime()
  let currentTime = timer(0, 1000);
  let countStoreUpdates = new BehaviorSubject({});
  let countStoreState = countStoreUpdates.pipe(scan((currentValue, operation: any) => {
    return operation(currentValue);
  }), skip(1));

  currentTime.pipe(map(() => {
    return () => {
      const now = Date.now()
      const remainingMilliseconds = endDate - now;

      // if (remainingMilliseconds <= 0) return
      
      return {
        leftTotal: Math.floor(remainingMilliseconds),
        days: Math.floor(remainingMilliseconds / 86400000),
        hours: Math.floor((remainingMilliseconds % 86400000) / 3600000),
        minutes: Math.floor(((remainingMilliseconds % 86400000) % 3600000) / 60000),
        seconds: Math.floor((((remainingMilliseconds % 86400000) % 3600000) % 60000) / 1000)
      };
    };
  }))
  .subscribe(countStoreUpdates);

  const [leftTime, setLeftTime] = useState<Duration>({
    leftTotal: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  useEffect(() => {
    const listener = countStoreState.subscribe(setLeftTime);
    return () => {
      listener.unsubscribe()
    }
  }, [date])

  return {...leftTime, countStoreUpdates}
}