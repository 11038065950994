import React from 'react';
import { Services } from 'shared/model';
import AppRoutes from './AppRoutes';

function Routes(props: { services?: Services }) {
  return props.services ? (
    <>
      <AppRoutes services={props.services} />
    </>
  ) : null;
}

export default Routes;
