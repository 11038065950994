import styles from './InfoScreens.module.scss';
import InfoModalImg from 'shared/assets/images/loyalty_modal_bg.svg';
import { CloseButton } from '../Buttons';

function InfoScreen(props: {
  backgroundColorHeader: 'systemOrange' | 'clubBg',
  title: string,
  subtitle?: string,
  headerChildren?: JSX.Element,
  children: JSX.Element | JSX.Element[]
  onClose: () => void;
}) {
  const backgroundHeader = props.backgroundColorHeader ? props.backgroundColorHeader : 'clubPrimary'
  const fontColorHeader = props.backgroundColorHeader === 'systemOrange' ? 'systemBlack' : 'systemWhite'

  return (
    <div className={styles.infoSceen}>
        <header className={styles.infoHeader} style={{backgroundColor: `var(--${backgroundHeader})`}}>
          <div className={styles.infoCloseBtn}><CloseButton id='InfoScreen' backgroundColor='systemWhite' onClick={props.onClose} /></div>
          <div className={styles.infoHeaderBody}>
            <h1>{props.title}</h1>
            <div className={styles.infoHeaderSubtitle} style={{color: `var(--${fontColorHeader})` }}>
              <p>{props.subtitle}</p>
              {props.headerChildren}
            </div>
          </div>
          {props.backgroundColorHeader === 'systemOrange' && <img src={InfoModalImg} alt='orange info modal pic'/>}
        </header>
        <main className={styles.infoMain}>{props.children}</main>
    </div>
  )
}

export default InfoScreen
