import React from 'react';
import { ReactComponent as FirstSvg } from './first.styled.svg';

function propsToCssVars(props?: FirstProps): React.CSSProperties {
    return {
        '--fan-theme_clubPrimary': props?.styles?.fanThemeClubPrimary,
    } as React.CSSProperties
}

export type FirstProps = {
    styles?: {
        fanThemeClubPrimary?: string
    }
}

/**
 * Generated component
 * Available CSS variables: 
 * fan-theme_clubPrimary,
 **/
export function First(props?: FirstProps) {
    return <FirstSvg style={{ ...propsToCssVars(props) }}/>
}