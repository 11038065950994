import { useState, useEffect } from 'react'

export function useDelayUnmount(isMounted: boolean, delayTime: number) {
  const [ shouldRender, setShouldRender ] = useState(false);

  useEffect(() => {
      let timeoutId: number
      if (isMounted && !shouldRender) {
          setShouldRender(true);
      }
      else if(!isMounted && shouldRender) {
          timeoutId = window.setTimeout(
              () => setShouldRender(false), 
              delayTime
          );
      }
      return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
}