import React, {useEffect, useState} from 'react';
import * as L from 'leaflet';
import {KeyValueObject, PlacePrice, PlaceStatus} from 'entities/Venue';
import {MapHeight, MapSize} from 'shared/model';
import 'leaflet/dist/leaflet.css';
import './MapComponent.scss';
// DONT_DELETE: Crash without import
/*eslint-disable no-unused-vars*/
import 'leaflet-responsive-popup';
// DONT_DELETE
import 'leaflet-responsive-popup/leaflet.responsive.popup.css';
import {Money} from 'shared/utils';
import {throttle} from 'shared/utils/helpers/throttle';
import {useRefFromState} from 'shared/utils/hooks/useRefFromState';
import formatShortSectorName from 'entities/Venue/utils/formatShortSectorName';
import i18next from 'i18next';

type PlaceOptions = {
  radius: number;
  weight: number;
  id: string;
  number: string;
  row: string;
  sector: string;
  title: string;
  price: Money;
  fillColor: string;
  color: string;
  status: PlaceStatus;
  fillOpacity: number;
  interactive: boolean;
  isSelected: boolean;
  originPlacePrice: PlacePrice;
};

function getZoomCoefficient(zoom: number): number {
  let zoomKf;
  switch (zoom) {
    case 3:
      zoomKf = 3;
      break;
    case 4:
      zoomKf = 4;
      break;
    case 5:
      zoomKf = 8;
      break;
    case 6:
      zoomKf = 17;
      break;
    case 7:
      zoomKf = 30;
      break;
    case 8:
      zoomKf = 55;
      break;
    default:
      zoomKf = 1;
  }

  return zoomKf;
}

export type PlacePhotoConfig = {
  enabled: boolean;
  baseUrl: string;
  fileExtension: string;
  checkBeforeLoad: boolean;
};

type MapProps = {
  placesList: PlacePrice[];
  placesIdByIndex: KeyValueObject;
  selectedPlaces: PlacePrice[];
  mapSize: MapSize;
  mapUrl: string;
  onSelect?: (obj: PlacePrice) => void;
  onRemove?: (obj: PlacePrice) => void;
  initialZoom?: number;
  clickableFromZoom?: number;
  minZoom?: number;
  maxZoom?: number;
  placePhotoConfig?: PlacePhotoConfig;
  styleHeight?: MapHeight;
  // default is 0.5
  placesRadius?: number;
  // default is 0.2
  placesWeight?: number;
};

function addPopupToPlace(place: L.CircleMarker, config: PlacePhotoConfig, map: L.Map) {
  const options = place.options as PlaceOptions;

  let urlPrefix = config.baseUrl;
  let fileExtension = config.fileExtension;

  let fileName = `${i18next.t('common.places.2')}-${options.sector.toLowerCase().replace(' ', '')}_${i18next.t('common.places.1')}-${
    options.row
  }_${i18next.t('common.places.0')}-${options.number}`;
  let imageUrl = `${urlPrefix}${fileName}${fileExtension}`;

  const popup = L.responsivePopup().setContent(`
    <div class='photoContainer' style='background-image: url(${imageUrl})'
    >
      <div class='photoFooter' >

        <div class='photoFooter__item' >
          <div class='photoFooter__item__value' >
          ${i18next.t('common.places.2')} <span>${formatShortSectorName(options.sector)}</span>
          </div>
        </div>

        <div class='photoFooter__item__center' >
          <div class='photoFooter__item__value' >
          ${i18next.t('common.places.1')} <span>${options.row}</span>
          </div>
        </div>

        <div class='photoFooter__item' >
          <div class='photoFooter__item__value' >
          ${i18next.t('common.places.0')} <span>${options.number}</span>
          </div>
        </div>

      </div>
    </div>
  `);

  place.on('mouseover', function () {
    const zoomLevel = map.getZoom();
    if (zoomLevel > 4) {
      if (config.checkBeforeLoad) {
        fetch(imageUrl, {
          headers: {
            accept: 'image/avif,image/webp,image/apng,image/*,*/*;q=0.8',
          },
          method: 'GET',
        })
          .then(response => {
            if (response.ok) {
              setTimeout(() => {
                place
                  .bindPopup(popup, {
                    closeButton: false,
                    minWidth: 267,
                    autoPan: false,
                  })
                  .openPopup();
              }, 200);
            }
            console.log(response);
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        setTimeout(() => {
          place
            .bindPopup(popup, {
              closeButton: false,
              minWidth: 267,
              autoPan: false,
            })
            .openPopup();
        }, 200);
      }
    }
  });
  place.on('mouseout', function () {
    setTimeout(() => {
      place.closePopup();
    }, 200);
  });
}

function createNewOptions(item: PlacePrice, zoomKf: number, selected?: boolean, pRadius: number = 0.5, pWeight: number = 0.2): PlaceOptions {
  const isActive = item.status === 'ACTIVE' || selected === true;

  let color: string;
  if (!isActive) {
    color = '#eee';
  } else {
    color = item.color;
  }

  let fillColor: string;
  if (selected) {
    fillColor = '#ffffff';
  } else {
    fillColor = item.color;
  }

  return {
    radius: (pRadius * zoomKf) / (isActive ? 1 : 2),
    weight: selected ? pWeight * zoomKf : 0,
    id: item.place.id,
    number: item.place.number,
    row: item.place.rowNumber,
    sector: item.place.sectorTitle,
    title: item.place.number,
    price: item.price,
    color: color,
    fillColor: isActive ? fillColor : color,
    status: item.status,
    fillOpacity: 1,
    interactive: isActive,
    isSelected: selected === true,
    originPlacePrice: item,
  };
}

const zoomHandler = (k: number, item: L.CircleMarker, pRadius: number = 0.5, pWeight: number = 0.2) => {
  const options = item.options as PlaceOptions;
  const isActive = options.status === 'ACTIVE' || options.isSelected;
  item.setRadius((pRadius * k) / (isActive ? 1 : 2));
  if (options.isSelected) {
    item.setStyle({
      ...item.options,
      weight: pWeight * k,
    });
  }
  return item;
};

const MapComponent = (
  props: {
    containerId: string;
  } & MapProps
) => {
  const initialZoom = props.initialZoom || 3;
  const minZoom = props.minZoom || 3;
  const maxZoom = props.maxZoom || 8;
  const canvasTolerance = 5;
  const clickableFromZoom = props.clickableFromZoom || 5;
  const enablePlacePhoto = props.placePhotoConfig?.enabled || false;
  const [map, setMap] = useState<L.Map>();
  const [mapPlaces, setMapPlaces] = useState<L.CircleMarker[]>([]);
  const [placeCount, setPlaceCount] = useState(props.selectedPlaces.length);
  // const [redrawPlaces, setRedrawPlaces] = useState(true);

  const placesRef = useRefFromState(mapPlaces);

  //Change selected places on external changes
  useEffect(() => {
    if (props.selectedPlaces.length !== placeCount) {
      setPlaceCount(props.selectedPlaces.length);
    }

    if (map && mapPlaces.length > 0) {
      const selectedPlaceIds = props.selectedPlaces.map(it => it.place.id);

      const zoomKf = getZoomCoefficient(map.getZoom());
      mapPlaces
        .filter(it => (it.options as PlaceOptions).isSelected)
        .forEach(it => {
          const options = it.options as PlaceOptions;
          if (!selectedPlaceIds.includes(options.id)) {
            const newOptions = createNewOptions(options.originPlacePrice, zoomKf, false, props.placesRadius, props.placesWeight);
            it.setStyle(newOptions);
          }
        });

      selectedPlaceIds.forEach(placeId => {
        const place = mapPlaces.find(it => (it.options as PlaceOptions)?.id === placeId);
        if (place) {
          const options = place.options as PlaceOptions;
          const newOptions = createNewOptions(options.originPlacePrice, zoomKf, true, props.placesRadius, props.placesWeight);
          place.setStyle(newOptions);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapPlaces, props.selectedPlaces]);

  //Update places on external places change
  // useEffect(() => { //TODO: delete if not required with new card
  //   console.log('map places: ', props.placesList);
  //   if (JSON.stringify(props.placesList) !== JSON.stringify(localPlaces.current)) {
  //     localPlaces.current = props.placesList;
  //     setRedrawPlaces(true);
  //   }
  // }, [props.placesList]);

  //Update places on external and internal changes
  useEffect(() => {
    if (map) {
      const zoomKf = getZoomCoefficient(map.getZoom());
      const selectedPlaceIds = props.selectedPlaces.map(it => it.place.id);

      const onPlaceClick = throttle(async function (event: L.LeafletEvent) {
        const place = event.target as L.CircleMarker;
        const options = place.options as PlaceOptions;
        const zoomLevel = map.getZoom();

        if (zoomLevel >= clickableFromZoom) {
          const canBeSelected = options.status === 'ACTIVE';
          const isAlreadySelected = options.isSelected;

          if (canBeSelected || isAlreadySelected) {
            if (!isAlreadySelected) {
              setPlaceCount(pc => pc + 1);
              props.onSelect?.(options.originPlacePrice);
            } else {
              setPlaceCount(pc => pc - 1);
              props.onRemove?.(options.originPlacePrice);
            }
          }
        }
      }, 200);

      const createdPlaces = props.placesList.map((item) => {
        const lat = item.place.coordinates.y ? item.place.coordinates.y : 0;
        const lng = item.place.coordinates.x ? item.place.coordinates.x : 0;
        const latLng = L.latLng([lat, lng]);

        const isSelected = selectedPlaceIds.includes(item.place.id);

        const newOptions = createNewOptions(item, zoomKf, isSelected, props.placesRadius, props.placesWeight);
        const index = props.placesIdByIndex[item.place.id];
        const existedPlace = mapPlaces[index];
        if (existedPlace) {
          existedPlace.setStyle(newOptions)

          return existedPlace;
        } else {
          const newPlace = L.circleMarker(latLng, newOptions).addTo(map).on('click', onPlaceClick);

          if (enablePlacePhoto && props.placePhotoConfig !== undefined) {
            addPopupToPlace(newPlace, props.placePhotoConfig, map);
          }

          return newPlace;
        }
      });

      setMapPlaces(createdPlaces);
      // setRedrawPlaces(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, props.placesList]);

  //Create map
  useEffect(() => {
    if (!map) {
      const createdMap = L.map(props.containerId, {
        crs: L.CRS.Simple,
        renderer: L.canvas({ tolerance: canvasTolerance }),
        minZoom: minZoom,
        maxZoom: maxZoom,
        zoomControl: false,
        preferCanvas: true,
        // mapMode: 'SELECT',
      });

      const bounds: [number, number][] = [
        [0, 0],
        [props.mapSize.height, props.mapSize.width],
      ];
      L.imageOverlay(props.mapUrl, bounds).addTo(createdMap);
      createdMap.setMaxBounds(bounds);
      L.control.zoom({ position: 'topright' }).addTo(createdMap);
      createdMap.dragging.enable();
      createdMap.setView(
        new L.LatLng(props.mapSize.height / 2, props.mapSize.width / 2),
        initialZoom
      );

      createdMap.on('zoom', e => {
        const zoom = e.target._zoom;
        const zoomKf = getZoomCoefficient(zoom);
        placesRef.current.forEach(item => zoomHandler(zoomKf, item, props.placesRadius, props.placesWeight));
      });

      setMap(createdMap);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={props.containerId} style={props.styleHeight}></div>;
};

export default MapComponent;
