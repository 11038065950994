import { LoyaltyDescriptor } from "entities/Loyalty";
import styles from './OnboardingSlides.module.scss';
import AppCard from "../AppCard/AppCard";
import LoyaltyAccrual from "../LoyaltyAccrual/LoyaltyAccrual";
import { useTranslation } from 'react-i18next';

const Welcome = () => {
  const { t } = useTranslation();
  return (
    <article className={styles.slide}>
      <h1 className={styles.title}>{t('onboarding_slides.welcome.h1_text')}</h1>
      <p className={styles.text}>{t('onboarding_slides.welcome.p_text')}</p>
    </article>
  );
};

const Shop = () => {
  const { t } = useTranslation();
  return (
    <article className={styles.slide}>
      <h1 className={styles.title}>{t('onboarding_slides.shop.h1_text')}</h1>
      <ol className={styles.numberedList}>
        <li className={styles.listItem}>{t('onboarding_slides.shop.list.0')}</li>
        <li className={styles.listItem}>{t('onboarding_slides.shop.list.1')}</li>
        <li className={styles.listItem}>{t('onboarding_slides.shop.list.2')}</li>
        <li className={styles.listItem}>{t('onboarding_slides.shop.list.3')}</li>
      </ol>
    </article>
  );
};

const Account = () => {
  const { t } = useTranslation();
  return (
    <article className={styles.slide}>
      <h1 className={styles.title}>{t('onboarding_slides.account.h1_text')}</h1>
      <p className={styles.text}>{t('onboarding_slides.account.p_text')}</p>
      <AppCard />
    </article>
  );
};

const Loyalty = (props: { percent: number; name: string }) => {
  const { t } = useTranslation();
  return (
    <article className={styles.slide}>
      <h1 className={styles.title}>{t('onboarding_slides.loyalty.h1_text')}</h1>
      <LoyaltyAccrual percent={props.percent} />
      <p className={styles.text}>
        {t('onboarding_slides.loyalty.p_text.0')}{props.name}. {t('onboarding_slides.loyalty.p_text.1')}
      </p>
    </article>
  );
};

export default function useSlides(loyalty?: LoyaltyDescriptor) {
  const slides = [<Welcome />, <Shop />, <Account />];
  if (loyalty)
    slides.push(
      <Loyalty percent={loyalty.accrualPercentage} name={loyalty.loyaltyCurrencyName.plural} />
    );
  return slides;
}
