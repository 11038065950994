import { useCallback, useEffect, useRef, useState } from "react";

export const useBannerOnTop = (): [React.RefCallback<HTMLDivElement>, boolean] => {
  const bannerOrdinate = useRef<number>(0);
  const bannerSticked = useRef<boolean>(false);
  const bannerRef: React.RefCallback<HTMLDivElement> = useCallback(node => {
    if (node !== null) {
      const rect = node.getBoundingClientRect();
      bannerOrdinate.current = rect.y;
    }
  }, []);
  const rootEl = document.getElementById('root');
  const [isBannerOnTop, setIsBannerOnTop] = useState<boolean>(false);
 
  const updateState = (state: boolean) => {
    setIsBannerOnTop(state);
  }

  const scrollHandler = useCallback((bannerOrdinate: number) => { 
    const updateCondition = !!rootEl && !!bannerOrdinate;
    if(updateCondition){
      if (!bannerSticked.current && (rootEl.scrollTop >= (bannerOrdinate - 20))) {
        bannerSticked.current = true;
      } else if (bannerSticked.current && (rootEl.scrollTop + 40 < (bannerOrdinate - 20))) {
        bannerSticked.current = false;
      }
      updateState(bannerSticked.current);
    }
  }, [rootEl, bannerSticked]);

  useEffect(() => {
    rootEl?.addEventListener('scroll', () => scrollHandler(bannerOrdinate.current));
    return () => rootEl?.removeEventListener('scroll', () => scrollHandler(bannerOrdinate.current));
  }, [rootEl, scrollHandler]);

  return [bannerRef, isBannerOnTop];
}
