import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, split} from '@apollo/client';
import {WebSocketLink} from '@apollo/client/link/ws'
import {AuthService} from 'processes/Auth';
import {PreferenceKeys, PreferencesService} from 'processes/Preferences';
import {onError} from "@apollo/client/link/error"
import {getSupportedLngs} from 'i18n';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import {getMainDefinition} from '@apollo/client/utilities';

function initApolloClient(preferences: PreferencesService, authService: AuthService) {
  const currentLngs = getSupportedLngs();

  // TODO: Use graphql-ws library with the accompanying GraphQLWsLink(instead of WebSocketLink & SubscriptionClient) after project dependencies update
  const wsLink = new WebSocketLink(
    new SubscriptionClient(preferences.get(PreferenceKeys.GRAPHQL_WS_ENDPOINT), {
      reconnect: true,
    }),
  );

  const graphQlLink = new HttpLink({
    uri: preferences.get(PreferenceKeys.GRAPHQL_ENDPOINT),
  });

  const middlewareLink = new ApolloLink((operation, forward: any) => {
    operation.setContext({
      headers: {
        authorization: authService.credentials ? 'Bearer ' + authService.credentials.idToken : '',
        'Accept-Language': currentLngs,
      },
    });
    return forward(operation);
  });

  const tokenRefreshLink = authService.tokenRefreshLink;

  const authErrorHandlerLink = onError(error => {
    const isTerminationAuthError = error.graphQLErrors?.find(it => it.message.includes('BAD_CREDENTIALS')) !== undefined
    if (isTerminationAuthError) {
      authService.logout()
    }
  });

  const splitLink = split(
    ({query}) => {
      const definition = getMainDefinition(query);
      const isSubscription = definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      return (isSubscription);
    },
    wsLink,
    graphQlLink
  );

  return new ApolloClient({
    link: ApolloLink.from([tokenRefreshLink, authErrorHandlerLink, middlewareLink, splitLink]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
      },
      query: {
        fetchPolicy: "network-only",
      },
    },
    cache: new InMemoryCache(
      {
      typePolicies: {
        Query: {
          fields: {
            items: {
              merge: false,
            },
            order: {
              merge: false,
            },
            loyalty: {
              merge: false,
            },
          },
        },
        Mutation: {
          fields: {
            order: {
              merge: false,
            },
          },
        }
      }
    }
    ),
  });
}

export default initApolloClient;
