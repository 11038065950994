import { useState } from 'react';
import { CloseButton, Loader } from 'shared/ui';
import { classNames, Money, useWindowSize } from 'shared/utils';
import MobileTicket from '../Ticket/MobileTicket';
import Ticket from '../Ticket/Ticket';

import styles from './TicketDetails.module.scss';

function TicketDetails(props: {
  id: string,
  sector: string,
  row: string,
  place: string,
  price: Money,
  priceWithDiscount: Money,
  removeItem: (orderItemId: string) => void
}) {
  const [loading, setLoading] = useState(false)
  const { width } = useWindowSize()

  const remove = async () => {
    try {
      setLoading(true)
      await props.removeItem(props.id)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  if (width < 915) return (
    <div className={classNames(styles.ticketDetails, styles.isMobile)}>
      <MobileTicket sector={props.sector} row={props.row} place={props.place} />
      <div className={styles.price}>{props.price.value > props.priceWithDiscount.value ? props.price.toLocaleString() : null}</div>
      <div className={props.price.value > props.priceWithDiscount.value ? styles.priceWithDiscount : styles.priceWithoutDiscount}>{props.priceWithDiscount.toLocaleString()}</div>
      {
        loading
        ? <Loader className={styles.removeLoader}/>
        : <CloseButton id='TicketDetailsSmall' size='small' onClick={() => remove()}/>
      }
    </div>
  )

  return (
    <div className={styles.ticketDetails}>
      <Ticket sector={props.sector} row={props.row} place={props.place}/>
      <div className={styles.price}>{props.price.value > props.priceWithDiscount.value ? props.price.toLocaleString() : null}</div>
      <div className={props.price.value > props.priceWithDiscount.value ? styles.priceWithDiscount : styles.priceWithoutDiscount}>{props.priceWithDiscount.toLocaleString()}</div>
      {
        loading
        ? <Loader className={styles.removeLoader}/>
        : <CloseButton id='TicketDetailsMedium' size='medium' onClick={() => remove()}/>
      }
    </div>
  )
}

export default TicketDetails
