import { DateTime } from 'luxon';
import i18next from 'i18next';

export function formatDateTimeLong(date: DateTime) {
  const localized = date.toFormat('ccc, dd MMMM yyyy, HH:mm', { locale: i18next.language }); // Пт, 31 декабря 2021 , 18:35
  return localized.charAt(0).toUpperCase() + localized.slice(1);
}

function camelCase(string: String): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function lastOf<T>(array: T[]): T {
  if (array.length < 1) throw Error('<1c7cf41c> Array is empty');
  return array[array.length - 1];
}

export function formatDateTimeForOrder(date: DateTime) {
  const localized = date.toFormat('• dd MMMM yyyy, HH:mm • cccc', { locale: i18next.language }).split(' '); //  • 31 декабря 2021, 16:35 • Пятница
  return localized
    .slice(0, localized.length - 1)
    .concat(camelCase(lastOf(localized)))
    .join(' ');
}
