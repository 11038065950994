import { ObjectDeserializer } from '@sebbia/object-deserializer';
import { dateTimeParser, LocalizedString } from 'shared/utils';
import { User, Person, ContactType, UserContact, ShortFileDescriptor } from './types';

export function personDeserializer(d: ObjectDeserializer): Person {
  return {
    name: d.required('name').as((o) => LocalizedString.fromObject(o)),
    surname: d.required('surname').as((o) => LocalizedString.fromObject(o)),
    contacts: d.optional('contacts')?.asArrayOfObjects(contactDeserializer) || [],
    patronymic: d.optional('patronymic')?.as((o) => LocalizedString.fromObject(o)),
    birthday: d.optional('birthday')?.as(dateTimeParser),
    photo: d.optional('photo')?.asObject(shortFileDescriptorDeserializer),
  };
}

export function contactDeserializer(d: ObjectDeserializer): UserContact {
  return {
    type: d.required('type').asEnum(ContactType),
    value: d.required('value').asString,
  };
}

export function shortFileDescriptorDeserializer(f: ObjectDeserializer): ShortFileDescriptor {
  return {
    id: f.required('id').asString,
    publicLink: f.required('publicLink').asString,
  };
}



export function userDeserializer(o: ObjectDeserializer): User {
  return {
    id: o.required('id').asString,
    person: o.optional('person')?.asObject(personDeserializer),
  };
}

