import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { deserialize } from '@sebbia/object-deserializer';
import { paginatedResultDeserializer } from 'shared/model';
import { seasonPassDescriptorDeserializer } from './deserializers';
import { SeasonsPassService, SeasonTicketDescriptor, SeasonTicketDescriptorFilter } from './types';

class SeasonsPassServiceImpl implements SeasonsPassService {
  constructor(private client: ApolloClient<NormalizedCacheObject>) {}

  async getList(filter?: SeasonTicketDescriptorFilter): Promise<any> {
    const query = gql`
    query SHOP_getAvailableSeasonTicketDescriptor {
      seasonTicketDescriptor {
        getAvailableList {
          total
          limit
          page
          list {
            id
            title
            status
            description
          }
        }
      }
    }  
    `;

    const res = await this.client.query({ query });
    return deserialize(res.data, o =>
      o
        .required('seasonTicketDescriptor.getAvailableList')
        .asObject(o => paginatedResultDeserializer(o, seasonPassDescriptorDeserializer))
    );
  }

  async getById(id: string): Promise<SeasonTicketDescriptor> {
    const query = gql`
    query getEvent($id: ID!)  {
      seasonTicketDescriptor {
        getById(id: $id) {
            id
            title
            status
            description
        }
      }
    }  
    `;

    const res = await this.client.query({ query, variables: { id }, fetchPolicy: 'cache-first' });
    return deserialize(res.data, o =>
      o
        .required('seasonTicketDescriptor.getById')
        .asObject(seasonPassDescriptorDeserializer)
    );
  }
}

export default SeasonsPassServiceImpl;
