import { useTranslation } from 'react-i18next';
import styles from './ReCaptchaPolicies.module.scss'

function ReCaptchaPolicies() {
  const { t } = useTranslation();

  return (
    <div className={styles.ReCaptchaPolicies}>
      <p style={{fontSize: '12px'}}> {t('re_captcha_policies.0')} 
      <a href='https://policies.google.com/privacy?hl=ru' rel='noreferrer' target='_blank'> {t('re_captcha_policies.1')}</a> {t('re_captcha_policies.2')} 
      <a href='https://policies.google.com/terms?hl=ru' rel='noreferrer' target='_blank'> {t('re_captcha_policies.3')}</a></p>
    </div>
  )
}

export default ReCaptchaPolicies
