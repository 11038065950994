import { DICTIONARY_ERRORS } from './dictionaryErrors';
import i18next from 'i18next';

export const getErrorMessage = (errorMessage: string): string => {
  let result = i18next.t('get_error_message')

  const entries = Object.entries(DICTIONARY_ERRORS)
  for (const [key, value] of entries) {
    if (errorMessage.includes(key)) {
      result = value
    }
  }

  return result
}
