import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { deserialize } from '@sebbia/object-deserializer';
import { userDeserializer } from './deserializers';
import { PersonUpdateInput, ProfileService, RegistrationState, User } from './types';
import { BehaviorSubject } from 'rxjs';

const PERSON_FRAGMENT = gql`
  fragment PersonFragment on Person {
    name
    surname
    patronymic
    birthday
    photo {
      id
      publicLink
    }
    job
    contacts {
      type
      value
    }
  }
`;

class ProfileServiceImpl implements ProfileService {
  constructor(private client: ApolloClient<NormalizedCacheObject>) {}

  registrationState = new BehaviorSubject(RegistrationState.DEFAULT);

  startRegistration() {
    this.registrationState.next(RegistrationState.IN_PROCESS);
  };

  resetRegistration() {
    this.registrationState.next(RegistrationState.DEFAULT);
  }

  async restorePassword(phone: string, captchaCode?: string): Promise<any> {
    const mutation = gql`
      mutation SHOP_restorePassword($phone: String!, $captcha: String) {
        registration {
          restorePassword(phone: $phone, captchaCode: $captcha) {
            nextRestoreAfterMsecs
          }
        }
      }
    `;
    const captchaVar = captchaCode ? { captcha: captchaCode } : null;
    const res = await this.client.mutate({ variables: { phone: phone, ...captchaVar }, mutation });
    return deserialize(
      res.data,
      o => o.required('registration.restorePassword.nextRestoreAfterMsecs').asNumber
    );
  }

  async registration(phone: string, captchaCode?: string): Promise<string> {
    const mutation = gql`
      mutation SHOP_registration($phone: String!, $captcha: String) {
        registration {
          simpleUserRegistration(phone: $phone, captchaCode: $captcha) {
            login
          }
        }
      }
    `;

    const res = await this.client.mutate({
      variables: { phone: phone, captcha: captchaCode },
      mutation,
    });
    return deserialize(
      res.data,
      o => o.required('registration.simpleUserRegistration.login').asString
    );
  }

  async updateProfile(person: PersonUpdateInput): Promise<string> {
    const personDto = {
      name: person.name?.toObject(),
      surname: person.surname?.toObject(),
      patronymic: person.patronymic?.toObject(),
      birthday: person.birthday?.toISODate(),
      contacts: person.contacts,
    };

    const mutation = gql`
      ${PERSON_FRAGMENT}
      mutation SHOP_updateProfile($person: PersonUpdateInput) {
        users {
          updateProfile(user: { person: $person }) {
            id
            person {
              ...PersonFragment
            }
          }
        }
      }
    `;

    const res = await this.client.mutate({ variables: { person: personDto }, mutation });
    const id = deserialize(res.data, o => o.required('users.updateProfile.id').asString);

    try {
      this.client.cache.evict({ id });
    } catch {
      console.error(`Can't evict user ${id} from cache, drop cache`);
      this.client.resetStore();
    }

    return id;
  }

  async getProfile(): Promise<User> {
    const query = gql`
    ${PERSON_FRAGMENT}
      query SHOP_getProfile {
        users {
          getProfile {
            id
            person {
              ...PersonFragment
            }
          }
        }
      }
    `;

    const res = await this.client.query({ query, fetchPolicy: 'cache-first' });
    return deserialize(res.data, o =>
      o.required('users.getProfile').asObject(o => userDeserializer(o))
    );
  }
}

export default ProfileServiceImpl;
