import styles from './CustomRadioInput.module.scss';
import { ReactChild } from 'react';

function CustomRadioInput(props: {
  name: string,
  value?: string,
  label?: ReactChild,
  checked?: boolean,
  onClick?: () => void,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}) {
  return (
    <div className={styles.radioInput}>
      <label className={styles.customRadio} htmlFor={props.name}>
      <input
        type='radio'
        id={props.name}
        name={props.name}
        value={props.value}
        checked={props.checked}
        onClick={props.onClick}
        onChange={props.onChange}
      >
      </input>
      <span>{props.label}</span>
      </label>
    </div>
  )
}

export default CustomRadioInput