/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeaderWithTitle, Multistep, MultistepPrevButton, Steps, useMovementWithinMultistep } from 'shared/ui';
import { signInInitialState } from './utils/initialState';
import { formatPhone, LoginForm, useForm, useFetchFormData } from '../shared';
import { getErrorMessage } from 'shared/utils';
import validate from '../shared/utils/validate';

function SignInMultistepForm(props: {
  login: (phone: string, password: string) => Promise<void>
  openRegistration?: () => void,
  openRecovery?: () => void,
  phone?: string,
  setPhone?: (phone: string) => void,
  onFinish?: () => void,
}) {
  const { t } = useTranslation();
  const { formData, errors, changeHandler, setErrors } = useForm(signInInitialState, validate)
  const { selectedIndex } = useMovementWithinMultistep(Object.keys(errors).length > 0)
  
  const phone = formatPhone(formData.phone.value)
  const password = formData.password.value

  const { submited, loading, fetch: login } = useFetchFormData(() => props.login(phone, password),
  () => props.onFinish?.(), (error: string) => setErrors({password: getErrorMessage(error)})
  )

  useEffect(() => {
    if (!props.phone) return
    formData.phone.value = props.phone
  }, [props.phone])

  useEffect(() => {
    if (!props.setPhone) return
    props.setPhone(formData.phone?.value)
  }, [props.setPhone, formData.phone.value])

  useEffect(() => {
    const requiredErrors = validate(formData, true)
    setErrors(requiredErrors)
  }, [])

  const signInStep: Steps[] = [
    {
      title: t('multistep_form.entry'),
      closeable: true,
      headerBtn: <MultistepPrevButton
        title={t('multistep_form.registration') ?? "Регистрация"}
        onClick={() => props.openRegistration?.()} />,
      component: <LoginForm
        openRecovery={props.openRecovery}
        onChange={(e) => changeHandler(e)}
        onSubmit={() => login()}
        data={formData}
        errors={errors}
        loading={loading}
        touched={submited}
        btnChildren={t('multistep_form.come_in') ?? "Войти"}
      />
    },
  ]

  return (
    <>
      <ModalHeaderWithTitle 
        title={signInStep[selectedIndex]?.title}
        headerButton={signInStep[selectedIndex]?.headerBtn}
        closeModal={() => props.onFinish?.()}
      />
      <Multistep steps={signInStep} selectedIndex={selectedIndex}/>
    </>
  )
}

export default SignInMultistepForm
