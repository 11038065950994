export const transliterate = (string: string) => {
  return string.replace(/[а-яё]/gi, function(match) {
    const code = match.charCodeAt(0);
    if (code === 1025) {
      return 'YO';
    }
    if (code === 1105) {
      return 'yo';
    }
    const isUpperCase = code < 1071;
    const index = isUpperCase ? code - 1040 : code - 1072;
    const t = [
      'a',
      'b',
      'v',
      'g',
      'd',
      'e',
      'zh',
      'z',
      'i',
      'y',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'r',
      's',
      't',
      'u',
      'f',
      'h',
      'ts',
      'ch',
      'sh',
      'shh',
      '',
      'yi',
      '',
      'e',
      'yu',
      'ya',
    ];
    const letter = t[index];
    if (isUpperCase) {
      return letter.charAt(0).toUpperCase() + letter.slice(1);
    } else {
      return letter;
    }
  });
};
