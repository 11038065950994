import {useServiceData} from 'shared/utils';
import {useHistory, useParams} from 'react-router-dom';
import {SeasonTicketPlaces, VenueService} from 'entities/Venue';
import {useTranslation} from 'react-i18next';

import ErrorScreen from 'pages/ErrorScreen/ErrorScreen';
import {ItemsHead, Loader} from 'shared/ui';

import styles from './SeasonTicket.module.scss';
import {SeasonPass, SkeletonSeasonPass} from 'entities/SeasonsPass/ui';
import {SeasonsPassService} from 'entities/SeasonsPass/model';
import {PreferenceKeys, PreferencesService} from 'processes/Preferences';
import {ItemType, OrderService} from 'entities/Order';
import {AuthorizationState, AuthService} from 'processes/Auth';
import {Path} from 'routes/path';
import {useAuthChanged} from 'features/Authentication/shared/utils/useAuthChanged';
import {useContext} from "react";
import {OrderContext} from 'entities/Order/model/OrderContext';

const SeasonTicket = (props: {
  seasonsPassService: SeasonsPassService;
  venueService: VenueService;
  preferencesService: PreferencesService;
  orderService: OrderService;
  authService: AuthService;
}) => {
  const { seasonsPassService, venueService, preferencesService, orderService, authService } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const {
    data: seasonTicket,
    loading: seasonTicketLoading,
    error: seasonTicketError,
  } = useServiceData(() => seasonsPassService.getById(id), [seasonsPassService]);
  const {updateEventType} = useContext(OrderContext);
  updateEventType && updateEventType(ItemType.SEASON_TICKET);

  useAuthChanged(authService, async (newState, prevState) => {
    if(newState === AuthorizationState.NOT_AUTHORIZED){
      history.push(Path.SHOP)
    }
  })

  if (seasonTicketLoading)
    return (
      <>
        <SkeletonSeasonPass />
      </>
    );

  if (seasonTicketError) return <ErrorScreen error={seasonTicketError} />;

  if (!seasonTicket) return <Loader />;

  return (
    <main className={styles.main}>
      <ItemsHead
        title={seasonTicket.title}
        description={seasonTicket.description ? seasonTicket.description : ""}
        descriptionImage={props.preferencesService.get(PreferenceKeys.LOGO_SRC)}
        metaContent="product"
      />

      {seasonTicket && (
        <>
          <SeasonPass
            id={id}
            title={seasonTicket.title}
            logo={preferencesService.get(PreferenceKeys.LOGO_SRC)}
            backBtn={{
              title: t('common.back'),
              onClick: () => {
                history.push(Path.SHOP);
              },
            }}
          />
        </>
      )}
      {venueService && (
        <SeasonTicketPlaces
          venueService={venueService}
          preferencesService={preferencesService}
          authService={authService}
          orderService={orderService}
        />
      )}
    </main>
  );
};

export default SeasonTicket;
