import { CSSProperties } from 'react';
import { classNames } from 'shared/utils';
import styles from './Skeleton.module.scss';

export default function Skeleton(props: { type: 'span' | 'avatar' | 'line'; style?: CSSProperties }) {
  return (
    <div
      style={{
        ...props.style,
        height: props.style?.height,
        width: props.style?.width ,
      }}
      className={classNames(
        styles.skeleton,
        styles[`skeleton__${props.type}`]
      )}
    />
  );
}
