import { ReactChild } from 'react';
import { Button, ModalBody } from 'shared/ui';
import styleUtils from 'shared/styles/util.module.scss';
import { classNames } from 'shared/utils';
import { ProlongSeasonTicketBaseErrors } from './types';
import i18next from 'i18next';

export function getErrorModalBody(body: ReactChild | ReactChild[] | Element, onClose: () => void) {
  return (
    <ModalBody>
      <div style={{ maxWidth: 300 }}>
        <header className={ styleUtils.center }>
          <h2>{i18next.t('common.attention')}</h2>
        </header>
        <div className={ styleUtils.dispencedList } style={{ maxWidth: 350 }}>
          <div className={ classNames(styleUtils.centeredText, styleUtils.center) }>{ body }</div>
          <div className={ styleUtils.center }>
            <Button id='getErrorModalBodyOk' onClick={ onClose }>{i18next.t('common.ok') ?? "Понятно"}</Button>
          </div>
        </div>
      </div>
    </ModalBody>
  );
}

export function errorsModalContent(error: ProlongSeasonTicketBaseErrors) {
  const DefaultError = (
    <span className={ classNames(styleUtils.centeredText) }>
      {i18next.t('errors_modal_content.default_error')}
    </span>
  )

  switch (error.errorType) {
    case 'TooMuchTickets': {
      return (
        <span className={ classNames(styleUtils.centeredText) }>
          {i18next.t('errors_modal_content.too_much_tickets.0')} <br />
          {i18next.t('errors_modal_content.too_much_tickets.1')}
        </span>
      );
    }
    case 'NoTickets': {
      return (
        <span className={ classNames(styleUtils.centeredText) }>
          {i18next.t('errors_modal_content.no_tickets')}
        </span>
      );
    }
    case 'NoSeasonTicket': {
      return (
        <span className={ classNames(styleUtils.centeredText) }>
          {i18next.t('errors_modal_content.no_season_ticket')}
        </span>
      );
    }
    case 'VisibleIdNotFound': {
      return DefaultError;
    }
    case 'LatestVisibleIdNotFound': {
      return DefaultError;
    }
    case 'UnpaidSeasonTicket': {
      return (
        <span className={ classNames(styleUtils.centeredText) }>
          {i18next.t('errors_modal_content.unpaid_season_ticket')}
        </span>
      );
    }
    case 'DescriptorIdNotAvailableForProlong': {
      return (
        <span className={ classNames(styleUtils.centeredText) }>
          {i18next.t('errors_modal_content.descriptor_id_not_available_for_prolong')}
        </span>
      );
    }
    case 'DescriptorIdCantProlong': {
      return (
        <span className={ classNames(styleUtils.centeredText) }>
          {i18next.t('errors_modal_content.descriptor_id_cant_prolong')}
        </span>
      );
    }
    case 'BlockedSeasonTicket': {
      return (
        <span className={ classNames(styleUtils.centeredText) }>
          {i18next.t('errors_modal_content.blocked_season_ticket')}
        </span>
      );
    }
    case 'UnknownError': {
      return DefaultError;
    }
    default: {
      return DefaultError;
    }
  }
}
