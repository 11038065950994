import CrossDomainCredentialStorage from '@sebbia/auth-client/lib/CrossDomainCredentialStorage';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';

function initCredentialStorage(preferencesService: PreferencesService) {
  return new CrossDomainCredentialStorage(
    preferencesService.get(PreferenceKeys.CROSSDOMAIN_STORAGE),
    preferencesService.get(PreferenceKeys.SERVICE_ID),
    20000
  );
}

export default initCredentialStorage;
