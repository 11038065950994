import {Coordinates, ID} from "shared/model";
import {Money} from "shared/utils";

export enum PlaceStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  SOLD = 'SOLD',
  RESERVED = 'RESERVED',
}

export enum PriceTemplateTag {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export type RemotePlace = {
  id: ID;
  number: string;
  coordinates: Coordinates;
  rowNumber: string;
  sectorTitle: string;
};

export type PlacePrice = {
  place: RemotePlace;
  price: Money;
  status: PlaceStatus;
  color: string;
  priceCategoryId?: string;
};

export type PlacePriceFilter = {
  eventId: ID,
  tag: PriceTemplateTag,
  placeStatuses?: PlaceStatus[],
  priceUpLimit?: Money,
  priceDownLimit?: Money,
}

export type Map = {
  src: string;
  size: {
    width: number;
    height: number;
  };
};

export interface PlacePriceSubscription {
  /** PLacePrice filter for object observation */
  filter: PlacePriceFilter,
  /** Observer method for handling a place with a changed status */
  onNext: (value: PlacePrice) => void,
  /** Receives the terminating error of the sequence */
  onError?: (error: any) => void,
  /** Called when the stream has completed successfully */
  onComplete?: () => void,
}

export interface SeasonTicketSubscription extends Omit<PlacePriceSubscription, 'filter'> {
  filter: {
    descriptorId: ID
  },
}

export interface SubscriptionHandlers {
  handlePlacePriceChange: (placePrice: PlacePrice) => void,
  handleError: (err: any) => void,
  handleComplete?: () => void,
}

export type KeyValueObject = Record<string, number>;

export type PromiseStatus = 'pending' | 'fulfilled' | 'rejected' | 'none';
export type QueryStorage<T> = {
  query: Promise<T> | T[];
  status: PromiseStatus;
};

export interface SubscriptionHandlers {
  handlePlacePriceChange: (placePrice: PlacePrice) => void,
  handleError: (err: any) => void,
  handleComplete?: () => void,
}

export interface VenueService {
  getMapMatch(id: ID): Promise<Map>;

  getMapSeasonTicket(id: ID): Promise<Map>;

  getMatchPlaces(id: ID): Promise<PlacePrice[]>;

  getSeasonTicketPlaces(id: ID): Promise<PlacePrice[]>;

  eventPlacesStatusSubscription(placePriceSubscriptionConfig: PlacePriceSubscription): ZenObservable.Subscription;

  seasonTicketPlacesStatusSubscription(seasonTicketSubscriptionConfig: SeasonTicketSubscription): ZenObservable.Subscription;
}
