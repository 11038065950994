import React from 'react';
import { ReactComponent as IllCartSuccessSvg } from './ill-cart-success.styled.svg';

function propsToCssVars(props?: IllCartSuccessProps): React.CSSProperties {
    return {
        '--color-theme_clubNavigation1': props?.styles?.colorThemeClubNavigation1,
        '--color-theme_clubNavigation1-override': props?.styles?.colorThemeClubNavigation1Override,
        '--color-theme_clubPrimaryLight': props?.styles?.colorThemeClubPrimaryLight,
        '--color-theme_clubPrimaryLight-override': props?.styles?.colorThemeClubPrimaryLightOverride,
    } as React.CSSProperties
}

export type IllCartSuccessProps = {
    styles?: {
        colorThemeClubNavigation1?: string
        colorThemeClubNavigation1Override?: string
        colorThemeClubPrimaryLight?: string
        colorThemeClubPrimaryLightOverride?: string
    }
}

/**
 * Generated component
 * Available CSS variables: color-theme_clubNavigation1, color-theme_clubNavigation1-override, color-theme_clubPrimaryLight, color-theme_clubPrimaryLight-override
 **/
export default function IllCartSuccess(props?: IllCartSuccessProps) {
    return <IllCartSuccessSvg style={{ ...propsToCssVars(props) }}/>
}