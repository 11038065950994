import { ProlongSeasonTicketBaseErrors } from './types';

export function errorMapper(error: string = ''): ProlongSeasonTicketBaseErrors {
  let code: ProlongSeasonTicketBaseErrors;

  switch (error) {
    case 'c384e989':
      code = { errorType: 'VisibleIdNotFound' } as ProlongSeasonTicketBaseErrors;
      break;
    case 'd1ec0cb5':
      code = { errorType: 'LatestVisibleIdNotFound' } as ProlongSeasonTicketBaseErrors;
      break;
    case '14056204':
      code = { errorType: 'UnpaidSeasonTicket' } as ProlongSeasonTicketBaseErrors;
      break;
    case 'cdac7781':
      code = { errorType: 'DescriptorIdNotAvailableForProlong' } as ProlongSeasonTicketBaseErrors;
      break;
    case '8faf744f':
      code = { errorType: 'DescriptorIdCantProlong' } as ProlongSeasonTicketBaseErrors;
      break;
    case 'ebfd6fa2':
      code = { errorType: 'BlockedSeasonTicket' } as ProlongSeasonTicketBaseErrors;
      break;
    default:
      code = { errorType: 'UnknownError' } as ProlongSeasonTicketBaseErrors;
  }

  return code;
}
