import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next';
import styles from './EmptyCart.module.scss';
import {Path} from 'routes/path';
import {Button} from 'shared/ui';
import IllHappyTickets from 'figma/components/IllHappyTickets/IllHappyTickets';
import {PreferenceKeys, PreferencesService} from 'processes/Preferences';

function EmptyCart(props: { preferencesService?: PreferencesService }) {
  const history = useHistory()
  const {t} = useTranslation();

  const brandColors = {
    colorThemeClubNavigation1: props.preferencesService?.get(PreferenceKeys.CLUB_NAVIGATION_1),
    colorThemeClubPrimaryLight: props.preferencesService?.get(PreferenceKeys.CLUB_PRIMARY_LIGHT)
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t('common.cart')}</h1>
      <div className={styles.emptyCart}>
        <IllHappyTickets styles={brandColors}/>
        <h5>{t('empty_cart.0')}</h5>
        <p>{t('empty_cart.1')}</p>
        <Button id='EmptyCartGoStore' onClick={() => history.push(Path.SHOP)}>
          {t('empty_cart.2') ?? "Перейти в магазин"}
        </Button>
      </div>
    </div>
  )
}

export default EmptyCart
