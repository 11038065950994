import styleUtils from 'shared/styles/util.module.scss';
import {useLocation, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {PlacePriceSubscription, VenueService} from 'entities/Venue/model';
import {AuthService} from 'processes/Auth';
import {ItemType, Order, OrderService} from 'entities/Order';
import {classNames, useBehaviorSubject} from 'shared/utils';
import {PreferencesService} from 'processes/Preferences';
import PlacesReservation from 'entities/Venue/ui/Places/PlacesReservation';
import {useContext, useEffect} from 'react';
import {OrderContext} from 'entities/Order/model/OrderContext';
import {
  checkType,
  countByType,
  instantGoToCard,
  instantRemovePlacesFromOrder,
  instantReserve,
  packedGoToCart,
  prepareReservedPlacesArr
} from 'entities/Order/model/OrderUtils';

function MatchPlaces(props: {
  venueService: VenueService;
  authService: AuthService;
  preferencesService: PreferencesService;
  orderService: OrderService;
  isBannerOnTop?: boolean;
  bannerHeight?: number;
}) {
  const {orderService, authService, venueService, preferencesService} = props;
  const {id} = useParams<{ id: string }>();
  const location = useLocation();
  const {t} = useTranslation();
  const authenticated = useBehaviorSubject(props.authService.authenticated);
  let currentOrder: Order | undefined;
  const {
    updateReservedPlaces,
    eventType,
    updateEventType,
    updateReservePlace,
    updatePlacesCountByType,
    orderItemsIds,
    updateOrderItemsIds,
    updateRemovePlacesFromOrder,
    updateGoToCart,
    updateIsAuthenticated,
  } = useContext(OrderContext);
  const type = !!location.pathname.match(/season-/) ? ItemType.SEASON_TICKET : ItemType.TICKET;
  let orderId: string;

  const getOrder = async () => {
    currentOrder = await props.orderService.getCurrentOnly();
    (eventType && updateEventType) && checkType(eventType.current, type, updateEventType);
    if (!!currentOrder) {
      orderId = currentOrder.id;
      updateReservedPlaces && updateReservedPlaces(prepareReservedPlacesArr(currentOrder, id));
      (updateOrderItemsIds !== undefined && orderItemsIds !== undefined) && currentOrder.items.forEach(item => {
        updateOrderItemsIds(
          {...orderItemsIds.current, [item.item.place.id]: { orderItemId: item.id, orderId: orderId }}
        );
      });
      (eventType !== undefined && updatePlacesCountByType !== undefined) && updatePlacesCountByType(countByType(eventType.current, currentOrder));
    }

    if (authenticated) {
      updateIsAuthenticated && updateIsAuthenticated(authenticated);
      updateReservePlace && updateReservePlace(instantReserve)
      updateRemovePlacesFromOrder && updateRemovePlacesFromOrder(instantRemovePlacesFromOrder);
      updateGoToCart && updateGoToCart(instantGoToCard);
    } else {
      updateIsAuthenticated && updateIsAuthenticated(authenticated);
      updateGoToCart && updateGoToCart(packedGoToCart);
      updateReservePlace && updateReservePlace(undefined);
      updateRemovePlacesFromOrder && updateRemovePlacesFromOrder(undefined);
    }
  };

  useEffect(() => {
    getOrder();
  }, [authenticated])

  return (
    <PlacesReservation
      authService={authService}
      orderService={orderService}
      isBannerOnTop={props.isBannerOnTop}
      bannerHeight={props.bannerHeight}
      preferencesService={preferencesService}
      mapLoader={{
        getMap: async () => await venueService.getMapMatch(id),
        getPlaces: async () => await venueService.getMatchPlaces(id),
        subscribeToEventPlacesStatus: (subscriptionConfig: PlacePriceSubscription) => venueService.eventPlacesStatusSubscription(subscriptionConfig),
      }}
      errorModalContentResolver={error => {
        switch (error.errorType) {
          case 'MatchNotAvailable': {
            return (
              <span className={classNames(styleUtils.centeredText)}>
                {t('event.match_places.places_reservation.match_not_available')}
              </span>
            );
          }
        }
      }}
    />
  );
}

export default MatchPlaces;
