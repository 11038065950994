import { ObjectDeserializer, DeserializationError, DeserializationPath } from '@sebbia/object-deserializer';
import { LocalizedString, Money } from 'shared/utils';
import { PaginatedResult, RemoteFile, Team, MapSize, Coordinates} from './types';

export function moneyMapper(value: any, path: DeserializationPath) {
  if (typeof value !== 'string') throw new DeserializationError(path, 'Not a money string');
  return Money.parse(value);
}

export function paginatedResultDeserializer<T>(
  o: ObjectDeserializer,
  d: (o: ObjectDeserializer) => T
): PaginatedResult<T> {
  return {
    total: o.required('total').asNumber,
    limit: o.required('limit').asNumber,
    page: o.required('page').asNumber,
    list: o.required('list').asArrayOfObjects(d),
  };
}

export function remoteFileDeserializer(o: ObjectDeserializer): RemoteFile {
  return {
    publicLink: o.required('publicLink').asString,
  };
}

export function teamDeserializer(o: ObjectDeserializer): Team {
  return {
    id: o.required('id').asString,
    title: o.required('title').as((d) => LocalizedString.fromObject(d)),
    logo: o.optional('logo')?.asObject(remoteFileDeserializer),
    location: o.optional('location')?.as((d) => LocalizedString.fromObject(d)),
  };
}

export function mapSizeDeserializer(o: ObjectDeserializer): MapSize {
  return {
    height: o.required('height').asNumber,
    width: o.required('width').asNumber,
  }
}

export function coordinatesDeserializer(o: ObjectDeserializer): Coordinates {
  return {
    x: o.required('x').asNumber,
    y: o.required('y').asNumber,
  }
}
