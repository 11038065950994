import { User } from 'entities/Profile';
import { useEffect } from 'react';
import { OptionsForSend } from '../model';

export function useInitialSendOptions(getProfile: () => Promise<User>, sendOptions: OptionsForSend, setSendOptions: (sendOptions: OptionsForSend) => void) {

  useEffect(() => {
    (async () => {
      try {
        const user = await getProfile()
        const email = user.person?.contacts.find((item: { type: string, value: string }) => item.type === 'EMAIL')
        const phone = user.person?.contacts.find((item: { type: string, value: string }) => item.type === 'PHONE')
        setSendOptions({
          ...sendOptions,
          email: {value: email!.value},
          phone: {value: phone!.value},
        })
      } catch (e) {
        console.log(e);
      } 
    })();
  }, [])
}