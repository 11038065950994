import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import { BehaviorSubject } from 'rxjs';
import { AuthorizationState, AuthService } from './types';
import { createTokenRefreshLink } from '@sebbia/auth-client/lib/CredentailsRefreshLink';

class AuthServiceImpl implements AuthService {
  authenticated = new BehaviorSubject(false);
  state = new BehaviorSubject(AuthorizationState.NOT_AUTHORIZED);

  constructor(private authClient: AuthClient) {}

  async init() {
    await this.authClient.init();
    const authorized = this.authClient.isAuthorized()
    this.authenticated.next(authorized)
    this.state.next(authorized ? AuthorizationState.AUTHORIZED : AuthorizationState.NOT_AUTHORIZED)
  }

  get tokenRefreshLink() {
    return createTokenRefreshLink(this.authClient);
  }

  get credentials() {
    return this.authClient.credentials;
  }

  get isAuthorized() {
    return this.authClient.isAuthorized();
  }

  async logout() {
      await this.authClient.logout()
      this.authenticated.next(false)
      this.state.next(AuthorizationState.NOT_AUTHORIZED)
  }

  async login(login: string, password: string) {
      await this.authClient.login(login.trim(), password.trim());
      this.authenticated.next(true)
      this.state.next(AuthorizationState.AUTHORIZED)
  }

  requireAuthorization(): Promise<void> {
    this.state.next(AuthorizationState.REQUIRE_AUTHORIZATION)
    return Promise.resolve()
  }
}


export default AuthServiceImpl;
