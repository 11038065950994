import { AuthorizationState, AuthService } from 'processes/Auth';
import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs/internal/Subscription';
import { useBehaviorSubject } from 'shared/utils';

export function useAuthChanged(
  authService: AuthService,
  block: (newState: AuthorizationState, prevState: AuthorizationState) => Promise<void>
) {
  const isAuthorized = useBehaviorSubject(authService.authenticated);
  const [prevState, setPrevState] = useState(authService.state.value);

  useEffect(() => {
    let subscription: Subscription;
    subscription = authService.state.subscribe(it => {
      if (it !== prevState) {
        block(it, prevState);
        setPrevState(it);
      }
    });

    return () => {
      subscription?.unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);
}
