import React from 'react';
import styles from './AppLayout.module.scss';

export default function AppLayout(props: {
  header: JSX.Element;
  footer: JSX.Element;
  children: JSX.Element;
}) {
  return (
    <div className={styles.layout}>
      {props.header}
      <div className={styles.main}>{props.children}</div>
      {props.footer}
    </div>
  );
}
