import styles from './MatchBanner.module.scss';
import { Skeleton } from 'shared/ui';

function SkeletonMatchBanner() {
  return (
    <div className={styles.matchBanner}>
      <TeamInfo />
      <MainInfo />
      <TeamInfo />
    </div>
  );
}

function TeamInfo() {
  return (
    <div className={styles.teamContainer}>
      <Skeleton type="avatar" style={{ width: '90px', height: '90px', marginBottom: '22px' }} />
      <Skeleton type="span" />
      <Skeleton type="span" />
    </div>
  );
}

function MainInfo() {
  return (
    <div className={styles.info}>
      <Skeleton type="span" />
      <Skeleton type="span" style={{ width: 280 }} />
      <Skeleton type="span" style={{ width: 150 }} />
      <Skeleton type="span" />
    </div>
  );
}

export default SkeletonMatchBanner;
