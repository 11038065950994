import {PlacePriceSubscription, SeasonTicketSubscription} from "../model";
import {MapLoader} from "../ui/Places/PlacesReservation";

export type PlacesSubscriptionType = 'TICKET' | 'SEASON_TICKET';
export function usePlacesStatusSubscription(
  subscriptionType: PlacesSubscriptionType,
  subscriptionConfig: PlacePriceSubscription | SeasonTicketSubscription,
  mapLoader: MapLoader,
): () => ZenObservable.Subscription {
  let placesSubscription: () => ZenObservable.Subscription;
  switch (subscriptionType) {
    case "TICKET": {
      if (mapLoader.subscribeToEventPlacesStatus){
        placesSubscription = () => mapLoader.subscribeToEventPlacesStatus!(subscriptionConfig as PlacePriceSubscription)
      } else {
        throw new Error('There is no necessary subscription handler in mapLoader');
      }
      break;
    }
    case "SEASON_TICKET": {
      if (mapLoader.subscribeToSeasonTicketPlacesStatus){
        placesSubscription = () => mapLoader.subscribeToSeasonTicketPlacesStatus!(subscriptionConfig as SeasonTicketSubscription);
      } else {
        throw new Error('There is no necessary subscription handler in mapLoader');
      }
      break;
    }
    default: throw new Error('Incorrect subscription configuration');
  }

  return placesSubscription;
}

export default usePlacesStatusSubscription;
