import { gql } from 'graphql-tag';

export const seasonTicketDescriptorFragmentShort = gql`
  fragment STDFragment on SeasonTicketDescriptor {
    id
    title
  }
`

export const seasonTicketFragmentShort = gql`
  fragment ST on SeasonTicket {
    id
    visibleId
    descriptor{
      ...STDFragment
    }
  }
`

export const GET_PROLONG_SEASON_TICKETS = gql`
  ${seasonTicketDescriptorFragmentShort}
  ${seasonTicketFragmentShort}
  query SHOP_GetProlongSeasonTicketsInfo {
    seasonTicket {
      getAvailableToProlong {
        ...ST
        prolongationInfo {
          prices {
            descriptor {
              id
              title
            }
            price {
              status
              price
            }
          }
        }
        place {
          id
          number
          row {
            number
            sector {
              title
            }
          }
        }
      }
    }
  }
`

export const PROLONG_SEASON_TICKET = gql`
  ${seasonTicketDescriptorFragmentShort}
  ${seasonTicketFragmentShort}
  mutation SHOP_ProlongSeasonTicket ($data: ProlongSeasonTicketInput!){
    seasonTicket {
      prolong(data: $data) {
        ...ST
        place {
          id
          number
          row {
            number
            sector {
              title
            }
          }
        }
      }
    }
  }
`

export const GET_SEASON_TICKET_DESCRIPTOR_BY_ID = gql`
  ${seasonTicketDescriptorFragmentShort}
  query SHOP_GetSeasonTicketDescriptorById ($id: ID!){
    seasonTicketDescriptor {
      getById(id: $id) {
        ...STDFragment
        prolongationDescriptors {
          ...STDFragment
        }
      }
    }
  }
`