import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LoyaltyDescriptor} from 'entities/Loyalty';
import {classNames, getErrorMessage, Money} from 'shared/utils';
import LoyaltyInfoScreen from 'entities/Loyalty/ui/LoyaltyInfoScreen/LoyaltyInfoScreen';

import {ReactComponent as IconQuestion} from 'shared/assets/icons/icon_question.svg';
import {Loader, RangeSlider} from 'shared/ui';

import styles from './PaymentPointsCard.module.scss';

function PaymentPointsCard(props: {
  applyLoyaltyBonuses: (value: Money) => void,
  loyaltyDescriptor: LoyaltyDescriptor,
  title: string,
  subtitle: string,
  loyaltySpent: Money,
  maxPossibleExpenditure: Money,
  totalLoyalty: Money,
  disabled: boolean,
  isShown?: boolean,
}) {
  const [isActive, setActive] = useState(false)
  const [isInfoVisible, setInfoVisible] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [possibleExpenditure, setPossibleExpenditure] = useState<number>(props.maxPossibleExpenditure.getMain())
  const { t } = useTranslation();

  const applyLoyalty = async (value: Money) => {
    try {
      setLoading(true)
      await props.applyLoyaltyBonuses(value)
    } catch(e) {
      setError(getErrorMessage((e as Error).message))
      console.error(e)
    } finally {
      setLoading(false)
      setActive(false)
    }
  }

  useEffect(() => {
    setPossibleExpenditure(props.maxPossibleExpenditure.getMain())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.maxPossibleExpenditure.getMain()])

  const handleChange = (value: number) => setPossibleExpenditure(value)

  const balance = props.loyaltySpent ? props.totalLoyalty.plus(props.loyaltySpent).toString() : props.totalLoyalty.toString()

  return (props.isShown === true ?
      <div className={styles.PaymentPointsCardWrap}>
        <div className={classNames(styles.PaymentPointsCard,
          props.disabled ? styles.disabled : ''
        )}>
          <div className={styles.cardWrap}>
            <div className={styles.cardTitle}>
              <p className={styles.title}>{props.title}</p>
              <button id='PaymentPointsCard' className={styles.cardBtn} onClick={() => setInfoVisible(true)}>
                <IconQuestion/></button>
            </div>
            <p className={classNames(styles.subtitle, error ? styles.error : '')}>{error ? error : props.subtitle}</p>
            <div className={styles.cardMain}>
              <div className={styles.balance}>
                {t('payment_points_card.0')}
                <span>{balance} {props.loyaltyDescriptor.loyaltyCurrencyName.plural.slice(0, 1)}</span>
              </div>
              {props.maxPossibleExpenditure &&
                <div className={styles.total}>
                  {isActive && !props.disabled
                    ? `${possibleExpenditure} ${props.loyaltyDescriptor.loyaltyCurrencyName.plural.slice(0, 1)}`
                    : <button id='PaymentPointsCardUse' disabled={props.disabled} onClick={() => setActive(true)}
                              className={styles.cardBtn}>{t('payment_points_card.1')}</button>}
                </div>}
            </div>
          {(isActive && !props.disabled) && (
            <>
              <RangeSlider
                minRange={0}
                maxRange={props.maxPossibleExpenditure.getMain()}
                value={possibleExpenditure}
                step={5}
                onChange={handleChange}
              />
              <button
                id='PaymentPointsCardUseIsActive'
                onClick={() => applyLoyalty(new Money(possibleExpenditure))}
                className={styles.cardBtn}
              >
                {isLoading ? <Loader className={'loyaltyLoader'}/> : t('payment_points_card.1')}
              </button>
            </>
          )}
          </div>
          {isInfoVisible &&
            <LoyaltyInfoScreen
              visible={isInfoVisible}
              closeModal={() => setInfoVisible(false)}
              loyaltyDescriptor={props.loyaltyDescriptor}
              totalLoyalty={Number(balance)}
            />
          }
        </div>
      </div> : null
  )
}

export default PaymentPointsCard
