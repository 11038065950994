import { ContactType, PersonUpdateInput } from 'entities/Profile';
import validate, { IErrors } from 'features/Authentication/shared/utils/validate';
import { getSignUpInitialState } from 'features/Authentication/SignUpMultistep/utils/initialState';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'shared/ui';
import { isNotNull, LocalizedString, transliterate } from 'shared/utils';
import { formatPhone, useForm } from '../../utils';

function UpdateProfileFormV2(props: {
  onChange: (data: PersonUpdateInput, errors?: IErrors) => void;
  initialValues: PersonUpdateInput;
}) {
  const { onChange, initialValues } = props;

  const initialState = useMemo(() => {
    return getSignUpInitialState(initialValues);
  }, [initialValues]);

  const { formData: data, errors, changeHandler } = useForm(initialState, validate);

  const [touched, setTouched] = useState<Record<string, boolean>>({});

  const { t } = useTranslation();

  useEffect(() => {
    const phone = data.phone.value
      ? formatPhone(data.phone.value)
      : initialValues.contacts?.find(it => it.type === ContactType.PHONE)?.value;
    const email = data.email.value
      ? data.email.value
      : initialValues.contacts?.find(it => it.type === ContactType.EMAIL)?.value;

    const person: PersonUpdateInput = {
      name: LocalizedString.fromObject({
        ru: data.name.value,
        en: transliterate(data.name.value),
      }),
      surname: LocalizedString.fromObject({
        ru: data.surname.value,
        en: transliterate(data.surname.value),
      }),
      patronymic: data.patronymic.value ? LocalizedString.fromObject({
        ru: data.patronymic.value,
        en: transliterate(data.patronymic.value),
      }) : undefined,
      birthday: DateTime.fromFormat(data.birthday.value, 'dd.MM.yyyy'),
      contacts: [
        phone
          ? {
              type: ContactType.PHONE,
              value: phone,
            }
          : undefined,
        email
          ? {
              type: ContactType.EMAIL,
              value: data.email.value,
            }
          : undefined,
      ].filter(isNotNull),
    };
    const errorsKeys = Object.keys(errors);
    onChange(person, errorsKeys.length > 0 ? errors : undefined);
  }, [data]);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Input
        type="text"
        name="surname"
        title={t('update_profile_form.surname.0') ?? "Фамилия"}
        placeholder={t('update_profile_form.surname.1') ?? "Введите фамилию"}
        value={data.name?.value}
        handleChange={changeHandler}
        error={errors?.name}
        touched={touched['surname']}
        onClick={() =>
          setTouched(old => {
            return { ...old, surname: true };
          })
        }
      />
      <Input
        type="text"
        name="name"
        title={t('update_profile_form.name.0') ?? "Имя"}
        placeholder={t('update_profile_form.name.1') ?? "Введите имя"}
        value={data.surname.value}
        handleChange={changeHandler}
        error={errors?.surname}
        touched={touched['name']}
      />
      <Input
        type="text"
        name="patronymic"
        title={t('update_profile_form.patronymic.0') ?? "Отчество"}
        placeholder={t('update_profile_form.patronymic.1') ?? "Введите отчество"}
        value={data.patronymic?.value}
        handleChange={changeHandler}
        error={errors?.patronymic}
      />
      <Input
        type="text"
        name="birthday"
        title={t('update_profile_form.dob') ?? "Дата рождения"}
        placeholder="10.01.1970"
        mask="00.00.0000"
        value={data.birthday?.value}
        handleChange={changeHandler}
        error={errors?.birthday}
        touched={touched['birthday']}
      />
      <Input
        type="text"
        name="email"
        title={t('update_profile_form.email.0') ?? "Почта"}
        placeholder="yourmail@mail.com"
        label={t('update_profile_form.email.1') ?? "Для копий билетов"}
        value={data.email?.value}
        handleChange={changeHandler}
        error={errors?.email}
        touched={touched['email']}
      />
    </form>
  );
}

export default UpdateProfileFormV2;
