import i18next from 'i18next';
import { Helmet } from 'react-helmet-async';
import { LocalizedString } from 'shared/utils';

function ItemsHead(props: {
  title: LocalizedString | string;
  description: LocalizedString | string;
  descriptionImage: string;
  metaContent?: string,
}) {
  
  return (
    <Helmet>
      <title>{props.title.toString(i18next.language)}</title>

      <meta name="description" content={props.description.toString(i18next.language)} />
      <meta name="title" content={props.title.toString(i18next.language)} />
      <meta property="og:type" content={props.metaContent ? props.metaContent : 'article'} />
      <meta property="og:title" content={props.title.toString(i18next.language)} />
      <meta property="og:description" content={props.description.toString(i18next.language)} />
      <meta property="og:image" content={props.descriptionImage} />

      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:title" content={props.title.toString(i18next.language)} />
      <meta property="twitter:description" content={props.description.toString(i18next.language)} />
      <meta property="twitter:image" content={props.descriptionImage} />
    </Helmet>
  )
}

export default ItemsHead

