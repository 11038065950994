import { Coordinates, ID, ISOstring } from 'shared/model/types';
import { BehaviorSubject } from 'rxjs';
import { Money } from 'shared/utils';
import { DateTime } from 'luxon'
import { PlacePrice, PlaceStatus } from 'entities/Venue';
import { MutableRefObject, ReactNode } from 'react';
import { CancelError, PlacesReservationError } from 'entities/Venue/utils/types';
import { AuthService } from 'processes/Auth';

export type Tickets_RemoteSector = {
  title: string;
};

export type Tickets_RemoteRow = {
  number: string;
  sector: Tickets_RemoteSector;
};

export type Tickets_RemotePlace = {
  id: string;
  number: string;
  row: Tickets_RemoteRow;
  coordinates: Coordinates;
};

export type Tickets_RemoteSeason = {
  id: ID;
  endDate: ISOstring;
};

export type SeasonTicketDescriptor = {
  id: ID;
  title: string;
  seasons?: Tickets_RemoteSeason[];
};

export type SeasonTicket = {
  place: Tickets_RemotePlace;
  descriptor: SeasonTicketDescriptor;
  price: Money;
  priceCategoryId: string;
  status: PlaceStatus;
  color: string;
};

export type Tickets_RemoteTeam = {
  title: string;
};

export type RemoteFile = {
  publicLink: string;
};

export type Tickets_RemoteTournament = {
  logo?: RemoteFile;
};

export type Tickets_RemoteStage = {
  tournament: Tickets_RemoteTournament;
};

export type Tickets_RemoteMatch = {
  id: ID;
  startTime: DateTime;
  team1: Tickets_RemoteTeam;
  team2: Tickets_RemoteTeam;
  stage: Tickets_RemoteStage;
};

export type Ticket = {
  visibleId: ID;
  place: Tickets_RemotePlace;
  event: Tickets_RemoteMatch;
  price: Money;
  priceCategoryId: string;
  status: PlaceStatus;
  color: string;
};

export type OrderItem = Ticket | SeasonTicket;

export enum ItemType {
  TICKET = 'TICKET',
  SEASON_TICKET = 'SEASON_TICKET',
  PROLONG_SEASON_TICKET = 'PROLONG_SEASON_TICKET',
  OUTFIT = 'OUTFIT',
}

export type OrderItemDescriptor = {
  id: ID;
  type: ItemType;
  title: string;
  price: Money;
  priceWithDiscount: Money;
  loyaltyUsed?: string;
  reservedUntil?: ISOstring;
  item: OrderItem;
};

export type OrderAdditionalData = {
  loyaltyAmount?: Money;
};

export type Order = {
  id: ID;
  additionalData?: OrderAdditionalData;
  price: Money;
  priceWithDiscount: Money;
  appliedPromocode?: string;
  items: OrderItemDescriptor[];
};

export enum Tickets_LoyaltyApplicabilityError {
  LOYALTY_PROGRAM_IS_NOT_ACTIVE,
  NOT_APPLICABLE_WITH_DISCOUNT,
}

export type Tickets_LoyaltyOrderCheckResult = {
  isApplicable: boolean;
  applicabilityError?: Tickets_LoyaltyApplicabilityError;
  loyaltyAccrued: Money;
  maxPossibleExpenditure: Money;
  loyaltySpent: Money;
};

export enum TicketStatus {
  CANCELED = 'CANCELED',
  SOLD = 'SOLD',
  RESERVED = 'RESERVED',
}

export type ReserveResult = {
  id: string;
  orderItemId: string;
  order: Order;
};

export type ReserveResultIds = {
  orderItemId: string;
  orderId: string;
}

export type OrderNotificationSettings = {
  enableEmail: boolean,
  enableSms: boolean,
  overridePhone?: string,
  overrideEmail?: string
}

export interface OrderService {
  behaviorOrder: BehaviorSubject<Promise<Order | undefined>> | undefined;
  getCurrentOnly(userId?: ID): Promise<Order | undefined>;
  removeItemFromOrder(orderId: ID, itemsIds: ID[]): Promise<Order>;
  applyPromocode(orderId: ID, promocode: string): Promise<Order>;
  cancelPromocode(orderId: ID): Promise<Order>;
  applyLoyaltyBonuses(orderId: ID, value: Money): Promise<Order>;
  changeNotificationSettings(orderId: ID, settings: OrderNotificationSettings): Promise<void>;
  sendToSMS(orderId: ID, phone: string): Promise<boolean>;
  sendToMail(orderId: ID, email: string): Promise<boolean>;
  refreshState(): Promise<void>;

  reserveTicket(placeId: string, matchId: string): Promise<ReserveResult>;
  reserveSeasonTicket(placeId: string, descriptorId: string): Promise<ReserveResult>;
}

export type OrderContextProviderProps = {
  children: ReactNode;
}

export interface ReservePlaceParams {
  place: PlacePrice,
  orderService: OrderService,
  id: string,
  eventType: ItemType,
}
export type ReservePlaceFunction = (params: ReservePlaceParams) => Promise<PlacesReservationError | ReserveResult>

export interface RemovePlacesFromOrderParams {
  places: PlacePrice[],
  orderItemsIds: {[key: string]: ReserveResultIds},
  orderService: OrderService,
}
export type RemovePlacesFromOrderFunction = (params: RemovePlacesFromOrderParams) => Promise<CancelError | undefined>

export interface GoToCartParams {
  authService: AuthService,
  orderService: OrderService,
  id: string,
  reservedPlaces: PlacePrice[],
  updateReservedPlaces: (places: PlacePrice[]) => void,
  redirectFunc: () => void,
  eventType?: ItemType,
}
export type GoToCartFunction = (params: GoToCartParams) => Promise<void | PlacesReservationError>

export type OrderContextType = {
  loading?: boolean,
  reservedPlaces: MutableRefObject<PlacePrice[]>,
  eventType?: MutableRefObject<ItemType>,
  reservePlace?: MutableRefObject<ReservePlaceFunction | undefined>,
  placesCountByType?: MutableRefObject<number>,
  orderItemsIds?: MutableRefObject<{[key: string]: ReserveResultIds}>,
  removePlacesFromOrder?:  MutableRefObject<RemovePlacesFromOrderFunction | undefined>,
  goToCart?: MutableRefObject<GoToCartFunction | undefined>,
  isAuthenticated?: MutableRefObject<boolean | undefined>,
  updateLoading?: (val: boolean) => void,
  updateReservedPlaces?: (places: PlacePrice[]) => void,
  updateEventType?: (type: ItemType) => void,
  updateReservePlace?: (func: ReservePlaceFunction | undefined) => void,
  updatePlacesCountByType?: (count: number) => void,
  updateOrderItemsIds?: (val: {[key: string]: ReserveResultIds}) => void,
  updateRemovePlacesFromOrder?: (func: RemovePlacesFromOrderFunction | undefined) => void
  updateGoToCart?: (func: GoToCartFunction) => void,
  updateIsAuthenticated?: (val: boolean | undefined) => void,
}
