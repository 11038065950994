import { classNames } from 'shared/utils'
import styles from './BurgerIcon.module.scss'

export function BurgerIcon(props: {active: boolean, onClick: () => void}) {

  return (
  <div
    id="menu-icon"
    onClick={props.onClick}
    className={classNames(styles.menuIcon, styles.mobile, props.active ? styles.open : '')}
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  )
}