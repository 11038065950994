import { useTranslation } from 'react-i18next';
import { OrderItemDescriptor, SeasonTicket } from 'entities/Order/model';
import { CountdownTimerOnRxJS, SeparatedList } from 'shared/ui';
import { groupBy, minDate } from 'shared/utils';
import OrderSeasonTickets from '../OrderItems/OrderSeasonTickets';
import styles from './OrderItemsList.module.scss';

function OrderSeasonTicketsList(props: {
  orderItemDescriptors: OrderItemDescriptor[],
  removeItem: (orderItemId: string) => void,
  title: string,
}) {
  const { t } = useTranslation();
  const minReserved = minDate<OrderItemDescriptor>(props.orderItemDescriptors, item => item.reservedUntil!)
  const groupTickets = groupBy<OrderItemDescriptor, string>(props.orderItemDescriptors, item => (item.item as SeasonTicket).descriptor.id)
  
  return (
    <article className={styles.order}>
      <div className={styles.header}>
        <h4>{props.title}</h4>
        <CountdownTimerOnRxJS label={t('common.countdown_timer_label') ?? 'бронь'} date={minReserved}/>
      </div>
      <SeparatedList>
        { Array.from(groupTickets, ([key, value]) => 
          <OrderSeasonTickets
            key={key}
            orderItemDescriptor={value}
            removeItem={props.removeItem}
          />
        )}
      </SeparatedList>
    </article>
  )
}

export default OrderSeasonTicketsList
