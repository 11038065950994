import { CSSProperties, ReactChild } from 'react';
import { classNames } from 'shared/utils';
import styles from './ModalHeader.module.scss';

export type ModalHeaderProps = {
  headerStyle?: CSSProperties;
  headerClassName?: string;
  children: ReactChild | ReactChild[];
};

function ModalHeader(props: ModalHeaderProps) {
  return (
    <header
      style={props.headerStyle}
      className={classNames(styles.headerStyle, props.headerClassName)}
    >
      {props.children}
    </header>
  );
}

export default ModalHeader;
